import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react";
import { FC, ReactNode, useRef, useState } from "react";
import Button, { ButtonProps } from "../Button/Button";
import { IoCaretDownOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Paper from "../Paper/Paper";
import "./ButtonDropdown.scss";

type Action = {
  id: string;
  label: string;
  onClick: () => void;
};

export interface ButtonDropdownProps extends ButtonProps {
  children?: ReactNode;
  actions: Action[];
}

const ButtonDropdown: FC<ButtonDropdownProps> = ({
  children,
  actions,
  ...props
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<null | number>(null);
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<(null | HTMLLIElement)[]>([]);
  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset({ mainAxis: 6 }), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const listNavigation = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [listNavigation, click, dismiss, role, focus],
  );
  return (
    <>
      <Button
        {...props}
        endIcon={<IoCaretDownOutline />}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </Button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <Paper
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className="button-dropdown-popper-menu"
            >
              <ol>
                {actions.map((action, index) => (
                  <li
                    key={action.id}
                    tabIndex={activeIndex === index ? 0 : -1}
                    ref={(node) => {
                      listRef.current[index] = node;
                    }}
                    {...getItemProps()}
                    className="button-dropdown-popper-menu-item"
                  >
                    <button
                      onClick={action.onClick}
                      className="button-dropdown-popper-menu-item-button"
                    >
                      {t(action.label)}
                    </button>
                  </li>
                ))}
              </ol>
            </Paper>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default ButtonDropdown;
