import VideoPlayer from "../../utils/video/VideoPlayer";
import Button from "../Button/Button";
import Paper from "../Paper/Paper";
import "./PortalCard.scss";
import { FC } from "react";

interface PortalCardProps {
  title: string;
  videoUrl: string;
  buttonText: string;
  onClick: () => void;
}

const PortalCard: FC<PortalCardProps> = ({
  title,
  videoUrl,
  onClick,
  buttonText,
}: PortalCardProps) => {
  return (
    <Paper className="portal-card">
      <h2 className="m-typography-h2">{title}</h2>
      <VideoPlayer src={videoUrl} />
      <Button onClick={onClick} size="large" aria-label={buttonText}>
        {buttonText}
      </Button>
    </Paper>
  );
};
export default PortalCard;
