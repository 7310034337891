import { ReactComponent as Cross } from "./cross.svg";
import { ReactNode, useContext } from "react";
import { modalContext } from "../modal";
import "./modal-component.scss";

export const ModalHeader = function ModalHeader(props: {
  children: ReactNode;
}): JSX.Element {
  const { onModalClose } = useContext(modalContext);
  const { children } = props;

  return (
    <div className="modal-header">
      {children}
      <button
        className="cross-btn"
        title="close modal"
        onClick={() => onModalClose()}
        type="button"
      >
        <Cross className="cross-icon" />
      </button>
    </div>
  );
};

export const ModalBody = function ModalBody(props: {
  children: ReactNode;
}): JSX.Element {
  const { children } = props;

  return <div className="modal-body">{children}</div>;
};

export const ModalFooter = function ModalFooter(props: {
  children: ReactNode;
}): JSX.Element {
  const { children } = props;
  return <div className="modal-footer">{children}</div>;
};
