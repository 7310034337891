import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import fr from "./lang/fr.json";
import i18n from "i18next";

const resources = {
  "en-EN": en,
  "fr-FR": fr,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "fr-FR",
  lng: "fr-FR",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
