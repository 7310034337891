import { selectCurrentUserId } from "../../../domain/currentUser/currentUser.selectors";
import { saveMessage } from "../../../domain/contact/message.actions";
import Button from "../../../components/Button/Button";
import { useSelector, useDispatch } from "react-redux";
import Paper from "../../../components/Paper/Paper";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import "./chatbot-beneficary.scss";
import { FC } from "react";

const ChatbotBeneficary: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty },
  } = useForm({ criteriaMode: "all", defaultValues: { message: "" } });
  const currentUserId = useSelector(selectCurrentUserId) as unknown as number;

  const onSubmit = (data) => {
    dispatch(saveMessage({ text: data.message, sender: currentUserId }));
    reset({ message: "" });
  };

  return (
    <div className="chatbot-beneficiary">
      <h1 className="m-typography-h1 text-color-primary">{t("chatbot")}</h1>
      <h2 className="m-typography-h2 text-color-primary">
        {t("needSomeInformation")}
        <br />
        {t("contactUs")}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)} className="m-form">
        <Paper className="m-form-fields" fullWidth>
          <div className="m-form-fields-content">
            <div className="m-form-field m-form-field-full-width">
              <label className="m-form-label" htmlFor="message">
                {t("yourMessage")}
              </label>
              <textarea
                id="message"
                name="message"
                rows={12}
                className="m-form-input"
                ref={register({ required: true })}
              />
            </div>
          </div>
        </Paper>
        <Paper className="m-form-actions" fullWidth>
          <Button
            type="submit"
            className="m-form-action-main"
            disabled={!isDirty}
          >
            {t("send")}
          </Button>
        </Paper>
      </form>
    </div>
  );
};
export default ChatbotBeneficary;
