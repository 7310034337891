import routes from "../../components/AppRouter/routes";
import { CurrentUserState } from "./currentUser.types";
import isAuthorized from "../../utils/isAuthorized";
import isMatching from "../../utils/isMatching";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectCurrentUserState = (state: AppState) => state.currentUser;

export const selectCurrentUser = createSelector(
  selectCurrentUserState,
  (currentUser: CurrentUserState) => currentUser.currentUser,
);

export const selectPersonalInfos = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.personalInfos,
);

export const selectCurrentUserKcId = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.kcId,
);

export const selectCurrentUserId = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.id,
);

export const selectRole = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.role,
);

export const selectIsAdmin = createSelector(
  selectRole,
  (role) => role === "admin",
);

export const selectRoutes = createSelector(selectCurrentUser, (user) =>
  routes.filter((route) => isAuthorized(user, route)),
);

export const selectRoutesForHeader = createSelector(selectCurrentUser, (user) =>
  routes.filter((route) =>
    isMatching(route.showInHeader, user.role && [user.role]),
  ),
);

export const selectRoutesForHome = createSelector(selectRoutes, (routes) =>
  routes.filter((route) => route.showInHome),
);
