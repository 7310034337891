import {
  upsertAdmin,
  deleteAdmin,
} from "../../../domain/manageUsers/manageUsers.actions";
import { selectCurrentUser } from "../../../domain/currentUser/currentUser.selectors";
import { IAdmin } from "../../../domain/manageUsers/manageUsers.types";
import { useDispatch, useSelector } from "react-redux";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import AdminCardDeleteModal from "./AdminCardDeleteAdmin/AdminCardDeleteModal";
import AdminCardPermission from "./AdminCardPermission/AdminCardPermission";
import AdminCardMail from "./AdminCardName/adminCardMail";
import trashIcon from "../../../assets/icons/trash.svg";
import editIcon from "../../../assets/icons/edit.svg";

import "./adminCard.scss";

interface AdminCardType {
  admin?: IAdmin;
}
const AdminCard: FC<AdminCardType> = ({
  admin,
}: AdminCardType): JSX.Element => {
  const methods = useForm({
    defaultValues: admin,
    mode: "onSubmit",
  });
  const { t } = useTranslation();
  const currentUser = useSelector(selectCurrentUser);
  const hasCreateAdmin = currentUser.permissions?.includes("write_user");
  const [registeredAdmin, setAdmin] = useState<IAdmin>(admin as IAdmin);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const isAnAdministrator = admin?.isAdministrator;
  const isMe = admin?.email === currentUser.email;
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(upsertAdmin(registeredAdmin));
    setEditMode(!editMode);
  };

  const handleSwitchChange = (
    checked: boolean,
    event: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string,
  ) => {
    const goodId = id.split("-")[0];
    const adminModified = {
      ...registeredAdmin,
      permissions: {
        ...registeredAdmin.permissions,
        [goodId]: checked,
      },
    };
    setAdmin(adminModified);
  };

  const deleteUser = () => {
    dispatch(deleteAdmin(admin as IAdmin));
    setIsModalOpen(!isModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const cancelEditMode = () => {
    methods.clearErrors("email");
    setEditMode(!editMode);
  };
  const toggleDeleteModal = () => {
    setIsModalOpen(!isModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const renderContent = () => (
    <>
      {hasCreateAdmin && isAnAdministrator && !isMe ? (
        <div className="actions-button">
          <button
            onClick={toggleDeleteModal}
            type="button"
            aria-label={t("delete") as string}
            className="action-button delete-button"
          >
            <img src={trashIcon} alt={t("delete_icon_alt")} />
          </button>
          <button
            onClick={toggleEditMode}
            type="button"
            aria-label={t("edit") as string}
            className="action-button edit-button"
          >
            <img src={editIcon} alt={t("edit_icon_alt")} />
          </button>
        </div>
      ) : (
        <></>
      )}

      <AdminCardDeleteModal
        isOpen={isModalOpen}
        openModale={toggleDeleteModal}
        deleteUser={() => deleteUser()}
        adminEmail={admin?.email as string}
      />
      <AdminCardMail
        ariaId={admin?.id as number}
        email={registeredAdmin.email}
        role={admin?.isAdminPrincipal ? t("Admin-principal") : t("Admin")}
        isMe={isMe}
      />
      <AdminCardPermission
        ariaId={admin?.id as number}
        editMode={editMode}
        handleSwitchChange={handleSwitchChange}
        accessChatbot={registeredAdmin?.permissions?.accessChatbot || false}
        modifySupport={registeredAdmin?.permissions?.modifySupport || false}
        createAdmin={registeredAdmin?.permissions?.createAdmin || false}
        pedagogicSupervision={
          registeredAdmin?.permissions?.pedagogicSupervision || false
        }
      />
      {editMode && (
        <div className="form-actions">
          <input
            className="m-button green"
            type="submit"
            value={t("Record") as string}
          />
          <input
            className="m-button red"
            type="reset"
            value={t("cancel") as string}
            onClick={cancelEditMode}
          />
        </div>
      )}
    </>
  );

  return isAnAdministrator ? (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      className="admin-card-item m-form"
    >
      {renderContent()}
    </form>
  ) : (
    <div className="admin-card-item m-form"> {renderContent()}</div>
  );
};

export default AdminCard;
