import {
  CreateThemeActionPayload,
  UpdateThemeActionPayload,
} from "../../domain/e-learning/e-learning.types";
import { DefaultValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FileInput from "../input/FileInput";
import Button from "../Button/Button";
import classNames from "classnames";
import Paper from "../Paper/Paper";
import Title from "../Page/Title";
import { FC } from "react";
import "./ThemeForm.scss";

export type ThemeFormValues =
  | UpdateThemeActionPayload
  | CreateThemeActionPayload;

interface ThemeFormProps {
  title: string;
  onSubmit: (data: ThemeFormValues) => void;
  defaultValues?: DefaultValues<ThemeFormValues>;
}

type Mode = "add" | "edit";

const ThemeForm: FC<ThemeFormProps> = ({
  title,
  onSubmit,
  defaultValues = {},
}) => {
  const params = useParams<{ id: string }>();
  const mode: Mode = params.id ? "edit" : "add";
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm<ThemeFormValues>({
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues,
    mode: "all",
  });

  const requiredErrorMessage = t("field_required");
  const fileSizeExceededErrorMessage = t("file_size_exceeded");

  const pictoMaxSize = 150 * 1024 * 1024;
  const videoMaxSize = 150 * 1024 * 1024;

  return (
    <form className="m-form" onSubmit={handleSubmit(onSubmit)}>
      <Title className="">{t(title)}</Title>
      <Paper fullWidth className="m-form-fields">
        <div className="m-form-fields-content">
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="title" className="m-form-label">
              {t("e_learning_title_field")}
            </label>
            <input
              id="title"
              name="title"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.title),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.title)}
            />
          </div>
          <div className="m-form-field m-form-field-full-width">
            <FileInput
              id="picto"
              accept="image/png, image/jpeg"
              ref={register({
                required: requiredErrorMessage,
                validate: (value) => {
                  const size = value[0]?.size;
                  if (size > pictoMaxSize) {
                    return fileSizeExceededErrorMessage;
                  }
                  return true;
                },
              })}
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.picto),
              })}
              aria-invalid={Boolean(errors.picto)}
              errors={errors}
            />
            <div className="m-form-field m-form-field-full-width">
              <label htmlFor="alt" className="m-form-label">
                {t("e_learning_alt_field")}
              </label>
              <input
                id="alt"
                name="alt"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.alt),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.alt)}
              />
            </div>
          </div>
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="video" className="m-form-label">
              {t("e_learning_video_field")}
            </label>
            <FileInput
              id="video"
              accept="video/*"
              ref={register({
                required: requiredErrorMessage,
                validate: (value) => {
                  const size = value[0]?.size;
                  if (size > videoMaxSize) {
                    return fileSizeExceededErrorMessage;
                  }
                  return true;
                },
              })}
              aria-invalid={Boolean(errors.video)}
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.video),
              })}
              errors={errors}
            />
          </div>
        </div>
      </Paper>
      <Paper fullWidth className="m-form-actions">
        <Button type="submit">{t("save")}</Button>
        <Button
          to={`/e-learning/${params.id}/courses/add`}
          className="m-form-action-main"
          disabled={mode === "add"}
        >
          {t("e_learning_add_courses")}
        </Button>
        <Button color="dangerous" variant="outlined" to="/e-learning">
          {t("cancel")}
        </Button>
      </Paper>
    </form>
  );
};

export default ThemeForm;
