import { useHistory } from "react-router-dom";
import { LocationDescriptor } from "history";

const useNavigate = (to?: LocationDescriptor): (() => void) => {
  const history = useHistory();

  const navigate = () => {
    if (to) {
      history.push(to);
    }
  };

  return navigate;
};

export default useNavigate;
