import { CreateExerciseActionPayload } from "../../../domain/e-learning/e-learning.types";
import { createExercise } from "../../../domain/e-learning/e-learning.actions";
import ExerciseForm from "../../../components/ExerciseForm/ExerciseForm";
import { Step } from "../../../components/StepBar/StepNav";
import { generatePath, useParams } from "react-router-dom";
import useNavigate from "../../../utils/useNavigate";
import mapWithId from "../../../utils/mapWithId";
import { useDispatch } from "react-redux";
import { FC, useMemo } from "react";

const AddExercise: FC = () => {
  const params = useParams<{ themeId: string; exerciseId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: CreateExerciseActionPayload) => {
    dispatch(createExercise(data));
    navigate();
  };

  const onSubmitWithId = (data: CreateExerciseActionPayload) => {
    onSubmit({ ...data, themeId: params.themeId });
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return <ExerciseForm mode="add" onSubmit={onSubmitWithId} steps={steps} />;
};

export default AddExercise;
