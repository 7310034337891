export const GET_KC_USER = "GET_KC_USER";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const ADD_PERSONAL_INFORMATION = "ADD_PERSONAL_INFORMATION";

export const CREATE_BENEFICIARY = "CREATE_BENEFICIARY";
export const CREATE_BENEFICIARY_SUCCESS = "CREATE_BENEFICIARY_SUCCESS";
export const CREATE_BENEFICIARY_FAILURE = "CREATE_BENEFICIARY_FAILURE";

export type ThemePropertySize = "sm" | "md" | "lg" | "xl";

export type ThemePropertyPalette = "dark1" | "dark2" | "light1" | "light2";

export type IPersonalInformations = {
  firstName: string;
  lastName: string;
  address: string;
  zipcode: string;
  city: string;
  gender: string;
  birthDate: string;
  fontFamily: string;
  fontSize: ThemePropertySize;
  lineSpacing: ThemePropertySize;
  wordSpacing: ThemePropertySize;
  palette: ThemePropertyPalette;
};

export type IBeneficiary = {
  id?: number;
  kcId?: string;
  email?: string;
  personalInfos?: IPersonalInformations;
  status?: string;
};

export interface AuthState {
  beneficiary: IBeneficiary;
  loading: boolean;
  errors?: string;
}

export interface AuthActionTypes {
  type: string;
  kcId?: string;
  personalInfos?: IPersonalInformations;
  email?: string;
  message?: string;
  beneficiary?: IBeneficiary;
  path?: string;
}
