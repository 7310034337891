import {
  IoHomeOutline,
  IoChatboxOutline,
  IoBookOutline,
  IoPersonAddOutline,
  IoPlayCircleOutline,
  IoPersonOutline,
} from "react-icons/io5";
import CoursesPlayer from "../../presentation/e-learning/courses/courses-player/courses-player";
import EditDictionnaryEntry from "../../presentation/dictionnary/EditDictionnaryEntry";
import AddDictionnaryEntry from "../../presentation/dictionnary/addDictionnaryEntry";
import MyStatistics from "../../presentation/e-learning/statistiques/statistiques";
import AddAdmin from "../../presentation/manageAdmins/add-admin/add-admin";
import Exercises from "../../presentation/e-learning/exercises/exercises";
import AddCourse from "../../presentation/e-learning/courses/AddCourse";
import ManageAdmin from "../../presentation/manageAdmins/manageAdmin";
import Exam from "../../presentation/e-learning/exercises/exam/exam";
import Dictionnary from "../../presentation/dictionnary/dictionnary";
import { IRouter } from "../../domain/currentUser/currentUser.types";
import Payment from "../../presentation/payment/payment";
import Profile from "../../presentation/profile/profile";
import Chatbot from "../../presentation/chatbot/chatbot";
import ELearning from "../../pages/ELearning/ELearning";
import AddTheme from "../../pages/AddTheme/AddTheme";
import Theme from "../../pages/Theme/Theme";
import Home from "../../pages/Home/Home";
import { v4 as uuid } from "uuid";

const routes: Omit<IRouter, "id">[] = [
  {
    path: "/",
    component: Home,
    exact: true,
    roles: ["user", "admin", "admin_principal"],
    showInHeader: ["user"],
    icon: IoHomeOutline,
  },
  {
    path: "/admin-manage",
    component: ManageAdmin,
    exact: true,
    roles: ["admin_principal"],
    showInHeader: ["admin_principal"],
    icon: IoPersonAddOutline,
  },
  {
    path: "/personal",
    component: Profile,
    exact: true,
    roles: ["user"],
    showInHeader: ["user"],
    icon: IoPersonOutline,
  },
  {
    path: "/e-learning",
    component: ELearning,
    exact: true,
    roles: ["admin", "admin_principal"],
    showInHeader: ["admin", "admin_principal"],
    icon: IoPlayCircleOutline,
  },
  {
    path: "/chatbot",
    component: Chatbot,
    exact: true,
    roles: ["user", "admin", "admin_principal"],
    showInHeader: ["user", "admin", "admin_principal"],
    icon: IoChatboxOutline,
  },
  {
    path: "/dictionnary",
    component: Dictionnary,
    exact: true,
    roles: ["user", "admin", "admin_principal"],
    showInHeader: ["user", "admin", "admin_principal"],
    icon: IoBookOutline,
  },
  {
    path: "/dashboard",
    component: MyStatistics,
    exact: true,
    roles: ["user"],
  },
  {
    path: "/courses/:themeId",
    component: CoursesPlayer,
    exact: true,
    roles: ["user"],
  },
  {
    path: "/exercises",
    component: Exercises,
    exact: true,
    roles: ["user"],
    showInHome: ["user"],
    label: "exercises",
  },
  {
    path: "/exam",
    component: Exam,
    exact: true,
    roles: ["user"],
    showInHome: ["user"],
    label: "exam",
  },
  {
    path: "/paiement",
    component: Payment,
    exact: true,
    roles: ["user"],
  },
  {
    path: "/admin-manage/add-admin",
    component: AddAdmin,
    exact: true,
    roles: ["admin_principal"],
  },
  {
    path: "/e-learning/add",
    component: AddTheme,
    exact: true,
    roles: ["admin", "admin_principal"],
  },
  {
    path: "/e-learning/:id",
    component: Theme,
    roles: ["admin", "admin_principal"],
  },
  {
    path: "/e-learning/:themeId/courses/add",
    component: AddCourse,
    exact: true,
    roles: ["admin", "admin_principal"],
  },
  {
    path: "/dictionnary/add",
    component: AddDictionnaryEntry,
    exact: true,
    roles: ["admin", "admin_principal"],
  },
  {
    path: "/dictionnary/edit/:id",
    component: EditDictionnaryEntry,
    exact: true,
    roles: ["admin", "admin_principal"],
  },
];

const enhancedRoutes: IRouter[] = routes.map((route) => ({
  id: uuid(),
  ...route,
}));

export default enhancedRoutes;
