import {
  ReactNode,
  HTMLProps,
  isValidElement,
  cloneElement,
  ForwardRefRenderFunction,
  forwardRef,
} from "react";
import useDialogContext from "./useDialogContext";
import { useMergeRefs } from "@floating-ui/react";
import Button from "../Button/Button";

interface DialogTriggerProps {
  children: ReactNode;
  asChild?: boolean;
}

const DialogTrigger: ForwardRefRenderFunction<
  HTMLElement,
  HTMLProps<HTMLElement> & DialogTriggerProps
> = ({ children, asChild = false, ...props }, propRef) => {
  const context = useDialogContext();
  const ref = useMergeRefs([
    context.refs.setReference,
    propRef,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (children as any)?.ref,
  ]);

  if (!context) {
    return null;
  }

  if (asChild && isValidElement(children)) {
    return cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    );
  }

  return (
    <Button
      ref={ref}
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </Button>
  );
};

export default forwardRef(DialogTrigger);
