import {
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from "@floating-ui/react";
import { forwardRef, ForwardRefRenderFunction, HTMLProps } from "react";
import useDialogContext from "./useDialogContext";
import Paper from "../Paper/Paper";
import "./DialogContent.scss";

const DialogContent: ForwardRefRenderFunction<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
> = (props, propRef) => {
  const { context: floatingContext, ...context } = useDialogContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!floatingContext.open) return null;

  return (
    <FloatingPortal>
      <FloatingOverlay className="dialog-overlay" lockScroll>
        <FloatingFocusManager context={floatingContext}>
          <Paper
            className="dialog-content"
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </Paper>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  );
};

export default forwardRef(DialogContent);
