import {
  deleteTheme,
  publishAllCourse,
  publishAllExercises,
} from "../../../../domain/e-learning/e-learning.actions";
import { selectThemes } from "../../../../domain/e-learning/e-learning.selectors";
import crossedEyeIcon from "../../../../assets/icons/oeil-barre.svg";
import ProgressCircular from "../../progress-bar/progress-circular";
import DeleteThemeModal from "../theme-modal/delete-theme-modal";
import trashIcon from "../../../../assets/icons/trash.svg";
import eyeIcon from "../../../../assets/icons/oeil.svg";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useState, useRef } from "react";
import "./theme-card.scss";

interface IThemeCard {
  id: string;
  icon?: string;
  alt: string;
  path: string;
  src?: string;
  isBeneficiary: boolean;
  subtitle?: string;
}

const ThemeCard: FC<IThemeCard> = ({
  id,
  icon,
  path,
  alt,
  src,
  isBeneficiary,
  subtitle = "",
}: IThemeCard) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [isImagePresent, setIsImagePresent] = useState(true);
  const alternativeIcon =
    "../../../../static/media/play-button-white.abfd1f94.svg";
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActive, setActive] = useState(false);
  const isVideoPresent = true;
  const videoRef = useRef<HTMLVideoElement>(null);
  const themes = useSelector(selectThemes);
  const currentTheme = themes.find((theme) => theme.id === id);
  const titles = currentTheme?.title;

  const isThemesInExercises =
    location.pathname === "/e-learning/exercises" ||
    location.pathname === "/exercises";
  let eLearningBloc;
  if (isThemesInExercises) {
    if (
      currentTheme?.exercises?.length &&
      currentTheme?.exercises?.some((bloc) => bloc.isPublished)
    ) {
      eLearningBloc = currentTheme?.exercises;
    }
  } else if (currentTheme?.courses?.length) {
    eLearningBloc = currentTheme?.courses;
  }

  const isPublished = eLearningBloc?.some(
    (bloc: { isPublished: boolean }) => bloc.isPublished === true,
  );
  const playVideo = () => videoRef?.current?.play();
  const pauseVideo = () => videoRef?.current?.pause();
  const switchVideo = () =>
    videoRef?.current?.paused ? playVideo() : pauseVideo();

  function handleToggleVideo() {
    setActive(!isActive);
    switchVideo();
  }

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
    document.body.classList.toggle("modal-open");
  };

  const deleteThemeById = () => {
    dispatch(deleteTheme(id));
    document.body.classList.toggle("modal-open");
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const publishCoursesOrExercisesDependingOnCurrentPage = () => {
    if (isThemesInExercises) {
      dispatch(publishAllExercises(id, false));
    } else {
      dispatch(publishAllCourse(id, false));
    }
  };

  if (isBeneficiary && !isPublished) {
    return <></>;
  }

  return (
    <>
      <li className={`theme-card ${isThemesInExercises ? "theme-round" : ""}`}>
        {!isBeneficiary && (
          <>
            <div className="progress-title">{titles}</div>
            <Link
              to={path}
              className="theme-link"
              aria-label={`${t("goTo")} ${t(alt)}`}
            >
              {isImagePresent ? (
                <img
                  src={icon}
                  alt={t(alt)}
                  onError={() => setIsImagePresent(false)}
                />
              ) : (
                <img src={alternativeIcon} alt={t(alt)} />
              )}
            </Link>
            <button
              onClick={toggleDeleteModal}
              type="button"
              aria-label={t("delete_theme") as string}
              className="delete-button"
            >
              <img src={trashIcon} alt={t("delete_icon_alt")} />
            </button>
            <div
              className={`published-button ${isPublished ? "publish" : "not-publish"}`}
            >
              {isPublished ? (
                <button
                  onClick={publishCoursesOrExercisesDependingOnCurrentPage}
                  type="button"
                  aria-label={t("dont_publish_courses") as string}
                  className="publish-action"
                >
                  <img src={eyeIcon} alt={t("published-courses")} />
                </button>
              ) : (
                <img src={crossedEyeIcon} alt={t("not-publish-courses")} />
              )}
            </div>
          </>
        )}

        {isBeneficiary && (
          <>
            <div className="progress-container">
              <ProgressCircular id={id} />
            </div>
            <Link
              to={path}
              className="theme-link"
              aria-label={`${t("goTo")} ${t(alt)}`}
            >
              {isImagePresent ? (
                <img
                  src={icon}
                  alt={t(alt)}
                  onError={() => setIsImagePresent(false)}
                />
              ) : (
                <img src={alternativeIcon} alt={t(alt)} />
              )}
            </Link>
            {isVideoPresent && ( // Utiliser la variable dans la condition
              <div className={`theme-video ${isActive ? "active" : ""}`}>
                <video ref={videoRef} muted crossOrigin="anonymous">
                  <source src={src} type="video/mp4" />
                  <track default lang="fr" kind="captions" src={subtitle} />
                </video>
              </div>
            )}
            <button
              type="button"
              className={`
                    ${isThemesInExercises ? "control-button-round" : "control-button"}
                    ${isActive ? "pause-button" : "play-button"}
                `}
              onClick={handleToggleVideo}
              aria-label={t("aria_label_play_video")}
            />
          </>
        )}
      </li>
      {isDeleteModalOpen && (
        <DeleteThemeModal
          isOpen={isDeleteModalOpen}
          openModale={toggleDeleteModal}
          deleteTheme={() => deleteThemeById()}
        />
      )}
    </>
  );
};

export default ThemeCard;
