import { ApiExecutorType } from "./apiExecutor.type";
import axios, { AxiosInstance } from "axios";

import ApiExecutor from "./apiExecutor";
import config from "../../config";

const TIMEOUT = 50000;
let instance: AxiosInstance;

function getApiInstance(): AxiosInstance {
  const baseURL = config.SIGNMOVE_API_URL;
  instance = axios.create({
    baseURL,
    timeout: TIMEOUT,
  });
  return instance;
}

export function updateAxiosInstanceWithTokenHeader(token?: string): void {
  instance.interceptors.request.use((config) => {
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });
}

export default function api(): ApiExecutorType {
  getApiInstance();
  return ApiExecutor(instance);
}
