import { useTranslation } from "react-i18next";
import "./adminCardMail.scss";

type AdminCardMailProp = {
  ariaId: number;
  email?: string;
  role: string;
  isMe: boolean;
};

function AdminCardMail(props: AdminCardMailProp): JSX.Element {
  const { ariaId, email, role, isMe } = props;
  const { t } = useTranslation();
  return (
    <div className="admin-infos">
      <span className="infos-title">
        {isMe ? `${role} - ${t("me")}` : role}
      </span>
      <div className="form-group">
        <label htmlFor={`email-${ariaId}`}>
          {t("Email")}
          <input
            type="text"
            id={`email-${ariaId}`}
            value={email}
            name="email"
            readOnly
          />
        </label>
      </div>
    </div>
  );
}

export default AdminCardMail;
