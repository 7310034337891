import {
  AuthActionTypes,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  CREATE_BENEFICIARY,
  CREATE_BENEFICIARY_SUCCESS,
  CREATE_BENEFICIARY_FAILURE,
  IBeneficiary,
} from "./auth.types";

export const getUserSuccess = (beneficiary: IBeneficiary): AuthActionTypes => ({
  type: GET_USER_SUCCESS,
  beneficiary,
});

export const getUserFailure = (message: string): AuthActionTypes => ({
  type: GET_USER_FAILURE,
  message,
});

export const createBeneficiary = (
  beneficiary: IBeneficiary,
): AuthActionTypes => ({
  type: CREATE_BENEFICIARY,
  beneficiary,
});

export const createBeneficiarySuccess = (
  beneficiary: IBeneficiary,
): AuthActionTypes => ({
  type: CREATE_BENEFICIARY_SUCCESS,
  beneficiary,
});

export const createBeneficiaryFailure = (message: string): AuthActionTypes => ({
  type: CREATE_BENEFICIARY_FAILURE,
  message,
});
