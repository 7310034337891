import fileUpload from "../../../src/assets/icons/file-upload-multiple.svg";
import "./FileUploadCustom.scss";
import { FC } from "react";

interface FileUploadCustom {
  label: string;
  onChange?: () => void;
}

const FileUploadCustom: FC<FileUploadCustom> = ({ onChange, label }) => {
  return (
    <div className="file-upload">
      <img src={fileUpload} alt="upload" />
      <p>{label}</p>
      <input type="file" onChange={onChange} />
    </div>
  );
};

export default FileUploadCustom;
