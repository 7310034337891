import { ReactKeycloakProvider } from "@react-keycloak/web";
import Loading from "../../pages/Loading/Loading";
import { FC, ReactNode } from "react";
import keycloak from "./keycloak";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "check-sso",
      }}
      LoadingComponent={<Loading />}
    >
      {children}
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
