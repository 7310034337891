import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import { IAdmin } from "../../domain/manageUsers/manageUsers.types";
import requestUrlConstructor from "../utils/requestUrlConstructor";

function UserApi(executor: ApiExecutorType): {
  getAllAdmins: () => Promise<{
    body: IAdmin[];
    status: number;
  }>;
  createAdmin: (admin: string | IAdmin | undefined) => Promise<{
    body: IAdmin;
    status: number;
  }>;
  deleteAdmin: (adminId: string) => Promise<{
    status: number;
  }>;
} {
  // GET ALL ADMINS
  function getAllAdmins(): Promise<{
    body: IAdmin[];
    status: number;
  }> {
    return executor.get("/user/admin");
  }

  // CREATE ADMIN
  function createAdmin(admin: string | IAdmin | undefined): Promise<{
    body: IAdmin;
    status: number;
  }> {
    return executor.post("/user/admin", admin);
  }

  // DELETE ADMIN
  function deleteAdmin(adminId: string): Promise<{
    status: number;
  }> {
    const id = {
      id: adminId,
    };
    const url = "/user/admin";
    const urlCreated = requestUrlConstructor(url, id);

    return executor.del(urlCreated);
  }

  return { getAllAdmins, createAdmin, deleteAdmin };
}

export default UserApi;
