import useThemeProvider from "./useThemeProvider";
import { FC, ReactNode } from "react";
import "../../styles/main.scss";

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  useThemeProvider();
  return <>{children}</>;
};

export default ThemeProvider;
