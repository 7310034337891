import {
  UPSERT_ADMIN,
  UPSERT_ADMIN_FAILURE,
  UPSERT_ADMIN_SUCCESS,
  DELETE_ADMIN,
  DELETE_ADMIN_FAILURE,
  DELETE_ADMIN_SUCCESS,
  GET_ADMINS,
  GET_ADMINS_FAILURE,
  GET_ADMINS_SUCCESS,
  ManageActionTypes,
  ManageUsersState,
  IAdmin,
} from "./manageUsers.types";
import { upsertAdminStateReducer } from "./manageUsers.utils";

const INITIAL_STATE: ManageUsersState = {
  admins: [],
  loading: false,
  error: false,
};

const manageUsersReducer = (
  state = INITIAL_STATE,
  action: ManageActionTypes,
):
  | ManageUsersState
  | {
      admins: IAdmin[] | undefined;
      loading: boolean;
      error: boolean;
    } => {
  switch (action.type) {
    case UPSERT_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPSERT_ADMIN_SUCCESS:
      return {
        ...state,
        admins: upsertAdminStateReducer(state.admins, action.admin as IAdmin),
        loading: false,
        error: false,
      };
    case UPSERT_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_ADMINS:
      return {
        ...state,
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.admins,
      };
    case GET_ADMINS_FAILURE:
      return {
        ...state,
        error: true,
      };
    case DELETE_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter((admin) => admin.id !== action.admin?.id),
        loading: false,
        error: false,
      };
    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default manageUsersReducer;
