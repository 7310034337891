export function updateAt<T>(array: T[] = [], value?: T, index = -1): T[] {
  if (value === undefined || index < 0) {
    return array;
  }
  return [...array.slice(0, index), value, ...array.slice(index + 1)];
}

export function updateBy<T>(
  array: T[] = [],
  value?: T,
  predicate: (value: T) => boolean = () => false,
): T[] {
  const index = array.findIndex(predicate);
  return updateAt(array, value, index);
}
