import ManagerUserSaga from "../data/manageUsers/manageUsers.sagas";
import CurrentUserSaga from "../data/currentUser/currentUser.sagas";
import ELearningSaga from "../data/e-learning/e-learning.sagas";
import PaymentSaga from "../data/payment/Payment.sagas";
import ContactSaga from "../data/contact/Message.saga";
import AuthSaga from "../data/auth/auth.sagas";
import { all, fork } from "redux-saga/effects";
import apiInstance from "../utils/fetchApi";
import { SagaIterator } from "redux-saga";

export const api = apiInstance();

function* sagas(): SagaIterator {
  yield all([
    fork(ManagerUserSaga(api).userSaga),
    fork(AuthSaga(api).authSaga),
    fork(CurrentUserSaga(api).currentUserSaga),
    fork(ELearningSaga(api).eLearningSaga),
    fork(ContactSaga(api).contactSaga),
    fork(PaymentSaga(api).paymentSaga),
  ]);
}

export default sagas;
