import { selectCurrentUser } from "../../domain/currentUser/currentUser.selectors";
import { initPayment, savePayment } from "../../domain/payment/payment.actions";
import { selectInitPayment } from "../../domain/payment/payment.selector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FC, useEffect } from "react";
import "./payment.scss";

import { getCurrentUser } from "../../domain/currentUser/currentUser.actions";

const Payment: FC = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectCurrentUser);
  const history = useHistory();

  function getPayment() {
    dispatch(initPayment(user.kcId as string));
  }

  /* eslint-disable */
  useEffect(() => {
    dispatch(getCurrentUser(user.kcId as string));

    if (user.personalInfos && user.status !== "done") {
      getPayment();
    }
  }, [dispatch]);

  // @ts-ignore
  document.body.addEventListener("payment-success", (e: CustomEvent) => {
    dispatch(savePayment(e.detail));
    history.push("/resume");
  });

  const paymentData = useSelector(selectInitPayment);

  // @ts-ignore
  if (!paymentData || !paymentData.id) {
    return (
      <div className="loading-container">
        <p className="please-wait">Veuillez patientez</p>
        <br />
        <p>Nous nous connectons au système de paiement</p>
      </div>
    );
  }

  return (
    <>
      <div className="paygreen-information">
        <div className="paygreen-methods-price">Prix : 150 euros</div>
        <div className="paygreen-methods-description">
          <p>Le paiement se fait en une fois</p>
          <p>Vous avez accès à Signmove pendant 10 mois</p>
        </div>
      </div>
      <div
        id="paygreen-container"
        data-payment={JSON.stringify(paymentData)}
      ></div>
      <div id="paygreen-methods-container"></div>
      <div id="paygreen-form">
        <h1 className="paygreen-title">Informations bancaires</h1>
        <div id="paygreen-pan-frame">
          <label className="paygreen-label">Numéro de carte</label>
        </div>
        <div id="paygreen-exp-frame">
          <label className="paygreen-label">Date d'expiration</label>
        </div>
        <div id="paygreen-cvv-frame">
          <label className="paygreen-label">CVV</label>
        </div>
      </div>
      <button id="payBtn" type="button" style={{ display: "none" }}>
        Valider
      </button>
      <Helmet>
        <script type="text/javascript">
          {` 
                    function initPaygreen(paymentData) {                    
                            paygreenjs.init({
                                paymentOrderID: paymentData.id,
                                objectSecret: paymentData.object_secret,
                                publicKey: 'pk_8f354d6f019f46ca92f2381248831445',
                                mode: 'payment',
                                paymentMethod: 'bank_card',
                                style: {
                                    input: {
                                        base: {
                                            color: '#09141c', // firefly
                                            fontSize: '18px',
                                        },
                                    },
                                },
                            });
                    
                        const handleClick = (e) => {
                            paygreenjs.submitPayment();
                        };
                    
                        document.getElementById('payBtn').addEventListener('click', handleClick);
                    
                    
                        paygreenjs.attachEventListener(
                            paygreenjs.Events.FULL_PAYMENT_DONE,
                            (event) => {
                                const paymentEvent = new CustomEvent('payment-success', {detail: paygreenjs.status()});
                                document.body.dispatchEvent(paymentEvent);
                            },
                        );
                        paygreenjs.attachEventListener(
                            paygreenjs.Events.REUSABLE_ALLOWED_CHANGE,
                            (event) => console.log(event.detail.reusable_allowed),
                        );
                    
                    
                        paygreenjs.attachEventListener(
                            paygreenjs.Events.PAYMENT_FLOW_ONCHANGE,
                            () => {
                                const status = paygreenjs.status()
                                const flows = paygreenjs.status().flows;
                                const lastFlow = flows[flows.length - 1];
                                if (lastFlow?.method) {
                                    document.querySelector('#payBtn').style.display = 'block';
                                } else {
                                    document.querySelector('#payBtn').style.display = 'none';
                                }
                            },
                        );
                    }
                    
                    var paygreenContainer = document.getElementById('paygreen-container');
                    var paymentData = JSON.parse(paygreenContainer.dataset.payment);

                    if ('undefined' != typeof paymentData.id) {
                        var paymentData = JSON.parse(paygreenContainer.dataset.payment);
                        initPaygreen(paymentData);
                    }
                    `}
        </script>
      </Helmet>
    </>
  );
};

export default Payment;
