import { serialize } from "object-to-formdata";

const sanitizeData = (
  data: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.keys(data).reduce((acc, name) => {
    const value = data[name];
    if (value instanceof FileList) {
      for (const file of value) {
        if (file.size > 0) {
          return { ...acc, [name]: value };
        }
        return acc;
      }
      return { ...acc, [name]: value };
    } else if (value instanceof File) {
      if (value.size > 0) {
        return { ...acc, [name]: value };
      }
      return acc;
    } else {
      return { ...acc, [name]: value };
    }
  }, {});
};

const createFormData = (data: Record<string, unknown>): FormData => {
  const sanitizedData = sanitizeData(data);
  const formData = serialize(sanitizedData, {
    indices: true,
    noFilesWithArrayNotation: true,
    dotsForObjectNotation: true,
  });

  return formData;
};

export default createFormData;
