import { IconType } from "react-icons";

import { IPersonalInformations } from "../auth/auth.types";
import { ComponentType } from "react";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";

export const GET_ROLE_USER = "GET_ROLE_USER";

export const GET_PERSONAL_INFORMATIONS = "GET_PERSONAL_INFORMATIONS";
export const GET_PERSONAL_INFORMATIONS_SUCCESS =
  "GET_PERSONAL_INFORMATIONS_SUCCESS";
export const GET_PERSONAL_INFORMATIONS_FAILURE =
  "GET_PERSONAL_INFORMATIONS_FAILURE";

export const UPDATE_INFOS = "UPDATE_INFOS";
export const UPDATE_INFOS_SUCCESS = "UPDATE_INFOS_SUCCESS";
export const UPDATE_INFOS_FAILURE = "UPDATE_INFOS_FAILURE";

export type IUpdateInfos = {
  kcId?: string;
  personnalInformations?: IPersonalInformations;
};

export type Role = "user" | "admin" | "admin_principal";

export type Permission =
  | "read_user"
  | "write_user"
  | "read_e_learning"
  | "write_e_learning"
  | "read_chatbot"
  | "write_chatbot";

export type IRouter = {
  id: string;
  path: string;
  component: ComponentType;
  exact?: boolean;
  roles?: Role[];
  permissions?: Permission[];
  label?: string;
  alt?: string;
  icon?: IconType;
  image?: string;
  video?: string;
  showInHeader?: Role[];
  showInHome?: Role[];
  order?: number;
};

export interface ICurrentUser {
  id?: string;
  kcId?: string;
  email?: string;
  personalInfos?: IPersonalInformations;
  role?: Role;
  permissions?: Permission[];
  status?: string;
}
export interface CurrentUserState {
  currentUser: ICurrentUser;
  loading: boolean;
  errors?: string;
}

export interface CurrentUserActionTypes {
  type: string;
  id?: string;
  kcId?: string;
  email?: string;
  personalInfos?: IPersonalInformations;
  role?: Role;
  permissions?: Permission[];
  status?: string;
  data?: IUpdateInfos;
  message?: string;
  currentUser?: ICurrentUser;
}
