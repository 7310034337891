import { selectThemeById } from "../../domain/e-learning/e-learning.selectors";
import { deleteTheme } from "../../domain/e-learning/e-learning.actions";
import { useSelector, useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import DialogContent from "../Dialog/DialogContent";
import DialogTrigger from "../Dialog/DialogTrigger";
import IconButton from "../IconButton/IconButton";
import DialogClose from "../Dialog/DialogClose";
import { IoTrashSharp } from "react-icons/io5";
import "./DeleteThemeConfirmationDialog.scss";
import { FC, useCallback } from "react";
import Button from "../Button/Button";
import Dialog from "../Dialog/Dialog";

interface DeleteThemeConfirmationDialogProps {
  id: string;
}

const DeleteThemeConfirmationDialog: FC<DeleteThemeConfirmationDialogProps> = ({
  id,
}) => {
  const theme = useSelector(selectThemeById(id));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onConfirm = useCallback(() => {
    dispatch(deleteTheme(id));
  }, [dispatch, id]);
  if (!theme) return null;
  return (
    <Dialog>
      <DialogTrigger asChild>
        <IconButton color="dangerous">
          <IoTrashSharp />
        </IconButton>
      </DialogTrigger>
      <DialogContent>
        <p className="dialog-message">
          <Trans
            i18nKey="delete_explication_theme"
            values={{ title: theme.title }}
            components={{
              bold: <span className="delete-theme-confirmation-dialog-title" />,
            }}
          />
        </p>
        <div className="dialog-actions">
          <Button color="dangerous" onClick={onConfirm}>
            {t("delete_confirmation_theme")}
          </Button>
          <DialogClose>{t("cancel")}</DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteThemeConfirmationDialog;
