import DialogContext, { DialogContextType } from "./DialogContext";
import { useContext } from "react";

const useDialogContext = (): DialogContextType => {
  const context = useContext(DialogContext);

  if (context == null) {
    throw new Error("Dialog components must be wrapped in <Dialog />");
  }

  return context;
};

export default useDialogContext;
