import defaultThemePic from "../../assets/img/defaultThemePic.png";
import editIconRound from "../../assets/icons/edit-round.svg";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import "./TitleEdit.scss";

interface TitleEditProps {
  title?: string;
  onClick?: () => void;
  img?: string;
}

const TitleEdit: FC<TitleEditProps> = ({ onClick, title = "titre", img }) => {
  const { t } = useTranslation();

  return (
    <div className="theme-title-edit">
      <div className="theme-infos">
        <img
          src={img ? img : defaultThemePic}
          className="theme-image"
          alt="theme-image"
        />
        <p>{title}</p>
      </div>
      <button onClick={onClick}>
        <img src={editIconRound} alt={t("edit_icon_alt")} />
      </button>
    </div>
  );
};

export default TitleEdit;
