import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FC, useEffect } from "react";
import classNames from "classnames";

import {
  IBeneficiary,
  IPersonalInformations,
} from "../../../../domain/auth/auth.types";
import { selectCurrentUser } from "../../../../domain/currentUser/currentUser.selectors";
import { createBeneficiary } from "../../../../domain/auth/auth.actions";
import Button from "../../../../components/Button/Button";
import Paper from "../../../../components/Paper/Paper";
import formatDate from "../../../../utils/formatDate";
import "./personal-informations.scss";

const PersonalInformations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { handleSubmit, register, reset, errors, control } =
    useForm<IPersonalInformations>({
      defaultValues: user.personalInfos,
      criteriaMode: "all",
      shouldFocusError: false,
    });
  useEffect(() => {
    const handleCreate = async () => {
      const userProfile = await keycloak.loadUserProfile();
      if (userProfile) {
        const data: IBeneficiary = {
          kcId: user.kcId || keycloak.subject,
          email: userProfile.email,
          status: "infos",
        };
        dispatch(createBeneficiary(data));
      }
    };
    handleCreate();
  }, [dispatch, keycloak, user.kcId]);

  const handleUpdate = async (personalInfos: IPersonalInformations) => {
    const userProfile = await keycloak.loadUserProfile();
    if (userProfile) {
      const data: IBeneficiary = {
        kcId: user.kcId || keycloak.subject,
        email: userProfile.email,
        status: "registered",
        personalInfos,
      };
      dispatch(createBeneficiary(data));

      history.push("/register/payment");
    }
  };

  const handleReset = () => {
    reset(user.personalInfos);
  };

  const requiredHelperText = t("required");
  const requiredErrorMessage = t("field_required");
  return (
    <section className="auth-personal-informations">
      <form
        className="m-form auth-personal-informations-form"
        onSubmit={handleSubmit(handleUpdate)}
      >
        <Paper className="m-form-fields" fullWidth>
          <div className="m-form-fields-content m-form-fields-content-col-2 auth-personal-informations-form-fields-content">
            <div className="m-form-field">
              <label htmlFor="lastName" className="m-form-label">
                {t("lastName")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.lastName),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.lastName)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.lastName?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="firstName" className="m-form-label">
                {t("firstName")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.firstName),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.firstName)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.firstName?.message}
              </legend>
            </div>
            <div className="m-form-field m-form-field-full-width">
              <label htmlFor="address" className="m-form-label">
                {t("address")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.address),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.address)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.address?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="zipcode" className="m-form-label">
                {t("zipcode")} ({requiredHelperText})
              </label>
              <input
                type="text"
                placeholder={t("zipcode-placeholder")}
                name="zipcode"
                id="zipcode"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.zipcode),
                })}
                ref={register({
                  required: requiredErrorMessage,
                  pattern: {
                    value: /[0-9]{5}/,
                    message: t("invalid_format_zipcode"),
                  },
                })}
                aria-invalid={Boolean(errors.zipcode)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.zipcode?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="city" className="m-form-label">
                {t("city")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.city),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.city)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.city?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="city" className="m-form-label">
                {t("birthDate")} ({requiredHelperText})
              </label>
              <Controller
                control={control}
                name="birthDate"
                rules={{ required: requiredErrorMessage }}
                render={({ ref, value, onChange, name }) => (
                  <input
                    name={name}
                    ref={ref}
                    value={value}
                    onChange={(event) => {
                      onChange(formatDate(event.target.value));
                    }}
                    type="date"
                    id="birthDate"
                    className={classNames("m-form-input", {
                      "m-form-input-error": Boolean(errors.birthDate),
                    })}
                    aria-invalid={Boolean(errors.birthDate)}
                  />
                )}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.birthDate?.message}
              </legend>
            </div>
            <div className="m-form-field m-form-field-full-width auth-personal-informations-form-field-gc">
              <input
                className="m-form-input-checkbox auth-personal-informations-form-field-gc-input"
                type="checkbox"
                id="gc"
                required
                aria-required="true"
              />
              <legend className="m-form-helper-text auth-personal-informations-form-field-gc-helper-text">
                {t("gc_helper_text")} ({requiredHelperText})
              </legend>
            </div>
          </div>
        </Paper>
        <Paper className="m-form-actions" fullWidth>
          <Button type="submit" className="m-form-action-main">
            {t("validate")}
          </Button>
          <Button color="dangerous" onClick={handleReset}>
            {t("cancel")}
          </Button>
        </Paper>
      </form>
    </section>
  );
};
export default PersonalInformations;
