import { ManageUsersState } from "./manageUsers.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectManageAdmins = (state: AppState) => state.manageUser;

export const selectAdmins = createSelector(
  selectManageAdmins,
  (manageUser: ManageUsersState) => manageUser.admins,
);
