import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { FC } from "react";

import {
  getCurrentUser,
  getPersonalInformations,
} from "../../../../domain/currentUser/currentUser.actions";
import illustration from "../../../../assets/img/validation-register-illustration.svg";
import { selectBeneficiary } from "../../../../domain/auth/auth.selectors";
import { createBeneficiary } from "../../../../domain/auth/auth.actions";
import fakeVideo from "../../../../assets/img/fake-video.png";
import checkIcon from "../../../../assets/icons/check.svg";

import IconButton from "../../../../components/IconButton/IconButton";
import "./resume-register.scss";

const ResumeRegister: FC = () => {
  const { handleSubmit } = useForm();
  const beneficiary = useSelector(selectBeneficiary);
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(createBeneficiary({ ...beneficiary, status: "done" }));
    dispatch(getCurrentUser(beneficiary.kcId as string));
    dispatch(getPersonalInformations(beneficiary.kcId as string));
    window.location.href = "/";
  };

  return (
    <section className="resume-content">
      <div className="resume-wrapper">
        <div className="resume-video">
          <img
            src={fakeVideo}
            alt="Vidéo de félicitation"
            className="resume-video"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="m-form">
          <img
            src={illustration}
            alt="Illustration pour la validation de l'inscription"
            className="resume-illustration"
          />
          <IconButton type="submit" aria-label="Submit">
            <img src={checkIcon} alt="Ajout d'un bénéficiaire" />
          </IconButton>
        </form>
      </div>
    </section>
  );
};

export default ResumeRegister;
