import { FC } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";

interface FormInputProps {
  register: (Ref, RegisterOptions?) => void;
  errors: FieldErrors;
  label?: string;
  id: string;
  inpuType: string;
  placeholder?: string;
  required?: boolean;
  requiredText?: string;
  readonly?: boolean;
}

const FormInput: FC<FormInputProps> = ({
  register,
  errors,
  label,
  inpuType,
  id,
  placeholder,
  required = false,
  requiredText,
  readonly = true,
}: FormInputProps) => (
  <>
    <label htmlFor={id}>
      {label} {required ? requiredText : null}
      <input
        ref={register}
        id={id}
        name={id}
        type={inpuType}
        placeholder={placeholder}
        aria-invalid={errors[id] ? "true" : "false"}
        readOnly={readonly}
      />
      <ErrorMessage
        errors={errors}
        name={id}
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <span key={type} className="text-error">
              {message}
            </span>
          ))
        }
      />
    </label>
  </>
);

export default FormInput;
