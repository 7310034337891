import { ComponentProps, FC, Ref } from "react";
import { useTranslation } from "react-i18next";
import "./SelectInput.scss";

type Option = {
  label: string;
  value: string;
};

export interface SelectInputProps extends ComponentProps<"select"> {
  options: Option[];
  register: Ref<HTMLSelectElement>;
  multiple?: boolean;
}

const SelectInput: FC<SelectInputProps> = ({
  name,
  id,
  options,
  register,
  multiple = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className="select-input-wrapper">
      <select
        size={5}
        name={name}
        id={id}
        className="m-form-input select-input"
        ref={register}
        multiple={multiple}
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="select-input-option"
          >
            {t(option.label)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
