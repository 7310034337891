import {
  SHOW_SNACKBAR,
  RESET_SNACKBAR,
  ISnackBar,
  SuccessOrErrorActionTypes,
  SuccessOrErrorState,
} from "./interactionsWithUsers.types";

const INITIAL_STATE: SuccessOrErrorState = {
  snackbar: {
    status: false,
    type: "",
    message: "",
  },
};

const interactionsWithUsersReducer = (
  state = INITIAL_STATE,
  action: SuccessOrErrorActionTypes,
): {
  snackbar: ISnackBar | undefined;
} => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: action.data,
      };
    case RESET_SNACKBAR:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default interactionsWithUsersReducer;
