export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const RESET_SNACKBAR = "RESET_SNACKBAR";

export type ISnackBar = {
  status: boolean;
  type: string;
  message: string;
};

export interface SuccessOrErrorState {
  snackbar: ISnackBar;
}

export interface ResetSnackbarActionType {
  type: string;
}

export interface SuccessOrErrorActionTypes {
  type: string;
  data?: ISnackBar;
}
