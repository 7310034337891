import { addDictionnaryEntry } from "../../domain/e-learning/e-learning.actions";
import DictionnaryEntryForm from "./dictionnaryEntryForm";
import { DictionnaryEntry } from "./dictionnaryEntry";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./addDictionnaryEntry.scss";
import { FC } from "react";

const AddDictionnaryEntry: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddDictionnaryEntry = (dictionnaryEntry: DictionnaryEntry) => {
    dispatch(addDictionnaryEntry(dictionnaryEntry));
    history.push("/dictionnary");
  };

  return (
    <DictionnaryEntryForm
      title="add-a-word"
      onSubmit={handleAddDictionnaryEntry}
    />
  );
};
export default AddDictionnaryEntry;
