import { ICurrentUser, IRouter } from "../domain/currentUser/currentUser.types";
import { isMatching } from "./isMatching";

const isAuthorized = (
  user: Pick<ICurrentUser, "role" | "permissions">,
  route: Pick<IRouter, "roles" | "permissions">,
): boolean =>
  isMatching(route.roles, user.role && [user.role]) &&
  isMatching(route.permissions, user.permissions);

export default isAuthorized;
