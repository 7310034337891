import AddThemeExercise from "../../../assets/icons/green-add-s.svg";
import arrowBack from "../../../assets/icons/arrow-back.svg";
import addAdmin from "../../../assets/icons/addAdmin_s.svg";
import AddAdminCard from "./add-admin-card/add-admin-card";
import slash from "../../../assets/icons/slash.svg";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import "./add-admin.scss";

const AddAdmin: FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="manage-admin-container">
      <h1 className="title-hidden">{t("h1_manage_admins")}</h1>
      <div className="themes-actions">
        <NavLink exact to="/admin-manage" className="themes-link" role="button">
          <img src={arrowBack} alt="revenir en arrière" />
        </NavLink>
        <div className="ariane-container">
          <Link
            to="/admin-manage"
            className="process"
            role="button"
            style={{
              backgroundImage: `url(${addAdmin})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
            }}
            title={t("go_to_e-learning_home")}
          />
          <span className="process">
            <img src={slash} alt="processus pour créer un cours" />
          </span>
          <Link
            to={(location) => location}
            className="process"
            role="button"
            style={{
              backgroundImage: `url(${AddThemeExercise})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              height: "100%",
            }}
            title={t("actual_page")}
            aria-disabled
          />
        </div>
      </div>
      <div className="admins-wrapper">
        <AddAdminCard />
      </div>
    </div>
  );
};

export default AddAdmin;
