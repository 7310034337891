import { selectRole } from "../../domain/currentUser/currentUser.selectors";
import ChatbotBeneficiary from "./chatbot-beneficiary/chatbot-beneficiary";
import ChatbotAdmin from "./chatbot-admin/chatbot-admin";
import { useSelector } from "react-redux";
import { FC } from "react";

const Chatbot: FC = () => {
  const role = useSelector(selectRole);

  return role === "user" ? <ChatbotBeneficiary /> : <ChatbotAdmin />;
};
export default Chatbot;
