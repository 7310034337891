import { useTranslation } from "react-i18next";
import Header from "../Header/Header";
import { FC, ReactNode } from "react";
import "./Layout.scss";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pop-up-screen-size-container" aria-hidden="true">
        <p className="pop-up-screen-size">
          {t("Pop-up-display-error-screen-size")}
        </p>
      </div>
      <Header />
      <main className="layout" role="main">
        {children}
      </main>
    </>
  );
};

export default Layout;
