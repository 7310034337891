import {
  UPSERT_ADMIN,
  DELETE_ADMIN,
  GET_ADMINS,
  IAdmin,
  ManageActionTypes,
} from "../../domain/manageUsers/manageUsers.types";
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects";
import UserApi from "./manageUsers.api";

import {
  upsertAdminFailure,
  upsertAdminSuccess,
  deleteAdminFailure,
  deleteAdminSuccess,
  getAdminsFailure,
  getAdminsSuccess,
} from "../../domain/manageUsers/manageUsers.actions";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import getErrorMessage from "../../utils/getErrorMessage";

export default function ManagerUserSaga(apiExecutor: ApiExecutorType): {
  userSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const userApi = UserApi(apiExecutor);
  function* createAdmin(action: ManageActionTypes): Generator<
    | CallEffect<{
        body: IAdmin;
        status: number;
      }>
    | PutEffect<ManageActionTypes>,
    void,
    {
      body: IAdmin;
    }
  > {
    try {
      const { body: admin } = yield call(userApi.createAdmin, action.admin);
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_registration",
      };
      yield put(showSnackBar(snackbar));
      yield put(upsertAdminSuccess(admin));
    } catch (e) {
      const snackbar = {
        type: "error",
        message: getErrorMessage(e),
        status: true,
      };
      yield put(showSnackBar(snackbar));

      yield put(upsertAdminFailure());
    }
  }

  function* getAdmins(): Generator<
    | CallEffect<{
        body: IAdmin[];
        status: number;
      }>
    | PutEffect<ManageActionTypes>,
    void,
    {
      body: IAdmin[];
    }
  > {
    try {
      const { body: admins } = yield call(userApi.getAllAdmins);
      yield put(getAdminsSuccess(admins));
    } catch (e) {
      yield put(getAdminsFailure());
    }
  }
  function* deleteAdmin(action: ManageActionTypes): Generator<
    | CallEffect<{
        status: number;
      }>
    | PutEffect<ManageActionTypes>,
    void,
    unknown
  > {
    try {
      yield call(userApi.deleteAdmin, action?.admin?.id as unknown as string);
      const snackbar = {
        status: true,
        type: "success",
        message: "admin_deleted",
      };
      yield put(deleteAdminSuccess(action.admin as IAdmin));
      yield put(showSnackBar(snackbar));
    } catch (e) {
      yield put(deleteAdminFailure());
    }
  }

  function* userSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(UPSERT_ADMIN, createAdmin);
    yield takeLatest(GET_ADMINS, getAdmins);
    yield takeLatest(DELETE_ADMIN, deleteAdmin);
  }

  return { userSaga };
}
