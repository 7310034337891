import { FC, SyntheticEvent } from "react";
import { IconType } from "react-icons";
import classNames from "classnames";
import "./Tab.scss";

export type TabProps = {
  label: string;
  icon: IconType;
  value: number;
  active?: boolean;
  onChange?: (event: SyntheticEvent, value: number) => void;
  disabled?: boolean;
};

const Tab: FC<TabProps> = ({
  label,
  icon: Icon,
  value,
  active = false,
  onChange,
  disabled = false,
}: TabProps) => {
  const handleSelectTab = (event: SyntheticEvent) => {
    if (onChange) {
      onChange(event, value);
    }
  };
  return (
    <li className={classNames("tab", { "tab-active": active })}>
      <button
        type="button"
        onClick={handleSelectTab}
        className="tab-button"
        disabled={disabled}
      >
        {Icon && <Icon className="tab-icon" />}
        <span className="tab-label">{label}</span>
      </button>
    </li>
  );
};

export default Tab;
