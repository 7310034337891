import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { ApiExecutorType } from "./apiExecutor.type";
import getErrorMessage from "../getErrorMessage";

function ApiExecutor(executor: AxiosInstance): ApiExecutorType {
  async function handleWithHeader(
    request:
      | Promise<AxiosResponse<unknown>>
      | PromiseLike<{ data: unknown; status: number }>
      | { data: unknown; status: number },
  ): Promise<{
    body: unknown;
    status: number;
  }> {
    try {
      const { data, status } = await request;
      return {
        body: data,
        status,
      };
    } catch (error) {
      if (error && axios.isAxiosError(error)) {
        if (error.response?.config.url === "users/reset-password") {
          throw new Error();
        }

        if (error.response?.status === 500) {
          if (error.response?.data && error.response?.data.message) {
            return {
              body: error.response?.data.message,
              status: 500,
            };
          }
          return {
            body: "Unknown response server",
            status: 500,
          };
        }
        if (
          error.response?.status &&
          error.response?.status !== 498 &&
          error.response?.status > 399 &&
          error.response?.status < 500
        ) {
          const { data, status } = await request;
          return {
            body: data,
            status,
          };
        }
        return {
          body: error.response?.data,
          status: error.response?.status || 400,
        };
      } else {
        return {
          body: getErrorMessage(error),
          status: 400,
        };
      }
    }
  }

  function get(url: string): Promise<{
    body: unknown;
    status: number;
  }> {
    return handleWithHeader(executor.get(url));
  }

  function patch(
    url: string,
    body: unknown,
    config: AxiosRequestConfig | undefined,
  ): Promise<{
    body: unknown;
    status: number;
  }> {
    return handleWithHeader(executor.patch(url, body, config));
  }

  function put(
    url: string,
    body: unknown,
  ): Promise<{
    body: unknown;
    status: number;
  }> {
    return handleWithHeader(executor.put(url, body));
  }

  function post(
    url: string,
    body: unknown,
  ): Promise<{
    body: unknown;
    status: number;
  }> {
    return handleWithHeader(executor.post(url, body));
  }

  function del(url: string): Promise<{
    body: unknown;
    status: number;
  }> {
    return handleWithHeader(executor.delete(url));
  }

  function upload(url: string, body: unknown) {
    return handleWithHeader(
      executor.post(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    );
  }

  function putUpload(url: string, body: unknown) {
    return handleWithHeader(
      executor.put(url, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    );
  }

  return {
    get,
    patch,
    put,
    post,
    del,
    upload,
    putUpload,
  };
}

export default ApiExecutor;
