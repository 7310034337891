import { ComponentPropsWithRef, forwardRef } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { FieldErrors } from "react-hook-form";

interface FileInputProps extends ComponentPropsWithRef<"input"> {
  label?: string;
  id: "title" | "video" | "alt" | "picto";
  required?: boolean;
  requiredText?: string;
  className: string;
  errors: FieldErrors;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      label,
      id,
      required = true,
      requiredText,
      className,
      errors,
      ...props
    }: FileInputProps,
    ref,
  ) => {
    return (
      <>
        <label htmlFor={id}>
          {label} {required ? requiredText : null}
          <input
            tabIndex={0}
            id={id}
            name={id}
            type="file"
            ref={ref}
            className={className}
            {...props}
          />
          <ErrorMessage
            errors={errors}
            name={id}
            render={({ message }) => {
              return <p style={{ color: "red" }}>{message}</p>;
            }}
          />
        </label>
      </>
    );
  },
);

FileInput.displayName = "FileInput";

export default FileInput;
