import {
  GET_ALL_MESSAGES,
  GET_ALL_MESSAGES_FAILURE,
  GET_ALL_MESSAGES_SUCCESS,
  Message,
  SAVE_MESSAGE,
  SAVE_MESSAGE_FAILURE,
  SAVE_MESSAGE_SUCCESS,
} from "./message.types";

const INITIAL_STATE = {
  messages: [],
  error: "",
  loading: false,
};

const contactReducer = (
  state = INITIAL_STATE,
  action: { type: string; messages: Message[]; error: string },
): {
  loading: boolean;
  error: string;
  messages: Message[];
} => {
  switch (action.type) {
    case GET_ALL_MESSAGES:
    case SAVE_MESSAGE:
      return {
        ...state,
        loading: true,
      };
    case SAVE_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages: action.messages,
      };
    }
    case GET_ALL_MESSAGES_FAILURE:
    case SAVE_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default contactReducer;
