import { FC, ReactNode } from "react";
import classNames from "classnames";
import "./Page.scss";

interface PageProps {
  children: ReactNode;
  disabledGutters?: boolean;
}

const Page: FC<PageProps> = ({ children, disabledGutters = false }) => (
  <div
    className={classNames("page", { "page-disable-gutters": disabledGutters })}
  >
    {children}
  </div>
);

export default Page;
