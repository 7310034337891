import { selectCurrentUserId } from "../../../domain/currentUser/currentUser.selectors";
import CircularAnimation from "../../../components/circle-animation/circle-animation";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import config from "../../../config";
import axios from "axios";

type PracticeSession = {
  id: string;
  themeId: string;
  userId: number;
  isFinished: boolean;
  counter: number;
  correctTotal: string;
  falseTotal: string;
  updatedAt: string;
};

const apiUrl = config.SIGNMOVE_API_URL;

const MyStatistics: FC = () => {
  const [percentage, setPercentage] = useState(0);
  const [uniqueThemeIds, setUniqueThemeIds] = useState(0);
  const [completedThemeIdsWithCounter, setCompletedThemeIdsWithCounter] =
    useState(0);
  const currentUserId = useSelector(selectCurrentUserId);

  useEffect(() => {
    axios
      .get(`${apiUrl}/practice-session/beneficiary/user/${currentUserId}`)
      .then((response) => {
        const sortedData = response.data.sort(
          (a: PracticeSession, b: PracticeSession) => {
            if (a.themeId === b.themeId) {
              return (
                new Date(b.updatedAt).getTime() -
                new Date(a.updatedAt).getTime()
              );
            }
            return a.themeId.localeCompare(b.themeId);
          },
        );

        const nonZeroCounterSessions = sortedData.filter(
          (session) => session.counter > 0,
        ).length;
        const uniqueIds = new Set(sortedData.map((session) => session.themeId))
          .size;

        setUniqueThemeIds(uniqueIds);
        setCompletedThemeIdsWithCounter(nonZeroCounterSessions);

        const computedPercentage = (nonZeroCounterSessions / uniqueIds) * 100;

        setPercentage(computedPercentage);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [currentUserId]);

  return (
    <>
      <CircularAnimation
        percentage={percentage}
        totalThemes={uniqueThemeIds}
        completedThemes={completedThemeIdsWithCounter}
      />
    </>
  );
};

export default MyStatistics;
