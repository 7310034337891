interface Config {
  SIGNMOVE_API_URL?: string;
}

const loadConfig = async (): Promise<Config> => {
  try {
    const response = await fetch("/config.json", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return { SIGNMOVE_API_URL: data.SIGNMOVE_API_URL };
  } catch (error) {
    return {};
  }
};

const config = await loadConfig();

export default config;
