import Bounces from "../../components/Bounces/Bounces";
import Content from "../../components/Page/Content";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import { FC } from "react";
import "./Loading.scss";

const Loading: FC = () => {
  const { t } = useTranslation();
  return (
    <main className="loading">
      <Page>
        <Content>
          <div className="loading-content">
            <Bounces />
            <p>{t("loading")}...</p>
          </div>
        </Content>
      </Page>
    </main>
  );
};

export default Loading;
