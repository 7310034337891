import upArrow from "../../assets/icons/up-arrow.svg";
import { FC, useState } from "react";
import Paper from "../Paper/Paper";
import "./SideBarToggle.scss";

interface SideBarToggleProps {
  title: string;
}

const SideBarToggle: FC<SideBarToggleProps> = ({ children, title }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isRotated, setIsRotated] = useState(false);

  const toggleHidden = () => {
    setIsHidden(!isHidden);
    setIsRotated(!isRotated);
  };

  return (
    <Paper fullWidth className="edit-files">
      <div className="edit-files-title">
        <p>{title}</p>
        <button onClick={toggleHidden}>
          <img
            src={upArrow}
            className={isRotated ? "rotate180" : ""}
            alt="toggle-arrow"
          />
        </button>
      </div>
      <div className={`file-edit ${isHidden ? "hidden" : ""}`}>{children}</div>
    </Paper>
  );
};

export default SideBarToggle;
