import {
  IoInformationCircleOutline,
  IoDocumentAttachOutline,
} from "react-icons/io5";
import PersonalInformations from "./personal-informations/personal-informations";
import TabPanel from "../../components/tabs/TabPanel";
import { FC, SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "../../components/tabs/Tabs";
import Tab from "../../components/tabs/Tab";

const Profile: FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Tabs value={activeTab} onChange={handleChangeTab}>
        <Tab
          value={0}
          label={t("personal_information")}
          icon={IoInformationCircleOutline}
        />
        <Tab
          value={1}
          label={t("my_document")}
          icon={IoDocumentAttachOutline}
          disabled
        />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <PersonalInformations />
      </TabPanel>
    </>
  );
};
export default Profile;
