import VideoPlayer from "../../utils/video/VideoPlayer";
import { IoArrowForwardCircle } from "react-icons/io5";
import IconButton from "../IconButton/IconButton";
import { useTranslation } from "react-i18next";
import { LocationDescriptor } from "history";
import Paper from "../Paper/Paper";
import "./NavigationCard.scss";
import { FC } from "react";

interface NavigationCardProps {
  title?: string;
  imageUrl?: string;
  videoUrl?: string;
  alt?: string;
  to: LocationDescriptor;
}

const NavigationCard: FC<NavigationCardProps> = ({
  title,
  videoUrl,
  to,
  imageUrl,
  alt,
}) => {
  const { t } = useTranslation();
  return (
    <Paper className="navigation-card" fullHeight>
      {title && <h1 className="navigation-card-title">{t(title)}</h1>}
      <VideoPlayer src={videoUrl} poster={imageUrl} />
      <IconButton size="large" to={to} aria-label={alt && t(alt)}>
        <IoArrowForwardCircle />
      </IconButton>
    </Paper>
  );
};

export default NavigationCard;
