import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";

function PaymentApi(executor: ApiExecutorType): {
  initPayment: (paymentData) => Promise<{
    body: unknown;
    status: unknown;
  }>;
  savePayment: (payment) => unknown;
} {
  function handleErrorResponse(error) {
    console.error("Error in Payment API:", error);
    throw error;
  }

  function initPayment(paymentData) {
    return executor
      .post("/payment/init", { paymentData })
      .catch(handleErrorResponse);
  }

  function savePayment(payment) {
    return executor
      .post("/payment/save", { payment })
      .catch(handleErrorResponse);
  }

  return { initPayment, savePayment };
}

export default PaymentApi;
