import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  GET_PERSONAL_INFORMATIONS,
  GET_PERSONAL_INFORMATIONS_SUCCESS,
  GET_PERSONAL_INFORMATIONS_FAILURE,
  UPDATE_INFOS,
  UPDATE_INFOS_SUCCESS,
  UPDATE_INFOS_FAILURE,
  ICurrentUser,
  IUpdateInfos,
  CurrentUserActionTypes,
} from "./currentUser.types";
import { IPersonalInformations } from "../auth/auth.types";

export const getCurrentUser = (kcId: string): CurrentUserActionTypes => ({
  type: GET_CURRENT_USER,
  kcId,
});

export const getCurrentUserSuccess = (
  currentUser: ICurrentUser,
): CurrentUserActionTypes => ({
  type: GET_CURRENT_USER_SUCCESS,
  currentUser,
});

export const getCurrentUserFailure = (
  message: string,
): CurrentUserActionTypes => ({
  type: GET_CURRENT_USER_FAILURE,
  message,
});

export const getPersonalInformations = (
  kcId: string,
): CurrentUserActionTypes => ({
  type: GET_PERSONAL_INFORMATIONS,
  kcId,
});

export const getPersonalInformationsSuccess = (
  personalInfos: IPersonalInformations,
): CurrentUserActionTypes => ({
  type: GET_PERSONAL_INFORMATIONS_SUCCESS,
  personalInfos,
});

export const getPersonalInformationsFailure = (
  message: string,
): CurrentUserActionTypes => ({
  type: GET_PERSONAL_INFORMATIONS_FAILURE,
  message,
});

export const updateInfos = (data: IUpdateInfos): CurrentUserActionTypes => ({
  type: UPDATE_INFOS,
  data,
});

export const updateInfosSuccess = (
  personalInfos: IPersonalInformations,
): CurrentUserActionTypes => ({
  type: UPDATE_INFOS_SUCCESS,
  personalInfos,
});

export const updateInfosFailure = (
  message: string,
): CurrentUserActionTypes => ({
  type: UPDATE_INFOS_FAILURE,
  message,
});
