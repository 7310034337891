import { selectMessage } from "../../../domain/contact/message.selector";
import { getAllMessages } from "../../../domain/contact/message.actions";
import { Message } from "../../../domain/contact/message.types";
import { IBeneficiary } from "../../../domain/auth/auth.types";
import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./chatbot-admin.scss";

const ChatbotAdmin: FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [messagesDisplayed, setMessageDisplayed] = useState<Message[]>();
  const dispatch = useDispatch();

  const [{ limit, start }, setPaginate] = useState<{
    limit: number;
    start: number;
  }>({ limit: 20, start: 0 });
  useEffect(() => {
    dispatch(getAllMessages());
  }, [dispatch]);
  const messagesUsers = useSelector(selectMessage);
  const paginate = (increment) => {
    setPaginate({ limit: limit + increment, start: start + increment });
  };

  useEffect(() => {
    if (limit > messagesUsers.length + 9) setPaginate({ limit: 10, start: 0 });
    if (start < 0) setPaginate({ limit: 10, start: 0 });
    if (messagesUsers.length) {
      setMessageDisplayed(messagesUsers.slice(start, limit));
    }
  }, [start, limit, messagesUsers]);

  const getDisplayedUserName = (message): string => {
    const user: IBeneficiary = message.beneficiary;
    if (user?.personalInfos?.firstName && user?.personalInfos?.lastName)
      return `${user?.personalInfos?.lastName} ${user?.personalInfos?.firstName}`;
    if (user?.email) return user?.email;
    return t("Anonyme");
  };
  return (
    <div className="chatbot-admin">
      <h1 className="title-hidden">{t("chatbot")}</h1>
      <div className="chatbot-admin-table-container">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>{t("Received_message")}</th>
            </tr>
          </thead>
          <tbody>
            {messagesDisplayed?.map((message) => (
              <tr key={message.id}>
                <td className="name-display">
                  {getDisplayedUserName(message)}
                </td>
                {message.createdAt && (
                  <td className="date-display">
                    {`${new Intl.DateTimeFormat(language, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(
                      new Date(message.createdAt),
                    )} ${t("at")} ${new Intl.DateTimeFormat(language, {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(message.createdAt))}`}
                  </td>
                )}
                <td className="text-display">{message.text}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="button-paginate-container">
        <button
          type="button"
          aria-label={t("more-message")}
          onClick={() => paginate(-10)}
        >
          {"<<"}
        </button>

        <button
          type="button"
          id={t("less-message")}
          aria-label={t("less-message")}
          onClick={() => paginate(10)}
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};
export default ChatbotAdmin;
