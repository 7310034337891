import {
  IExercise,
  UpdateExerciseActionPayload,
} from "../../../domain/e-learning/e-learning.types";
import { selectExerciseById } from "../../../domain/e-learning/e-learning.selectors";
import { updateExercise } from "../../../domain/e-learning/e-learning.actions";
import ExerciseForm from "../../../components/ExerciseForm/ExerciseForm";
import { Step } from "../../../components/StepBar/StepNav";
import { generatePath, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "../../../utils/useNavigate";
import mapWithId from "../../../utils/mapWithId";
import { AppState } from "../../../redux/types";
import { FC, useMemo } from "react";

const EditExercise: FC = () => {
  const params = useParams<{ themeId: string; exerciseId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: UpdateExerciseActionPayload) => {
    dispatch(updateExercise(params.exerciseId, data));
    navigate();
  };

  const defaultValues = useSelector<AppState, IExercise | undefined>(
    selectExerciseById(params.themeId, params.exerciseId),
  );

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_edit_exercise",
          to: generatePath("/e-learning/:id/exercises/:exerciseId", {
            id: params.themeId,
            exerciseId: params.exerciseId,
          }),
        },
      ]),
    [params],
  );

  return (
    <ExerciseForm
      mode="edit"
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      steps={steps}
    />
  );
};

export default EditExercise;
