import { AuthState, IPersonalInformations } from "./auth.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectAuth = (state: AppState) => state.auth;

export const selectBeneficiary = createSelector(
  selectAuth,
  (auth: AuthState) => auth.beneficiary,
);

export const selectInfosToBeneficiary = createSelector(
  selectAuth,
  (auth: AuthState) => auth.beneficiary.personalInfos as IPersonalInformations,
);
