export const INIT_PAYMENT = "INIT_PAYMENT";
export const INIT_PAYMENT_SUCCESS = "INIT_PAYMENT_SUCCESS";
export const INIT_PAYMENT_FAILURE = "INIT_PAYMENT_FAILURE";
export const SAVE_PAYMENT = "SAVE_PAYMENT";
export const SAVE_PAYMENT_SUCCESS = "SAVE_PAYMENT_SUCCESS";
export const SAVE_PAYMENT_FAILURE = "SAVE_PAYMENT_FAILURE";

export type Address = {
  line1: string;
  line2?: string | null;
  city: string;
  country: string;
  postal_code: string;
};

export type BillingAddress = Address;

export type ShippingAddress = Address;

export type Buyer = {
  email: string;
  first_name: string;
  last_name: string;
  reference: string;
  country: string;
  billing_address: BillingAddress;
};

export type Payment = {
  amount: number;
  currency: string;
  mode: string;
  description: string;
  reference: string | null;
  auto_capture: boolean;
  platforms: [string];
  partial_allowed: boolean;
  buyer: Buyer;
  shipping_address: ShippingAddress;
  kcId: string;
};

export type PaymentState = {
  paymentData: unknown;
  loading: boolean;
  errors?: string;
};
