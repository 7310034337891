import { selectDictionnaryEntry } from "../../domain/e-learning/e-learning.selectors";
import { updateDictionnaryEntry } from "../../domain/e-learning/e-learning.actions";
import DictionnaryEntryForm from "./dictionnaryEntryForm";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DictionnaryEntry } from "./dictionnaryEntry";
import { AppState } from "../../redux/types";
import "./addDictionnaryEntry.scss";
import { FC } from "react";

const EditDictionnaryEntry: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<Pick<DictionnaryEntry, "id">>();
  const defaultValues = useSelector((state: AppState) =>
    selectDictionnaryEntry(state, id),
  );

  const handleUpdateDictionnaryEntry = (dictionnaryEntry: DictionnaryEntry) => {
    const updatedDictionnaryEntry: DictionnaryEntry = {
      id,
      word: dictionnaryEntry.word,
      definition: dictionnaryEntry.definition,
      video: dictionnaryEntry.video,
      videoId: dictionnaryEntry.videoId,
    };
    dispatch(updateDictionnaryEntry(updatedDictionnaryEntry));
    history.push("/dictionnary");
  };

  return (
    <DictionnaryEntryForm
      onSubmit={handleUpdateDictionnaryEntry}
      title="edit-a-word"
      defaultValues={defaultValues}
    />
  );
};
export default EditDictionnaryEntry;
