import { UseFloatingReturn, UseInteractionsReturn } from "@floating-ui/react";
import { createContext, Dispatch, SetStateAction } from "react";

export type DialogContextType = {
  open?: boolean;
  setOpen: (
    open: boolean,
  ) => void | Dispatch<SetStateAction<boolean | undefined>>;
  labelId?: string;
  descriptionId?: string;
  setLabelId: Dispatch<SetStateAction<string | undefined>>;
  setDescriptionId: Dispatch<SetStateAction<string | undefined>>;
} & UseInteractionsReturn &
  UseFloatingReturn;

const DialogContext = createContext<DialogContextType | null>(null);

export default DialogContext;
