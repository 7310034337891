import { useTranslation } from "react-i18next";
import { FC, SyntheticEvent } from "react";
import "./admin-card-permission.scss";
import Switch from "react-switch";

type AdminCardPermissionProps = {
  handleSwitchChange?: (
    checked: boolean,
    event: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string,
  ) => void;
  editMode: boolean;
  accessChatbot: boolean;
  modifySupport: boolean;
  createAdmin: boolean;
  pedagogicSupervision: boolean;
};
const AdminCardPermission: FC<AdminCardPermissionProps> = ({
  accessChatbot = false,
  modifySupport = false,
  createAdmin = false,
  pedagogicSupervision = false,
  handleSwitchChange,
  editMode,
}: AdminCardPermissionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className="permissions-list"
      role="group"
      aria-labelledby="permissions"
    >
      <div id="permissions" className="permissions-title">
        {t("Autorisations")}
      </div>

      <div className="permissions-item">
        <label htmlFor="createAdmin">
          <span className="switch-element-title">
            {t("Create admin account")}
          </span>
          <span className="switch-element">
            <Switch
              checked={createAdmin}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id="createAdmin"
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={!editMode}
            />
          </span>
        </label>
      </div>

      <div className="permissions-item">
        <label htmlFor="modifySupport">
          <span className="switch-element-title">
            {t("Modify pedagogique support")}
          </span>
          <span className="switch-element">
            <Switch
              checked={modifySupport}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              id="modifySupport"
              disabled={!editMode}
            />
          </span>
        </label>
      </div>
      <div className="permissions-item">
        <label htmlFor="pedagogicSupervision">
          <span className="switch-element-title">
            {t("Access pedagogic supervision")}
          </span>
          <span className="switch-element">
            <Switch
              checked={pedagogicSupervision}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id="pedagogicSupervision"
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#017c8f"
              disabled={!editMode}
            />
          </span>
        </label>
      </div>
      <div className="permissions-item">
        <label htmlFor="accessChatbot">
          <span className="switch-element-title">{t("Access chatbot")}</span>
          <span className="switch-element">
            <Switch
              checked={accessChatbot}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id="accessChatbot"
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={!editMode}
            />
          </span>
        </label>
      </div>
    </div>
  );
};

export default AdminCardPermission;
