import { CreateCourseActionPayload } from "../../../domain/e-learning/e-learning.types";
import { createCourse } from "../../../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../../../components/StepBar/StepNav";
import CourseForm from "../../../components/CourseForm/CourseForm";

import FileUploadCustom from "../../../components/FileUploadCustom/FileUploadCustom";
import SideBarToggle from "../../../components/SideBarToggle/SideBarToggle";
import ImportedFile from "../../../components/ImportedFile/ImportedFile";
import TitleEdit from "../../../components/TitleEdit/TitleEdit";
import LeftSidebar from "../../../components/Page/LeftSidebar";
import { generatePath, useParams } from "react-router-dom";
import Content from "../../../components/Page/Content";
import Topbar from "../../../components/Page/Topbar";
import useNavigate from "../../../utils/useNavigate";
import Paper from "../../../components/Paper/Paper";
import Page from "../../../components/Page/Page";
import mapWithId from "../../../utils/mapWithId";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FC, useMemo } from "react";
import "./AddCourse.scss";

const AddCourse: FC = () => {
  const { t } = useTranslation();

  const params = useParams<{ themeId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: CreateCourseActionPayload) => {
    dispatch(createCourse(data));
    navigate();
  };

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_add_courses",
          to: generatePath("/e-learning/:id/courses/add", {
            id: params.themeId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <Page disabledGutters>
      <Topbar className="add-theme-topbar">
        <StepNav steps={steps} />
      </Topbar>
      <LeftSidebar>
        <div className="sideBar-content">
          <Paper fullWidth className="m-form-fields">
            <p className="m-form-label">{t("theme_title")}</p>
            {/* @TODO Get picto and title*/}
            <TitleEdit title="Les autres usagers" />
          </Paper>
          <SideBarToggle title={t("theme_import-file-title")}>
            <FileUploadCustom label={t("theme_import-file-upload")} />
            <div className="videos-list">
              <p className="videos-list-title">
                {t("theme_imported-files-title")}
              </p>
              {/*Composant à mapper avec les videos*/}
              <ImportedFile videoTitle="Video 03" videoId="video03" />
            </div>
          </SideBarToggle>
        </div>
      </LeftSidebar>
      <Content>
        <CourseForm title="e_learning_add_courses" onSubmit={onSubmit} />
      </Content>
    </Page>
  );
};

export default AddCourse;
