import { selectRoutesForHeader } from "../../domain/currentUser/currentUser.selectors";
import { ReactComponent as Logo } from "../../assets/img/Logo.svg";
import { useKeycloak } from "@react-keycloak/web";
import { IoLogOutOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import MenuItem from "./MenuItem";
import { FC } from "react";
import Menu from "./Menu";
import "./Header.scss";

const Header: FC = () => {
  const { keycloak } = useKeycloak();
  const links = useSelector(selectRoutesForHeader);
  const handleLogOut = () => {
    keycloak.logout({
      redirectUri: new URL("/portal", window.location.origin).href,
    });
  };
  return (
    <header className="header">
      {keycloak.authenticated && (
        <>
          <Menu>
            {links.map(({ id, path, alt, icon }) => (
              <MenuItem key={id} to={path} alt={alt} IconComponent={icon} />
            ))}
          </Menu>
          <Menu>
            <MenuItem onClick={handleLogOut} IconComponent={IoLogOutOutline} />
          </Menu>
        </>
      )}
      <Logo aria-label="logo" className="header-logo" />
    </header>
  );
};

export default Header;
