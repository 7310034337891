import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import { useKeycloak } from "@react-keycloak/web";
import Page from "../../components/Page/Page";
import "./NotAuthorized.scss";
import { FC } from "react";

const NotAuthorized: FC = () => {
  const { keycloak } = useKeycloak();
  return (
    <Page>
      <Content>
        <div className="not-authorized">
          <p className="m-typography-body1">
            Désolé, vous n&apos;êtes pas autorisé à accéder à cette page
          </p>
          {keycloak.authenticated ? (
            <Button to="/">Revenir à la page d&apos;accueil</Button>
          ) : (
            <Button to="/portal">Accéder au portail de connexion</Button>
          )}
        </div>
      </Content>
    </Page>
  );
};

export default NotAuthorized;
