import { FC, useEffect, useState } from "react";
import "./circle-animation.scss";

interface ICircularAnimationProps {
  percentage: number;
  totalThemes: number;
  completedThemes: number;
}

const StatsCircular: FC<ICircularAnimationProps> = ({
  percentage,
  totalThemes,
  completedThemes,
}): JSX.Element => {
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  const completedCurrentPercentage = percentage;

  useEffect(() => {
    let animationInterval: ReturnType<typeof setInterval>;
    const animatePercentage = () => {
      setAnimatedPercentage((prevPercentage) => {
        const newPercentage = prevPercentage + 1;
        if (newPercentage <= completedCurrentPercentage) {
          return newPercentage;
        }
        return completedCurrentPercentage;
      });
    };

    if (completedCurrentPercentage > animatedPercentage) {
      animationInterval = setInterval(animatePercentage, 10);
    }
    return () => {
      if (animationInterval) {
        clearInterval(animationInterval);
      }
    };
  }, [completedCurrentPercentage, animatedPercentage]);

  const radius = 180;
  const circumference = 2 * Math.PI * radius;
  const dashArray = `${circumference} ${circumference}`;
  const dashOffset = (1 - animatedPercentage / 100) * circumference;
  return (
    <div className="progress-container">
      <svg className="progress-circle" width="480" height="480">
        <defs>
          <mask id="mask">
            <rect x="0" y="0" width="480" height="480" fill="white" />
            <circle
              cx="240"
              cy="240"
              r={radius}
              strokeWidth="40"
              fill="none"
              strokeLinecap="round"
              stroke="white"
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              transform="rotate(-90, 240, 240)"
            />
          </mask>
        </defs>
        <circle
          className="progress-circle-bg"
          cx="240"
          cy="240"
          r={radius}
          strokeWidth="20"
          fill="none"
          stroke="#E2F3F4"
        />
        <circle
          className="progress-circle-fg"
          cx="240"
          cy="240"
          r={radius}
          strokeWidth="20"
          fill="none"
          strokeLinecap="round"
          stroke="#00505B"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>
      <div className="progress-percentage">
        {animatedPercentage.toFixed(1)}%
      </div>
      <div className="progress-info">
        <p>Série terminée</p>
        <div className="circular-animation__label mt-5 fw-bolder d-block w-100">{`${completedThemes} / ${totalThemes}`}</div>
      </div>
    </div>
  );
};

export default StatsCircular;
