import { ThemePropertyPalette } from "../../domain/auth/auth.types";

export type PaletteColor = {
  textColorPrimary: string;
  foregroundColorPrimary: string;
  foregroundColorSecondary: string;
  backgroundColorPrimaryMain: string;
  backgroundColorPrimaryLight: string;
  backgroundColorSecondaryMain: string;
};

const palette: Record<ThemePropertyPalette, PaletteColor> = {
  light1: {
    foregroundColorPrimary: "deep-teal",
    backgroundColorPrimaryMain: "white",
    textColorPrimary: "deep-teal",
    foregroundColorSecondary: "elephant",
    backgroundColorSecondaryMain: "ghost-white",
    backgroundColorPrimaryLight: "iceberg",
  },
  light2: {
    foregroundColorPrimary: "black-pearl",
    backgroundColorPrimaryMain: "white",
    textColorPrimary: "black-pearl",
    foregroundColorSecondary: "elephant",
    backgroundColorSecondaryMain: "ghost-white",
    backgroundColorPrimaryLight: "iceberg",
  },
  dark1: {
    foregroundColorPrimary: "ghost-white",
    backgroundColorPrimaryMain: "deep-teal",
    textColorPrimary: "deep-teal",
    foregroundColorSecondary: "ghost-white",
    backgroundColorSecondaryMain: "elephant",
    backgroundColorPrimaryLight: "oslo-grey",
  },
  dark2: {
    foregroundColorPrimary: "white",
    backgroundColorPrimaryMain: "black-pearl",
    textColorPrimary: "black-pearl",
    foregroundColorSecondary: "ghost-white",
    backgroundColorSecondaryMain: "elephant",
    backgroundColorPrimaryLight: "oslo-grey",
  },
};

export default palette;
