import { selectRoutesForHome } from "../../domain/currentUser/currentUser.selectors";
import NavigationCard from "../../components/NavigationCard/NavigationCard";
import Content from "../../components/Page/Content";
import Page from "../../components/Page/Page";
import { useSelector } from "react-redux";
import { FC } from "react";
import "./Home.scss";

const Home: FC = () => {
  const links = useSelector(selectRoutesForHome);
  return (
    <Page>
      <Content>
        <nav className="home">
          <ul className="home-nav">
            {links.map(({ path, label }) => (
              <li className="home-nav-item" key={path}>
                <NavigationCard title={label} to={path} />
              </li>
            ))}
          </ul>
        </nav>
      </Content>
    </Page>
  );
};
export default Home;
