import {
  selectCoursesByThemeId,
  selectExercisesByThemeId,
} from "../../domain/e-learning/e-learning.selectors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Course from "../Course/Course";
import "./CourseList.scss";
import { FC } from "react";

interface CourseListProps {
  themeId?: string;
}

const CourseList: FC<CourseListProps> = ({ themeId }) => {
  const { t } = useTranslation();
  const courses = useSelector(selectCoursesByThemeId(themeId));
  const exercises = useSelector(selectExercisesByThemeId(themeId));
  if (courses.length === 0) {
    return <p className="course-list-empty">{t("Aucun cours")}</p>;
  }
  return (
    <ol className="course-list">
      {courses.map((course) => (
        <Course
          key={course.id}
          themeId={course.themeId}
          {...course}
          exercises={exercises}
        />
      ))}
    </ol>
  );
};

export default CourseList;
