import { useForm, Controller, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FC, useMemo } from "react";

import type {
  IPersonalInformations,
  ThemePropertyPalette,
  ThemePropertySize,
} from "../../../domain/auth/auth.types";
import {
  selectCurrentUserKcId,
  selectPersonalInfos,
} from "../../../domain/currentUser/currentUser.selectors";
import RadioGroupField from "../../../components/RadioGroupField/RadioGroupField";
import { updateInfos } from "../../../domain/currentUser/currentUser.actions";
import { IUpdateInfos } from "../../../domain/currentUser/currentUser.types";
import palette, { PaletteColor } from "../../../utils/ThemeProvider/palette";
import SelectInput from "../../../components/SelectInput/SelectInput";
import VideoPlayer from "../../../utils/video/VideoPlayer";
import Button from "../../../components/Button/Button";
import Paper from "../../../components/Paper/Paper";
import formatDate from "../../../utils/formatDate";
import "./personal-informations.scss";

const fontFamilies = [
  "Quicksand",
  "Accessible-DfA",
  "Andika",
  "Aphont",
  "Atkinson",
  "Calibri",
  "SitKaSmall",
  "Luciole",
  "Sylexiad Serif",
  "Sylexiad Sans",
].map((value) => ({ value, label: value }));

const fontSizeOptions = ["sm", "md", "lg", "xl"].map((value) => ({
  value,
  label: value,
}));

type ThemePropertyPaletteOption = {
  value: ThemePropertyPalette;
} & PaletteColor;

const paletteOptions: ThemePropertyPaletteOption[] = [
  "light1",
  "light2",
  "dark1",
  "dark2",
].map((value) => ({
  value,
  ...palette[value],
}));

const PersonalInformations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultValues = useSelector(selectPersonalInfos);
  const kcId = useSelector(selectCurrentUserKcId);
  const { handleSubmit, register, reset, errors, control } =
    useForm<IPersonalInformations>({
      defaultValues,
      criteriaMode: "all",
      shouldFocusError: false,
    });

  const handleUpdate = (personnalInformations: IPersonalInformations) => {
    const data: IUpdateInfos = {
      kcId,
      personnalInformations,
    };
    dispatch(updateInfos(data));
  };

  const handleReset = () => {
    reset(defaultValues);
  };

  const fontSizeValue = useWatch<ThemePropertySize>({
    control,
    name: "fontSize",
    defaultValue: defaultValues?.fontSize || "sm",
  });
  const spacingOptions = useMemo(
    () =>
      fontSizeOptions.slice(
        fontSizeOptions.findIndex(
          (fontSizeOption) => fontSizeOption.value === fontSizeValue,
        ),
      ),
    [fontSizeValue],
  );

  const requiredHelperText = t("required");
  const requiredErrorMessage = t("field_required");

  return (
    <section className="personal-informations">
      <form
        className="m-form personal-informations-form"
        onSubmit={handleSubmit(handleUpdate)}
      >
        <Paper className="m-form-fields" fullWidth>
          <h2 className="m-form-fields-title fg-color-primary">
            {t("interface_settings")}
          </h2>
          <div className="m-form-fields-content m-form-fields-content-col-3">
            <div className="m-form-field m-form-field-full-width">
              <label htmlFor="fontFamily" className="m-form-label">
                {t("font-family")}
              </label>
              <SelectInput
                name="fontFamily"
                id="fontFamily"
                className="m-form-input"
                register={register({ required: requiredErrorMessage })}
                options={fontFamilies}
              />
            </div>
            <div className="m-form-field">
              <label htmlFor="fontSize" className="m-form-label">
                {t("size")}
              </label>
              <SelectInput
                name="fontSize"
                id="fontSize"
                className="m-form-input"
                register={register({ required: requiredErrorMessage })}
                options={fontSizeOptions}
              />
            </div>
            <div className="m-form-field">
              <label htmlFor="lineSpacing" className="m-form-label">
                {t("line-spacing")}
              </label>
              <SelectInput
                name="lineSpacing"
                id="lineSpacing"
                className="m-form-input"
                register={register({ required: requiredErrorMessage })}
                options={spacingOptions}
              />
            </div>
            <div className="m-form-field">
              <label htmlFor="wordSpacing" className="m-form-label">
                {t("word-spacing")}
              </label>
              <SelectInput
                name="wordSpacing"
                id="wordSpacing"
                className="m-form-input"
                register={register({ required: requiredErrorMessage })}
                options={spacingOptions}
              />
            </div>
            <RadioGroupField
              options={paletteOptions}
              name="palette"
              register={register}
              className="personal-informations-form-field-palette"
              renderOption={(option: ThemePropertyPaletteOption) => (
                <div className="personal-informations-form-field-palette-colors">
                  <div className="personal-informations-form-field-palette-color">
                    <div className="personal-informations-form-field-palette-color-label fg-color-primary">
                      Couleur de texte
                    </div>
                    <div
                      className={`personal-informations-form-field-palette-color-value bg-color-${option.foregroundColorPrimary}`}
                    />
                  </div>
                  <div className="personal-informations-form-field-palette-color">
                    <div className="personal-informations-form-field-palette-color-label fg-color-primary">
                      Couleur de fond
                    </div>
                    <div
                      className={`personal-informations-form-field-palette-color-value bg-color-${option.backgroundColorPrimaryMain}`}
                    />
                  </div>
                </div>
              )}
            />
          </div>
        </Paper>
        <Paper className="m-form-fields" fullWidth>
          <h2 className="m-form-fields-title fg-color-primary">
            {t("identity")}
          </h2>
          <div className="m-form-fields-content m-form-fields-content-col-2">
            <div className="m-form-field">
              <label htmlFor="lastName" className="m-form-label">
                {t("lastName")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.lastName),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.lastName)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.lastName?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="firstName" className="m-form-label">
                {t("firstName")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.firstName),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.firstName)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.firstName?.message}
              </legend>
            </div>
            <div className="m-form-field m-form-field-full-width">
              <label htmlFor="address" className="m-form-label">
                {t("address")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.address),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.address)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.address?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="zipcode" className="m-form-label">
                {t("zipcode")} ({requiredHelperText})
              </label>
              <input
                type="text"
                placeholder={t("zipcode-placeholder")}
                name="zipcode"
                id="zipcode"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.zipcode),
                })}
                ref={register({
                  required: requiredErrorMessage,
                  pattern: {
                    value: /[0-9]{5}/,
                    message: t("invalid_format_zipcode"),
                  },
                })}
                aria-invalid={Boolean(errors.zipcode)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.zipcode?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="city" className="m-form-label">
                {t("city")} ({requiredHelperText})
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.city),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.city)}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.city?.message}
              </legend>
            </div>
            <div className="m-form-field">
              <label htmlFor="city" className="m-form-label">
                {t("birthDate")} ({requiredHelperText})
              </label>
              <Controller
                control={control}
                name="birthDate"
                rules={{ required: requiredErrorMessage }}
                render={({ ref, value, onChange, name }) => (
                  <input
                    ref={ref}
                    value={value}
                    name={name}
                    onChange={(event) => {
                      onChange(formatDate(event.target.value));
                    }}
                    type="date"
                    id="birthDate"
                    className={classNames("m-form-input", {
                      "m-form-input-error": Boolean(errors.birthDate),
                    })}
                    aria-invalid={Boolean(errors.birthDate)}
                  />
                )}
              />
              <legend className="m-form-helper-text m-form-error">
                {errors.birthDate?.message}
              </legend>
            </div>
          </div>
        </Paper>
        <Paper className="m-form-actions" fullWidth>
          <Button type="submit" className="m-form-action-main">
            {t("save")}
          </Button>
          <Button color="dangerous" variant="outlined" onClick={handleReset}>
            {t("cancel")}
          </Button>
        </Paper>
      </form>
      <div className="personal-informations-left-sidebar">
        <VideoPlayer src="" size="medium" />
      </div>
    </section>
  );
};
export default PersonalInformations;
