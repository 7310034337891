/**
 * Inspired by https://dev.to/kphr99/sync-react-state-with-url-search-parameters-using-usequeryparamsstate-hook-1pgi
 */

import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useHistory, useLocation } from "react-router-dom";

type UseQueryParamsStateReturnType<T> = [T, Dispatch<SetStateAction<T>>];

const useQueryParamsState = <T>(
  param: string,
  initialState: T,
): UseQueryParamsStateReturnType<T> => {
  const location = useLocation();
  const history = useHistory();

  const [value, setValue] = useState<T>(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get(param);
    if (paramValue === null) {
      return initialState;
    }
    return JSON.parse(paramValue);
  });

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paramValue = JSON.stringify(value);
    if (paramValue.length > 0) {
      currentSearchParams.set(param, paramValue);
    } else {
      currentSearchParams.delete(param);
    }
    const newUrl = [location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    history.replace(newUrl, "");
  }, [param, value, location.pathname, location.search, history]);

  return [value, setValue];
};

export default useQueryParamsState;
