import {
  GET_CURRENT_USER,
  GET_PERSONAL_INFORMATIONS,
  UPDATE_INFOS,
  CurrentUserActionTypes,
} from "../../domain/currentUser/currentUser.types";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import CurrentUserApi from "./currentUser.api";

import {
  getCurrentUserSuccess,
  getCurrentUserFailure,
  getPersonalInformationsSuccess,
  getPersonalInformationsFailure,
  updateInfosSuccess,
  updateInfosFailure,
} from "../../domain/currentUser/currentUser.actions";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";

export default function CurrentUserSaga(apiExecutor: ApiExecutorType): {
  currentUserSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const currentUserApi = CurrentUserApi(apiExecutor);

  function* getCurrentUser(action: CurrentUserActionTypes) {
    try {
      const { body: currentUser } = yield call(
        currentUserApi.getCurrentUser,
        action?.kcId as string,
      );
      yield put(getCurrentUserSuccess(currentUser));
    } catch (e) {
      yield put(getCurrentUserFailure("Une erreur est survenue"));
    }
  }

  function* getPersonalInformations(action: CurrentUserActionTypes) {
    try {
      const { body: personalInfos } = yield call(
        currentUserApi.getPersonalInformations,
        action?.kcId as string,
      );
      yield put(getPersonalInformationsSuccess(personalInfos));
    } catch (e) {
      yield put(getPersonalInformationsFailure("Une erreur est survenue"));
    }
  }

  function* updateInfos(action: CurrentUserActionTypes) {
    try {
      const { body: infos } = yield call(
        currentUserApi.updateInfos,
        action.data,
      );
      const snackbar = {
        status: true,
        type: "success",
        message: "successfull_infos_updated",
      };
      yield put(showSnackBar(snackbar));
      yield put(updateInfosSuccess(infos));
    } catch (e) {
      yield put(updateInfosFailure("Une erreur est survenue"));
    }
  }

  function* currentUserSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(GET_CURRENT_USER, getCurrentUser);
    yield takeLatest(GET_PERSONAL_INFORMATIONS, getPersonalInformations);
    yield takeLatest(UPDATE_INFOS, updateInfos);
  }

  return { currentUserSaga };
}
