import {
  Children,
  FC,
  ReactElement,
  SyntheticEvent,
  cloneElement,
} from "react";

import { TabProps } from "./Tab";
import "./Tabs.scss";

type TabsProps = {
  value: number;
  onChange: (event: SyntheticEvent, value: number) => void;
  children: ReactElement<TabProps>[];
};

const Tabs: FC<TabsProps> = ({ children, value, onChange }: TabsProps) => {
  return (
    <nav>
      <ul className="tabs">
        {Children.map(children, (child) =>
          cloneElement(child, {
            active: child.props.value === value,
            onChange,
          }),
        )}
      </ul>
    </nav>
  );
};

export default Tabs;
