import {
  ADD_PERSONAL_INFORMATION,
  GET_KC_USER,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  AuthState,
  AuthActionTypes,
  IBeneficiary,
  CREATE_BENEFICIARY,
  CREATE_BENEFICIARY_SUCCESS,
  CREATE_BENEFICIARY_FAILURE,
  IPersonalInformations,
} from "./auth.types";
import formatDate from "../../utils/formatDate";

const INITIAL_STATE: AuthState = {
  beneficiary: {
    email: "",
    kcId: "",
    personalInfos: {
      firstName: "",
      lastName: "",
      address: "",
      zipcode: "",
      city: "",
      gender: "",
      birthDate: formatDate(new Date()),
      fontFamily: "Quicksand",
      fontSize: "sm",
      lineSpacing: "sm",
      wordSpacing: "sm",
      palette: "light1",
    },
    status: "infos",
  },
  loading: false,
  errors: undefined,
};

const authReducer = (
  state = INITIAL_STATE,
  action: AuthActionTypes,
): {
  beneficiary: IBeneficiary | undefined;
  loading: boolean;
  errors?: string | undefined;
} => {
  switch (action.type) {
    case ADD_PERSONAL_INFORMATION:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          personalInfos: action.personalInfos,
          status: "infos",
        },
      };
    case GET_KC_USER:
      return {
        ...state,
        beneficiary: {
          ...state.beneficiary,
          kcId: action.kcId,
          email: action.email,
        },
      };
    case GET_USER:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiary: action.beneficiary,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.message,
      };

    case CREATE_BENEFICIARY:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BENEFICIARY_SUCCESS:
      return {
        ...state,
        loading: false,
        beneficiary: {
          ...action.beneficiary,
          personalInfos: {
            ...(action.beneficiary?.personalInfos as IPersonalInformations),
            birthDate:
              action.beneficiary?.personalInfos?.birthDate ||
              formatDate(new Date()),
          },
        },
      };
    case CREATE_BENEFICIARY_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.message,
      };
    default:
      return state;
  }
};

export default authReducer;
