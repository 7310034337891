import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modalComponent/modal.component";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/modal/modal";
import { useTranslation } from "react-i18next";

import "./delete-theme-modal.scss";

interface IDeleteThemeModal {
  isOpen: boolean;
  openModale: () => void;
  deleteTheme: () => void;
}

function DeleteThemeModal({
  isOpen,
  openModale,
  deleteTheme,
}: IDeleteThemeModal): JSX.Element {
  const { t } = useTranslation();
  return isOpen ? (
    <div className="transparent">
      <Modal onModalClose={openModale} size="small" onConfirm={deleteTheme}>
        <ModalHeader>
          <h3 className="modal-title">{t("delete_theme")}</h3>
        </ModalHeader>
        <ModalBody>
          <span className="modal-description">
            {t("delete_explication_theme")}
          </span>
          <span className="modal-confirmation">
            {t("delete_confirmation_theme")}
          </span>
        </ModalBody>
        <ModalFooter>
          <Button onClick={openModale} color="dangerous">
            {t("cancel")}
          </Button>

          <Button onClick={deleteTheme}>{t("delete")}</Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <div className="transparent" />
  );
}

export default DeleteThemeModal;
