import DialogContext from "./DialogContext";
import { ReactNode, FC } from "react";
import useDialog from "./useDialog";

interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

interface DialogProps extends DialogOptions {
  children: ReactNode;
}

const Dialog: FC<DialogProps> = ({ children, ...options }) => {
  const dialog = useDialog(options);
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
};

export default Dialog;
