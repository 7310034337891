export default function requestUrlConstructor(
  url: string,
  objectParams: { [x: string]: string | number | boolean },
): string {
  const esc = encodeURIComponent;
  const query = Object.keys(objectParams)
    .map((k) => `${esc(k)}=${esc(objectParams[k])}`)
    .join("&");
  return `${url}/?${query}`;
}
