import {
  CreateCourseActionPayload,
  UpdateCourseActionPayload,
} from "../../domain/e-learning/e-learning.types";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { selectDictionnary } from "../../domain/e-learning/e-learning.selectors";
import { DefaultValues, useForm, Controller } from "react-hook-form";
import AutoComplete from "../AutoComplete/AutoComplete";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";
import { AppState } from "../../redux/types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../Button/Button";
import classNames from "classnames";
import { FC, useMemo } from "react";
import Paper from "../Paper/Paper";
import Title from "../Page/Title";
import "./CourseForm.scss";

export type CourseFormValues =
  | CreateCourseActionPayload
  | UpdateCourseActionPayload;

interface CourseFormProps {
  title: string;
  themeId?: string;
  onSubmit: (data: CourseFormValues) => void;
  defaultValues?: DefaultValues<CourseFormValues>;
}

const CourseForm: FC<CourseFormProps> = ({
  title,
  onSubmit,
  defaultValues = {},
}) => {
  const { t } = useTranslation();
  const params = useParams<{ themeId: string; courseId: string }>();
  const { handleSubmit, register, errors, control } = useForm<CourseFormValues>(
    {
      criteriaMode: "all",
      shouldFocusError: false,
      defaultValues,
      mode: "all",
      shouldUnregister: true,
    },
  );

  const selectDefaultValues = useMemo(() => {
    if (defaultValues && defaultValues.dictionnaryEntries) {
      return defaultValues?.dictionnaryEntries.map((item) => {
        return { value: item.id || "", label: item.word || "" };
      });
    } else return [];
  }, [defaultValues]);

  const selectDefaultChoices = useMemo(() => {
    if (defaultValues && defaultValues.dictionnaryEntries) {
      return defaultValues?.dictionnaryEntries.map((item) => {
        return item.id;
      });
    } else return [];
  }, [defaultValues]);

  const requiredErrorMessage = t("field_required");
  const dictionnary = useSelector<AppState, DictionnaryEntry[] | undefined>(
    selectDictionnary,
  );

  const dictionnaryOptions = dictionnary
    ? dictionnary.map((entry) => ({
        label: entry.word,
        value: entry.id,
      }))
    : [];

  const onSubmitWithId = (data: CreateCourseActionPayload) => {
    onSubmit({ ...data, themeId: params.themeId, order: 2 });
  };

  return (
    <form className="m-form" onSubmit={handleSubmit(onSubmitWithId)}>
      <Title className="">{t(title)}</Title>
      <Paper fullWidth className="m-form-fields" />
      <Paper fullWidth className="m-form-fields">
        <span className="m-typography-h2">{t("e_learning_add_courses")}</span>
        <div className="m-form-fields-content">
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="title" className="m-form-label">
              {t("course_title")}
            </label>
            <input
              id="title"
              name="title"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.title),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.title)}
            />
          </div>
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="description" className="m-form-label">
              {t("course_description")}
            </label>
            <input
              id="description"
              name="description"
              className={classNames("create-course-description-input", {
                "m-form-input-error": Boolean(errors.description),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.description)}
            />
          </div>
          <div className="m-form-fields-content m-form-fields-content-col-2">
            <div className="m-form-field">
              <label htmlFor="video" className="m-form-label">
                {t("add_video")}
              </label>
              <input
                name="video"
                id="video"
                type="file"
                accept="video/*"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.video),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.video)}
              />
            </div>
            <div className="m-form-field">
              <label htmlFor="picto" className="m-form-label">
                {t("add_mental_map")}
              </label>
              <input
                name="picto"
                id="picto"
                type="file"
                accept="image/png, image/jpeg"
                className={classNames("m-form-input", {
                  "m-form-input-error": Boolean(errors.picto),
                })}
                ref={register({ required: requiredErrorMessage })}
                aria-invalid={Boolean(errors.picto)}
              />
            </div>
          </div>
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="dictionnary" className="m-form-label">
              {t("add_dictionnary_entry")}
            </label>
            <Controller
              defaultValue={selectDefaultChoices}
              rules={{
                required: true,
                validate: {
                  checkValue: (items) =>
                    items.length > 0 || requiredErrorMessage,
                },
              }}
              name="dictionnary"
              control={control}
              render={(item) => {
                const { onChange } = item;
                const handleChange = (id: string, values: string[]) => {
                  onChange(values);
                };

                return (
                  <AutoComplete
                    defaultValue={selectDefaultValues}
                    handleChange={handleChange}
                    options={dictionnaryOptions}
                    name="dictionnary"
                    id="dictionnary"
                    className={classNames("m-form-input", {
                      "m-form-input-error": Boolean(errors.dictionnary),
                    })}
                    aria-invalid={Boolean(errors.dictionnary)}
                  />
                );
              }}
            />
          </div>
        </div>
      </Paper>
      <div className="add-course-btn">
        <Button size="large" variant="transparent" startIcon={<IoAddCircle />}>
          <p className="m-typography-body1">{t("e_learning_add_course")}</p>
        </Button>
      </div>
      <Paper fullWidth className="m-form-actions">
        <Button type="submit">{t("save")}</Button>
        <Button
          to={`/e-learning/${params.themeId}/exercises/add}`}
          className="m-form-action-main"
          disabled
        >
          {t("e_learning_add_exercises")}
        </Button>
        <Button
          to={`/e-learning?theme="${params.themeId}"`}
          color="dangerous"
          variant="outlined"
        >
          {t("cancel")}
        </Button>
      </Paper>
    </form>
  );
};

export default CourseForm;
