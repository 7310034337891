import {
  CREATE_THEME,
  CREATE_THEME_SUCCESS,
  CREATE_THEME_FAILURE,
  CreateThemeActionType,
  CreateThemeSuccessActionType,
  CreateThemeFailureActionType,
  GET_ADMIN_THEMES,
  GET_ADMIN_THEMES_SUCCESS,
  GET_ADMIN_THEMES_FAILURE,
  GetThemesActionType,
  GetThemesSuccessActionType,
  GetThemesFailureActionType,
  GET_DICTIONNARY_ENTRIES_SUCCESS,
  GET_DICTIONNARY_ENTRIES_FAILURE,
  GetDictionnaryEntriesSuccessActionType,
  GetDictionnaryEntriesFailureActionType,
  GET_THEME_AND_COURSES_SUCCESS,
  GET_THEME_AND_COURSES_FAILURE,
  GetThemeAndElementsSuccessActionType,
  GetThemeAndElementsFailureActionType,
  GET_THEME_AND_EXERCISES_SUCCESS,
  GET_THEME_AND_EXERCISES_FAILURE,
  RESET_THEME,
  ResetThemeActionType,
  DELETE_THEME,
  DELETE_THEME_SUCCESS,
  DELETE_THEME_FAILURE,
  DeleteThemeActionType,
  DeleteThemeSuccessActionType,
  DeleteThemeFailureActionType,
  UPDATE_THEME,
  UPDATE_THEME_SUCCESS,
  UPDATE_THEME_FAILURE,
  UpdateThemeActionType,
  UpdateThemeSuccessActionType,
  UpdateThemeFailureActionType,
  GET_BENEFICIARY_THEMES,
  GET_BENEFICIARY_THEMES_SUCCESS,
  GET_BENEFICIARY_THEMES_FAILURE,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
  CreateCourseSuccessActionType,
  CreateCourseFailureActionType,
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
  DeleteCourseActionType,
  DeleteCourseSuccessActionType,
  DeleteCourseFailureActionType,
  DELETE_DICTIONNARY_ENTRY,
  DELETE_DICTIONNARY_ENTRY_SUCCESS,
  DELETE_DICTIONNARY_ENTRY_FAILURE,
  DeleteDictionnaryEntryActionType,
  DeleteDictionnaryEntrySuccessActionType,
  DeleteDictionnaryEntryFailureActionType,
  ADD_DICTIONNARY_ENTRY,
  ADD_DICTIONNARY_ENTRY_SUCCESS,
  ADD_DICTIONNARY_ENTRY_FAILURE,
  UPDATE_DICTIONNARY_ENTRY,
  UPDATE_DICTIONNARY_ENTRY_SUCCESS,
  UPDATE_DICTIONNARY_ENTRY_FAILURE,
  AddDictionnaryEntryActionType,
  AddDictionnaryEntrySuccessActionType,
  AddDictionnaryEntryFailureActionType,
  UpdateDictionnaryEntryFailureActionType,
  UpdateDictionnaryEntrySuccessActionType,
  UpdateDictionnaryEntryActionType,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILURE,
  UpdateCourseActionType,
  UpdateCourseSuccessActionType,
  UpdateCourseFailureActionType,
  SET_ADD_MODE,
  SetAddModeActionType,
  PUBLISH_ALL_COURSES,
  PUBLISH_ALL_COURSES_SUCCESS,
  PUBLISH_ALL_COURSES_FAILURE,
  PublishAllCourseActionType,
  PublishAllCourseSuccessActionType,
  PublishAllCourseFailureActionType,
  PUBLISH_ALL_EXERCISES,
  PUBLISH_ALL_EXERCISES_SUCCESS,
  PUBLISH_ALL_EXERCISES_FAILURE,
  PublishAllExercisesActionType,
  PublishAllExercisesSuccessActionType,
  PublishAllExercisesFailureActionType,
  GET_COURSES,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAILURE,
  GetCoursesActionType,
  GetCoursesSuccessActionType,
  GetCoursesFailureActionType,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_FAILURE,
  UpdateExerciseSuccessActionType,
  UpdateExerciseActionType,
  DELETE_EXERCISE,
  DELETE_EXERCISE_SUCCESS,
  DELETE_EXERCISE_FAILURE,
  DeleteExerciseActionType,
  DeleteExerciseSuccessActionType,
  DeleteExerciseFailureActionType,
  UPDATE_QUESTION,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_QUESTION_FAILURE,
  UpdateQuestionActionType,
  UpdateQuestionSuccessActionType,
  UPDATE_ANSWER,
  UPDATE_ANSWER_SUCCESS,
  UPDATE_ANSWER_FAILURE,
  UpdateAnswerActionType,
  UpdateAnswerSuccessActionType,
  ITheme,
  ICourse,
  IAnswer,
  IExercise,
  IQuestion,
  ISession,
  ISessionScore,
  IExerciseBeneficiary,
  GET_BENEFICIARY_EXERCISES,
  GET_BENEFICIARY_EXERCISES_SUCCESS,
  GET_BENEFICIARY_EXERCISES_FAILURE,
  GetBeneficiaryExercisesActionType,
  GetBeneficiaryExercisesSuccessActionType,
  GetBeneficiaryExercisesFailureActionType,
  GET_BENEFICIARY_PRACTICE_SESSION,
  GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS,
  GET_BENEFICIARY_PRACTICE_SESSION_FAILURE,
  GetBeneficiaryPracticeSessionActionType,
  GetBeneficiaryPracticeSessionSuccessActionType,
  GetBeneficiaryPracticeSessionFailureActionType,
  GET_BENEFICIARY_EXAM_SESSION,
  GET_BENEFICIARY_EXAM_SESSION_SUCCESS,
  GET_BENEFICIARY_EXAM_SESSION_FAILURE,
  GetBeneficiaryExamSessionActionType,
  GetBeneficiaryExamSessionSuccessActionType,
  GetBeneficiaryExamSessionFailureActionType,
  UPDATE_EXERCISE_BENEFICIARY,
  UPDATE_EXERCISE_BENEFICIARY_SUCCESS,
  UPDATE_EXERCISE_BENEFICIARY_FAILURE,
  UpdateExerciseBeneficiaryActionType,
  UpdateExerciseBeneficiarySuccessActionType,
  UpdateExerciseBeneficiaryFailureActionType,
  RESET_SESSION,
  ResetSessionActionType,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS,
  GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE,
  GetBeneficiaryPracticeSessionScore,
  GetBeneficiaryPracticeSessionScoreSuccess,
  GetBeneficiaryPracticeSessionScoreFailure,
  GET_BENEFICIARY_EXAM_SESSION_SCORE,
  GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS,
  GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE,
  GetBeneficiaryExamSessionScore,
  GetBeneficiaryExamSessionScoreSuccess,
  GetBeneficiaryExamSessionScoreFailure,
  UpdateThemeActionPayload,
  CreateThemeActionPayload,
  CreateCourseActionPayload,
  CreateCourseActionType,
  CREATE_COURSE,
  UpdateCourseActionPayload,
  PUBLISH_COURSE,
  PUBLISH_COURSE_FAILURE,
  PUBLISH_COURSE_SUCCESS,
  PublishCourseActionType,
  PublishCourseFailureActionType,
  PublishCourseSuccessActionType,
  CreateExerciseActionPayload,
  CreateExerciseActionType,
  CREATE_EXERCISE,
  CreateExerciseSuccessActionType,
  CREATE_EXERCISE_SUCCESS,
  CreateExerciseFailureActionType,
  CREATE_EXERCISE_FAILURE,
  UpdateExerciseActionPayload,
  UPDATE_EXERCISE,
} from "./e-learning.types";
import { DictionnaryEntry } from "../../presentation/dictionnary/dictionnaryEntry";
import { FailureMessageType } from "../utils";

export const createTheme = (
  theme: CreateThemeActionPayload,
): CreateThemeActionType => ({
  type: CREATE_THEME,
  theme,
});

export const createThemeSuccess = (
  theme: ITheme,
): CreateThemeSuccessActionType => ({
  type: CREATE_THEME_SUCCESS,
  theme,
});

export const createThemeFailure = (
  message: string,
): CreateThemeFailureActionType => ({
  type: CREATE_THEME_FAILURE,
  message,
});

export const createExercise = (
  exercise: CreateExerciseActionPayload,
): CreateExerciseActionType => ({
  type: CREATE_EXERCISE,
  exercise,
});

export const createExerciseSuccess = (
  exercise: IExercise,
): CreateExerciseSuccessActionType => ({
  type: CREATE_EXERCISE_SUCCESS,
  exercise,
});

export const createExerciseFailure = (
  message: string,
): CreateExerciseFailureActionType => ({
  type: CREATE_EXERCISE_FAILURE,
  message,
});

export const deleteCourse = (id: string): DeleteCourseActionType => ({
  type: DELETE_COURSE,
  id,
});

export const deleteCourseSuccess = (
  course: ICourse,
): DeleteCourseSuccessActionType => ({
  type: DELETE_COURSE_SUCCESS,
  course,
});

export const deleteCourseFailure = (
  message: string,
): DeleteCourseFailureActionType => ({
  type: DELETE_COURSE_FAILURE,
  message,
});

export const publishCourse = (id: string): PublishCourseActionType => ({
  type: PUBLISH_COURSE,
  id,
});

export const publishCourseSuccess = (
  course: ICourse,
): PublishCourseSuccessActionType => ({
  type: PUBLISH_COURSE_SUCCESS,
  course,
});

export const publishCourseFailure = (
  message: string,
): PublishCourseFailureActionType => ({
  type: PUBLISH_COURSE_FAILURE,
  message,
});

export const deleteTheme = (id: string): DeleteThemeActionType => ({
  type: DELETE_THEME,
  id,
});

export const deleteThemeSuccess = (
  theme: ITheme,
): DeleteThemeSuccessActionType => ({
  type: DELETE_THEME_SUCCESS,
  theme,
});

export const deleteThemeFailure = (
  message: string,
): DeleteThemeFailureActionType => ({
  type: DELETE_THEME_FAILURE,
  message,
});

export const updateTheme = (
  id: string,
  theme: UpdateThemeActionPayload,
): UpdateThemeActionType => ({
  type: UPDATE_THEME,
  id,
  theme,
});

export const updateThemeSuccess = (
  theme: ITheme,
): UpdateThemeSuccessActionType => ({
  type: UPDATE_THEME_SUCCESS,
  theme,
});

export const updateThemeFailure = (
  message: string,
): UpdateThemeFailureActionType => ({
  type: UPDATE_THEME_FAILURE,
  message,
});

export const createCourse = (
  course: CreateCourseActionPayload,
): CreateCourseActionType => ({
  type: CREATE_COURSE,
  course,
});

export const createCourseSuccess = (
  course: ICourse,
): CreateCourseSuccessActionType => ({
  type: CREATE_COURSE_SUCCESS,
  course,
});

export const createCourseFailure = (
  message: string,
): CreateCourseFailureActionType => ({
  type: CREATE_COURSE_FAILURE,
  message,
});

export const updateCourse = (
  id: string,
  course: UpdateCourseActionPayload,
): UpdateCourseActionType => ({
  type: UPDATE_COURSE,
  id,
  course,
});

export const updateCourseSuccess = (
  course: ICourse,
): UpdateCourseSuccessActionType => ({
  type: UPDATE_COURSE_SUCCESS,
  course,
});

export const updateCourseFailure = (
  message: string,
): UpdateCourseFailureActionType => ({
  type: UPDATE_COURSE_FAILURE,
  message,
});

export const getAdminThemes = (): GetThemesActionType => ({
  type: GET_ADMIN_THEMES,
});

export const getAdminThemesSuccess = (
  themes: ITheme[],
): GetThemesSuccessActionType => ({
  type: GET_ADMIN_THEMES_SUCCESS,
  themes,
});

export const getAdminThemesFailure = (
  message: string,
): GetThemesFailureActionType => ({
  type: GET_ADMIN_THEMES_FAILURE,
  message,
});

export const getDictionnaryEntriesSuccess = (
  dictionnaryEntries: DictionnaryEntry[],
): GetDictionnaryEntriesSuccessActionType => ({
  type: GET_DICTIONNARY_ENTRIES_SUCCESS,
  dictionnaryEntries,
});

export const getDictionnaryEntriesFailure = (
  message: string,
): GetDictionnaryEntriesFailureActionType => ({
  type: GET_DICTIONNARY_ENTRIES_FAILURE,
  message,
});

export const getThemeAndCoursesSuccess = (
  theme: ITheme,
): GetThemeAndElementsSuccessActionType => ({
  type: GET_THEME_AND_COURSES_SUCCESS,
  theme,
});

export const getThemeAndCoursesFailure = (
  message: string,
): GetThemeAndElementsFailureActionType => ({
  type: GET_THEME_AND_COURSES_FAILURE,
  message,
});

export const getThemeAndExercisesSuccess = (
  theme: ITheme,
): GetThemeAndElementsSuccessActionType => ({
  type: GET_THEME_AND_EXERCISES_SUCCESS,
  theme,
});

export const getThemeAndExercisesFailure = (
  message: string,
): GetThemeAndElementsFailureActionType => ({
  type: GET_THEME_AND_EXERCISES_FAILURE,
  message,
});

export const resetTheme = (): ResetThemeActionType => ({
  type: RESET_THEME,
});

export const getBeneficiaryThemes = (): GetThemesActionType => ({
  type: GET_BENEFICIARY_THEMES,
});

export const getBeneficiaryThemesSuccess = (
  themes: ITheme[],
): GetThemesSuccessActionType => ({
  type: GET_BENEFICIARY_THEMES_SUCCESS,
  themes,
});

export const getBeneficiaryThemesFailure = (
  message: string,
): GetThemesFailureActionType => ({
  type: GET_BENEFICIARY_THEMES_FAILURE,
  message,
});

export const deleteDictionnaryEntry = (
  dictionnaryEntryId: string,
): DeleteDictionnaryEntryActionType => ({
  type: DELETE_DICTIONNARY_ENTRY,
  dictionnaryEntryId,
});

export const deleteDictionnaryEntrySuccess = (
  dictionnaryEntryId: string,
): DeleteDictionnaryEntrySuccessActionType => ({
  type: DELETE_DICTIONNARY_ENTRY_SUCCESS,
  dictionnaryEntryId,
});

export const deleteDictionnaryEntryFailure = (
  message: string,
): DeleteDictionnaryEntryFailureActionType => ({
  type: DELETE_DICTIONNARY_ENTRY_FAILURE,
  message,
});

export const addDictionnaryEntry = (
  dictionnaryEntry: DictionnaryEntry,
): AddDictionnaryEntryActionType => ({
  type: ADD_DICTIONNARY_ENTRY,
  dictionnaryEntry,
});

export const addDictionnaryEntrySuccess = (
  dictionnaryEntry: DictionnaryEntry,
): AddDictionnaryEntrySuccessActionType => ({
  type: ADD_DICTIONNARY_ENTRY_SUCCESS,
  dictionnaryEntry,
});

export const addDictionnaryEntryFailure = (
  message: string,
): AddDictionnaryEntryFailureActionType => ({
  type: ADD_DICTIONNARY_ENTRY_FAILURE,
  message,
});

export const updateDictionnaryEntry = (
  dictionnaryEntry: DictionnaryEntry,
): UpdateDictionnaryEntryActionType => ({
  type: UPDATE_DICTIONNARY_ENTRY,
  dictionnaryEntry,
});

export const updateDictionnaryEntrySuccess = (
  dictionnaryEntry: DictionnaryEntry,
): UpdateDictionnaryEntrySuccessActionType => ({
  type: UPDATE_DICTIONNARY_ENTRY_SUCCESS,
  dictionnaryEntry,
});

export const updateDictionnaryEntryFailure = (
  message: string,
): UpdateDictionnaryEntryFailureActionType => ({
  type: UPDATE_DICTIONNARY_ENTRY_FAILURE,
  message,
});

export const setAddMode = (value: boolean): SetAddModeActionType => ({
  type: SET_ADD_MODE,
  value,
});

export const publishAllExercises = (
  themeId: string,
  isPublished: boolean,
): PublishAllExercisesActionType => ({
  type: PUBLISH_ALL_EXERCISES,
  themeId,
  isPublished,
});

export const publishAllExercisesSuccess = (
  isPublished: boolean,
): PublishAllExercisesSuccessActionType => ({
  type: PUBLISH_ALL_EXERCISES_SUCCESS,
  isPublished,
});

export const publishAllExercisesFailure = (
  message: string,
): PublishAllExercisesFailureActionType => ({
  type: PUBLISH_ALL_EXERCISES_FAILURE,
  message,
});

export const publishAllCourse = (
  themeId: string,
  isPublished: boolean,
): PublishAllCourseActionType => ({
  type: PUBLISH_ALL_COURSES,
  themeId,
  isPublished,
});

export const publishAllCourseSuccess = (
  isPublished: boolean,
): PublishAllCourseSuccessActionType => ({
  type: PUBLISH_ALL_COURSES_SUCCESS,
  isPublished,
});

export const publishAllCourseFailure = (
  message: string,
): PublishAllCourseFailureActionType => ({
  type: PUBLISH_ALL_COURSES_FAILURE,
  message,
});

export const getCourses = (themeId: string): GetCoursesActionType => ({
  type: GET_COURSES,
  themeId,
});

export const getCoursesSuccess = (
  courses: ICourse[],
): GetCoursesSuccessActionType => ({
  type: GET_COURSES_SUCCESS,
  courses,
});

export const getCoursesFailure = (
  message: string,
): GetCoursesFailureActionType => ({
  type: GET_COURSES_FAILURE,
  message,
});

export const deleteExercise = (id: string): DeleteExerciseActionType => ({
  type: DELETE_EXERCISE,
  id,
});

export const deleteExerciseSuccess = (
  id: string,
): DeleteExerciseSuccessActionType => ({
  type: DELETE_EXERCISE_SUCCESS,
  id,
});

export const deleteExerciseFailure = (
  message: string,
): DeleteExerciseFailureActionType => ({
  type: DELETE_EXERCISE_FAILURE,
  message,
});

export const updateExercise = (
  id: string,
  exercise: UpdateExerciseActionPayload,
): UpdateExerciseActionType => ({
  type: UPDATE_EXERCISE,
  id,
  exercise,
});

export const updateExerciseSuccess = (
  exercise: Partial<IExercise>,
): UpdateExerciseSuccessActionType => ({
  type: UPDATE_EXERCISE_SUCCESS,
  exercise,
});

export const updateExerciseFailure = (message: string): FailureMessageType => ({
  type: UPDATE_EXERCISE_FAILURE,
  message,
});
export const updateQuestion = (
  question: Partial<IQuestion>,
): UpdateQuestionActionType => ({
  type: UPDATE_QUESTION,
  question,
});

export const updateQuestionSuccess = (
  question: Partial<IQuestion>,
): UpdateQuestionSuccessActionType => ({
  type: UPDATE_QUESTION_SUCCESS,
  question,
});

export const updateQuestionFailure = (message: string): FailureMessageType => ({
  type: UPDATE_QUESTION_FAILURE,
  message,
});
export const updateAnswer = (
  answer: Partial<IAnswer>,
): UpdateAnswerActionType => ({
  type: UPDATE_ANSWER,
  answer,
});

export const updateAnswerSuccess = (
  answer: Partial<IAnswer>,
): UpdateAnswerSuccessActionType => ({
  type: UPDATE_ANSWER_SUCCESS,
  answer,
});

export const updateAnswerFailure = (message: string): FailureMessageType => ({
  type: UPDATE_ANSWER_FAILURE,
  message,
});

export const getBeneficiaryExercises = (
  id: string,
): GetBeneficiaryExercisesActionType => ({
  type: GET_BENEFICIARY_EXERCISES,
  id,
});

export const getBeneficiaryExercisesSuccess = (
  exercises: IExercise[],
): GetBeneficiaryExercisesSuccessActionType => ({
  type: GET_BENEFICIARY_EXERCISES_SUCCESS,
  exercises,
});

export const getBeneficiaryExercisesFailure = (
  message: string,
): GetBeneficiaryExercisesFailureActionType => ({
  type: GET_BENEFICIARY_EXERCISES_FAILURE,
  message,
});

export const getBeneficiaryPracticeSession = (
  userId: string,
  themeId: string,
): GetBeneficiaryPracticeSessionActionType => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION,
  userId,
  themeId,
});

export const getBeneficiaryPracticeSessionSuccess = (
  session: ISession,
): GetBeneficiaryPracticeSessionSuccessActionType => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION_SUCCESS,
  session,
});

export const getBeneficiaryPracticeSessionFailure = (
  message: string,
): GetBeneficiaryPracticeSessionFailureActionType => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION_FAILURE,
  message,
});

export const getBeneficiaryExamSession = (
  userId: string,
): GetBeneficiaryExamSessionActionType => ({
  type: GET_BENEFICIARY_EXAM_SESSION,
  userId,
});

export const getBeneficiaryExamSessionSuccess = (
  session: ISession,
): GetBeneficiaryExamSessionSuccessActionType => ({
  type: GET_BENEFICIARY_EXAM_SESSION_SUCCESS,
  session,
});

export const getBeneficiaryExamSessionFailure = (
  message: string,
): GetBeneficiaryExamSessionFailureActionType => ({
  type: GET_BENEFICIARY_EXAM_SESSION_FAILURE,
  message,
});

export const resetSession = (): ResetSessionActionType => ({
  type: RESET_SESSION,
});

export const updateExerciseBeneficiary = (
  exerciseBeneficiary: Partial<IExerciseBeneficiary>,
): UpdateExerciseBeneficiaryActionType => ({
  type: UPDATE_EXERCISE_BENEFICIARY,
  exerciseBeneficiary,
});

export const updateExerciseBeneficiarySuccess = (
  exerciseBeneficiary: Partial<IExerciseBeneficiary>,
): UpdateExerciseBeneficiarySuccessActionType => ({
  type: UPDATE_EXERCISE_BENEFICIARY_SUCCESS,
  exerciseBeneficiary,
});

export const updateExerciseBeneficiaryFailure = (
  message: string,
): UpdateExerciseBeneficiaryFailureActionType => ({
  type: UPDATE_EXERCISE_BENEFICIARY_FAILURE,
  message,
});

export const getBeneficiaryPracticeSessionScore = (
  id: string,
): GetBeneficiaryPracticeSessionScore => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION_SCORE,
  id,
});

export const getBeneficiaryPracticeSessionScoreSuccess = (
  sessionScore: Partial<ISessionScore>,
): GetBeneficiaryPracticeSessionScoreSuccess => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION_SCORE_SUCCESS,
  sessionScore,
});

export const getBeneficiaryPracticeSessionScoreFailure = (
  message: string,
): GetBeneficiaryPracticeSessionScoreFailure => ({
  type: GET_BENEFICIARY_PRACTICE_SESSION_SCORE_FAILURE,
  message,
});

export const getBeneficiaryExamSessionScore = (
  id: string,
): GetBeneficiaryExamSessionScore => ({
  type: GET_BENEFICIARY_EXAM_SESSION_SCORE,
  id,
});
export const getBeneficiaryExamSessionScoreSuccess = (
  sessionScore: Partial<ISessionScore>,
): GetBeneficiaryExamSessionScoreSuccess => ({
  type: GET_BENEFICIARY_EXAM_SESSION_SCORE_SUCCESS,
  sessionScore,
});
export const getBeneficiaryExamSessionScoreFailure = (
  message: string,
): GetBeneficiaryExamSessionScoreFailure => ({
  type: GET_BENEFICIARY_EXAM_SESSION_SCORE_FAILURE,
  message,
});
