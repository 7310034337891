import { PaymentState } from "./payment.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const initPayment = (state: AppState) => state.payment;

export const selectInitPayment = createSelector(
  initPayment,
  (state: PaymentState) => state.paymentData,
);

const savePayment = (state: AppState) => state.payment;

export const selectSavePayment = createSelector(
  savePayment,
  (state: PaymentState) => state.paymentData,
);
