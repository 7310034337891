import {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../components/modal/modalComponent/modal.component";
import Button from "../../../../components/Button/Button";
import Modal from "../../../../components/modal/modal";
import { useTranslation } from "react-i18next";
import "./AdminCardDeleteModal.scss";

interface IAdminCardDeleteModal {
  isOpen: boolean;
  openModale: () => void;
  deleteUser: () => void;
  adminEmail: string;
}

function AdminCardDeleteModal({
  isOpen,
  openModale,
  deleteUser,
  adminEmail = "",
}: IAdminCardDeleteModal): JSX.Element {
  const { t } = useTranslation();
  return isOpen ? (
    <div className="transparent">
      <Modal onModalClose={openModale} size="default" onConfirm={deleteUser}>
        <ModalHeader>
          <h3 className="modal-title">{t("suppress admin")}</h3>
        </ModalHeader>
        <ModalBody>
          <span className="modal-description">
            {t("Do you really want to suppress the admin with email")}
          </span>
          <input
            className="modal-input"
            aria-disabled
            name={t("Email of admin to delete")}
            value={adminEmail}
            readOnly
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={deleteUser}>{t("delete")}</Button>
          <span className="modal-footer-btn-cancel">
            <Button onClick={openModale} color="dangerous">
              {t("cancel")}
            </Button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <div className="transparent" />
  );
}

export default AdminCardDeleteModal;
