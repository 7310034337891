import { intersection } from "lodash";

export const isMatching = (
  expected: string[] = [],
  asserted: string[] = [],
): boolean => {
  if (expected.length === 0 && asserted.length === 0) return true;
  return intersection(expected, asserted).length > 0;
};

export default isMatching;
