import { combineReducers, Reducer } from "redux";

import interactionsWithUsersReducer from "../domain/interactionsWithUsers/interactionsWithUsers.reducer";
import manageUsersReducer from "../domain/manageUsers/manageUsers.reducer";
import currentUserReducer from "../domain/currentUser/currentUser.reducer";
import eLearningReducer from "../domain/e-learning/e-learning.reducer";
import contactReducer from "../domain/contact/message.reducer";
import paymentReducer from "../domain/payment/payment.reducer";
import authReducer from "../domain/auth/auth.reducer";

const reducer = (): Reducer =>
  combineReducers({
    manageUser: manageUsersReducer,
    auth: authReducer,
    currentUser: currentUserReducer,
    successOrError: interactionsWithUsersReducer,
    eLearning: eLearningReducer,
    contact: contactReducer,
    payment: paymentReducer,
  });

export default reducer;
