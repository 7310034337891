import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import requestUrlConstructor from "../utils/requestUrlConstructor";
import { IBeneficiary } from "../../domain/auth/auth.types";

function UserApi(executor: ApiExecutorType): {
  createBeneficiary: (
    beneficiary: string | IBeneficiary | undefined,
    path?: string,
  ) => Promise<{
    body: IBeneficiary;
    status: number;
  }>;
  getUser: (kcId: string) => Promise<{
    status: number;
  }>;
} {
  function createBeneficiary(
    beneficiary: string | IBeneficiary | undefined,
  ): Promise<{
    body: IBeneficiary;
    status: number;
  }> {
    return executor.post("/user/beneficiary", beneficiary);
  }

  function getUser(kcId: string) {
    const param = {
      kcId,
    };
    const url = "/user/me";
    const urlCreated = requestUrlConstructor(url, param);
    return executor.get(urlCreated);
  }

  return { createBeneficiary, getUser };
}

export default UserApi;
