import mediumPoster from "../../assets/img/video-placeholder-medium.png";
import smallPoster from "../../assets/img/video-placeholder-small.png";
import largePoster from "../../assets/img/video-placeholder-large.png";
import { IoPlayCircle, IoPauseCircle } from "react-icons/io5";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./VideoPlayer.scss";

type VideoSize = "small" | "medium" | "large";

const posters: Record<VideoSize, string> = {
  small: smallPoster,
  medium: mediumPoster,
  large: largePoster,
};
interface VideoPlayerProps {
  src?: string;
  captions?: string;
  size?: VideoSize;
  poster?: string;
}

export default function VideoPlayer({
  src,
  captions,
  size = "small",
  poster,
}: VideoPlayerProps): JSX.Element {
  const ref = useRef<HTMLVideoElement>(null);
  const [paused, setPaused] = useState(true);
  const { t } = useTranslation();
  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.load();
    }
  }, [src]);
  const toggle = () => {
    if (paused) {
      setPaused(false);
      ref.current?.play();
    } else {
      setPaused(true);
      ref.current?.pause();
    }
  };
  const updatePoster = async () => {
    if (ref.current && ref.current.readyState !== 4 && size) {
      ref.current.setAttribute("poster", poster || posters[size]);
    }
  };
  return (
    <div className={`video-player video-player-${size}`}>
      <video
        className="video-player-content"
        ref={ref}
        muted
        onError={updatePoster}
        onLoadedData={updatePoster}
      >
        <source src={src} type="video/mp4" />
        {captions && <track default lang="fr" kind="captions" src={captions} />}
      </video>
      <button
        type="button"
        className="video-player-control-button"
        onClick={toggle}
        aria-label={t(
          paused ? "aria_label_play_video" : "aria_label_pause_video",
        )}
      >
        {paused ? (
          <IoPlayCircle className="video-player-control-button-icon" />
        ) : (
          <IoPauseCircle className="video-player-control-button-icon" />
        )}
      </button>
    </div>
  );
}
