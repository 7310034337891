import {
  ComponentPropsWithRef,
  forwardRef,
  ForwardRefRenderFunction,
  isValidElement,
  ReactNode,
} from "react";
import useNavigate from "../../utils/useNavigate";
import { LocationDescriptor } from "history";
import classNames from "classnames";
import "./Button.scss";

type Size = "small" | "medium" | "large";
const sizes: Size[] = ["small", "medium", "large"];

type Color = "primary" | "secondary" | "dangerous";
const colors: Color[] = ["primary", "secondary", "dangerous"];

type Variant = "contained" | "outlined" | "transparent";
const variants: Variant[] = ["contained", "outlined", "transparent"];

export interface ButtonProps extends ComponentPropsWithRef<"button"> {
  size?: Size;
  color?: Color;
  variant?: Variant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  to?: LocationDescriptor;
}

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    size = "medium",
    color = "primary",
    variant = "contained",
    disabled = false,
    children,
    startIcon,
    endIcon,
    className,
    to,
    ...props
  },
  ref,
) => {
  const navigate = useNavigate(to);

  return (
    <button
      onClick={navigate}
      type="button"
      role={to ? "link" : "button"}
      {...props}
      ref={ref}
      className={classNames(
        "m-button",
        colors.includes(color) ? `m-button-${color}` : "m-button-primary",
        variants.includes(variant)
          ? `m-button-${variant}`
          : "m-button-contained",
        sizes.includes(size) ? `m-button-${size}` : "m-button-medium",
        className,
      )}
      disabled={disabled}
    >
      {isValidElement(startIcon) && (
        <span className="m-button-start-icon">{startIcon}</span>
      )}
      <span>{children}</span>
      {isValidElement(endIcon) && (
        <span className="m-button-end-icon">{endIcon}</span>
      )}
    </button>
  );
};

export default forwardRef(Button);
