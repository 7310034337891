import { ComponentProps, FC, ReactNode } from "react";
import classNames from "classnames";

interface LeftSidebarProps extends ComponentProps<"div"> {
  children?: ReactNode;
}

const LeftSidebar: FC<LeftSidebarProps> = ({
  children,
  className,
  ...props
}) => (
  <div className={classNames("left-sidebar", className)} {...props}>
    {children}
  </div>
);

export default LeftSidebar;
