import dragIcon from "../../../src/assets/icons/cross-drag.svg";
import { IoTrashSharp } from "react-icons/io5";
import "./ImportedFile.scss";
import { FC } from "react";

interface ImportedFileProps {
  videoTitle: string;
  videoId: string;
  onDelete?: (id: string) => void;
}

const ImportedFile: FC<ImportedFileProps> = ({
  videoTitle,
  videoId,
  onDelete,
}) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete(videoId);
    }
  };

  return (
    <div className="imported-video">
      <div className="infos">
        <img src={dragIcon} alt="dragIcon" />
        <p>{videoTitle}</p>
      </div>
      <IoTrashSharp
        color="red"
        size={25}
        onClick={handleDelete}
        className="delete-icon-trash"
      />
    </div>
  );
};

export default ImportedFile;
