import { ContactState } from "./message.types";
import { AppState } from "../../redux/types";
import { createSelector } from "reselect";

const selectContact = (state: AppState) => state.contact;

export const selectMessage = createSelector(
  selectContact,
  (state: ContactState) => state.messages,
);
