import { selectCurrentUser } from "../../domain/currentUser/currentUser.selectors";
import { selectAdmins } from "../../domain/manageUsers/manageUsers.selectors";
import { getAdmins } from "../../domain/manageUsers/manageUsers.actions";
import { IAdmin } from "../../domain/manageUsers/manageUsers.types";
import AddAdminCard from "./add-admin-card/add-admin-card";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AdminCard from "./adminCard/adminCard";
import { FC, useLayoutEffect } from "react";

import "./manageAdmin.scss";

const ManageAdmin: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useLayoutEffect(() => {
    async function fetch() {
      dispatch(getAdmins());
    }
    fetch();
  }, [dispatch]);
  const admins: IAdmin[] = useSelector(selectAdmins);
  const currentUser = useSelector(selectCurrentUser);
  const hasCreateAdmin = currentUser.permissions?.includes("write_user");

  const adminsSortByRole = admins.sort((userA, userB) => {
    if (userA.email === currentUser.email) {
      return -1;
    }
    if (userB.email === currentUser.email) {
      return 1;
    }
    if (
      userA.isAdminPrincipal === userB.isAdminPrincipal ||
      userA.isAdministrator === userB.isAdministrator
    ) {
      if (userA.email) {
        return userA.email.localeCompare(userB.email as string);
      }
    }
    if (userA.isAdminPrincipal) {
      return -1;
    }
    return 1;
  });

  return (
    <div className="manage-admin-container">
      <div className="admins-wrapper">
        <h1 className="title-hidden">{t("h1_manage_admins")}</h1>
        {hasCreateAdmin && <AddAdminCard />}
        {adminsSortByRole.map((admin) => (
          <AdminCard key={admin.id} admin={admin} />
        ))}
      </div>
    </div>
  );
};

export default ManageAdmin;
