import {
  SAVE_PAYMENT,
  SAVE_PAYMENT_SUCCESS,
  SAVE_PAYMENT_FAILURE,
  INIT_PAYMENT,
  INIT_PAYMENT_SUCCESS,
  INIT_PAYMENT_FAILURE,
} from "./payment.types";

export const initPayment = (
  kcId: string,
): {
  type: string;
  kcId: string;
} => ({
  type: INIT_PAYMENT,
  kcId,
});

export const initPaymentSuccess = (
  payment: unknown,
): {
  type: string;
  payment: unknown;
} => ({
  type: INIT_PAYMENT_SUCCESS,
  payment,
});

export const initPaymentFailure = (
  payment: unknown,
): {
  type: string;
  payment: unknown;
} => ({
  type: INIT_PAYMENT_FAILURE,
  payment,
});

export const savePayment = (
  payment: unknown,
): {
  type: string;
  payment: unknown;
} => ({
  type: SAVE_PAYMENT,
  payment,
});

export const savePaymentSuccess = (
  payment: unknown,
): {
  type: string;
  payment: unknown;
} => ({
  type: SAVE_PAYMENT_SUCCESS,
  payment,
});

export const savePaymentFailure = (
  error: string,
): {
  type: string;
  error: string;
} => ({
  type: SAVE_PAYMENT_FAILURE,
  error,
});
