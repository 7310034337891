import {
  SHOW_SNACKBAR,
  RESET_SNACKBAR,
  ISnackBar,
  SuccessOrErrorActionTypes,
} from "./interactionsWithUsers.types";

export const showSnackBar = (data: ISnackBar): SuccessOrErrorActionTypes => ({
  type: SHOW_SNACKBAR,
  data,
});

export const resetSnackBar = (): SuccessOrErrorActionTypes => ({
  type: RESET_SNACKBAR,
});
