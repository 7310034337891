// import {
//   getBeneficiaryExamSession,
//   updateExerciseBeneficiary,
// } from "../../../../domain/e-learning/e-learning.actions";
// import {
//   selectExercises,
//   selectSession,
//   selectLoading,
// } from "../../../../domain/e-learning/e-learning.selectors";
// import { selectCurrentUser } from "../../../../domain/currentUser/currentUser.selectors";
// import { ReactComponent as Valid } from "../../../../assets/icons/valid.svg";
// import ExercisePlayer from "../exercise-player/exercise-player";
// import AnswerPicker from "../answer-picker/answer-picker";
// import { useDispatch, useSelector } from "react-redux";
// import { PlayerSdk } from "@api.video/player-sdk";
// import { useTranslation } from "react-i18next";
// import { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import Countdown from "react-countdown";
// import Score from "../score/score";
// import "./exam.scss";

// interface IVideo {
//   playerId: string;
//   videoId: string;
//   letter: string;
// }

// const letterList = ["A", "B", "C", "D"];

const Exam = (): JSX.Element => {
  //   const { t } = useTranslation();
  //   const dispatch = useDispatch();
  //   const { handleSubmit } = useForm();

  //   const playerList = {
  //     main: "main-player",
  //     complementary: "complementary-player",
  //     highlight: "highlight-player",
  //   };

  //   const loading = useSelector(selectLoading);
  //   const exercises = useSelector(selectExercises);
  //   const exercisesCount = exercises.length;

  //   const currentUser = useSelector(selectCurrentUser);
  //   const userId = currentUser?.id;

  //   const session = useSelector(selectSession);

  //   const [currentExercise, setCurrentExercise] = useState(
  //     exercises.find((exercise) => exercise.order === 0),
  //   );
  //   const [currentExerciseBeneficiary, setCurrentExerciseBeneficiary] = useState(
  //     session?.exercisesBeneficiary?.find(
  //       ({ exerciseId }) => exerciseId === currentExercise?.id,
  //     ),
  //   );

  //   const [currentExerciseNumber, setCurrentExerciseNumber] = useState(0);
  //   const [counter, setCounter] = useState(0);
  //   const [currentPlayer, setCurrentPlayer] = useState<PlayerSdk>();
  //   const [isCurrentPlayerPaused, setIsCurrentPlayerPaused] = useState(false);
  //   const [currentPlayerId, setCurrentPlayerId] = useState(playerList.main);
  //   const [currentVideoId, setCurrentVideoId] = useState(
  //     currentExercise?.question?.video?.videoId,
  //   );
  //   const [currentLetter, setCurrentLetter] = useState("");
  //   const [displayGrayLayer, setDisplayGrayLayer] = useState(true);
  //   const [hasReadAllVideos, setHasReadAllVideos] = useState(false);
  //   const [answersSelected, setAnswersSelected] = useState<string[]>([]);
  //   const [isAnswersChecked, setIsAnswersChecked] = useState(false);
  //   const [isExamFinished, setIsExamFinished] = useState(false);
  //   const [timer, setTimer] = useState<number | boolean>(false);
  //   const TIMER_DURATION = 90000;

  //   useEffect(() => {
  //     if (!isAnswersChecked && session && exercises.length > 0) {
  //       let currentExerciceNumberFromSession = 0;

  //       exercises.forEach((exercise) => {
  //         const exerciseFromSession = session?.exercisesBeneficiary?.find(
  //           (exerciseBeneficiary) =>
  //             exercise.id === exerciseBeneficiary.exerciseId,
  //         );

  //         if (exerciseFromSession?.isDone) {
  //           currentExerciceNumberFromSession = exercise.order + 1;
  //         }
  //       });

  //       setCurrentExerciseNumber(currentExerciceNumberFromSession);
  //     }
  //   }, [isAnswersChecked, session, exercises]);

  //   useEffect(() => {
  //     if (userId) {
  //       dispatch(getBeneficiaryExamSession(userId as string));
  //     }
  //   }, [dispatch, userId]);

  //   useEffect(() => {
  //     setCurrentExercise(() =>
  //       exercises.find((exercise) => exercise.order === currentExerciseNumber),
  //     );
  //     setIsExamFinished(
  //       exercises.length === currentExerciseNumber && exercises.length > 0,
  //     );
  //   }, [exercises, currentExerciseNumber]);

  //   useEffect(() => {
  //     setCurrentExerciseBeneficiary(
  //       session?.exercisesBeneficiary?.find(
  //         ({ exerciseId }) => exerciseId === currentExercise?.id,
  //       ),
  //     );
  //   }, [session, currentExercise]);

  //   const createPlayer = ({ playerId, videoId }: IVideo): PlayerSdk =>
  //     new PlayerSdk(`#${playerId}`, {
  //       id: videoId,
  //       hideTitle: true,
  //       autoplay: true,
  //       muted: true,
  //       hideControls: true,
  //     });

  //   const handleToggleVideo = (
  //     playerId: string,
  //     videoId?: string,
  //     letter?: string,
  //   ) => {
  //     if (currentPlayer && !isCurrentPlayerPaused) {
  //       currentPlayer.pause();
  //     } else if (currentPlayer && isCurrentPlayerPaused) {
  //       currentPlayer.play();
  //     }

  //     if (!currentPlayer && videoId && letter) {
  //       setDisplayGrayLayer(true);
  //       setCurrentPlayerId(playerId);
  //       setCurrentVideoId(videoId);
  //       setCurrentLetter(letter);

  //       const player = createPlayer({
  //         playerId,
  //         videoId,
  //         letter,
  //       });

  //       player.addEventListener("play", () => {
  //         setIsCurrentPlayerPaused(false);
  //       });

  //       player.addEventListener("pause", () => {
  //         setIsCurrentPlayerPaused(true);
  //       });

  //       player.addEventListener("ended", () => {
  //         setDisplayGrayLayer(false);
  //         setCurrentPlayerId("");
  //         setCurrentLetter("");
  //         setCurrentPlayer(undefined);
  //         player.destroy();
  //       });

  //       setCurrentPlayer(player);
  //     }
  //   };

  //   useEffect(() => {
  //     if (!currentExercise) {
  //       return undefined;
  //     }

  //     const videos: IVideo[] = [];

  //     if (currentExercise?.question?.video?.videoId) {
  //       videos.push({
  //         playerId: playerList.main,
  //         videoId: currentExercise.question.video.videoId,
  //         letter: "",
  //       });
  //     }

  //     if (currentExercise?.question?.complementaryVideo?.videoId) {
  //       videos.push({
  //         playerId: playerList.complementary,
  //         videoId: currentExercise.question.complementaryVideo.videoId,
  //         letter: "",
  //       });
  //     } else {
  //       videos.push({
  //         playerId: playerList.highlight,
  //         videoId: "",
  //         letter: "",
  //       });
  //     }

  //     if (currentExercise?.question?.answers) {
  //       currentExercise.question.answers.forEach(
  //         (answer, index) =>
  //           answer?.video?.videoId &&
  //           videos.push({
  //             playerId: playerList.main,
  //             videoId: answer?.video?.videoId,
  //             letter: letterList[index],
  //           }),
  //       );
  //     }

  //     let player;

  //     if (videos[counter].playerId !== playerList.highlight) {
  //       player = createPlayer(videos[counter]);
  //     } else {
  //       setTimeout(() => {
  //         const nextVideo = videos[counter + 1];
  //         if (nextVideo) {
  //           setCurrentPlayerId(videos[counter + 1].playerId);
  //           setCurrentVideoId(videos[counter + 1].videoId);
  //           setCurrentLetter(videos[counter + 1].letter);
  //           setCounter(counter + 1);
  //         }
  //       }, 5000);
  //     }

  //     if (!player) {
  //       return undefined;
  //     }

  //     player.addEventListener("ended", () => {
  //       if (counter + 1 < videos.length) {
  //         const nextVideo = videos[counter + 1];
  //         if (nextVideo) {
  //           setCurrentPlayerId(nextVideo.playerId);
  //           setCurrentVideoId(nextVideo.videoId);
  //           setCurrentLetter(nextVideo.letter);
  //           setCounter(counter + 1);
  //         }
  //       } else {
  //         setCurrentPlayerId("");
  //         setDisplayGrayLayer(false);
  //         setCurrentLetter("");
  //         setHasReadAllVideos(true);
  //         setTimer(Date.now());
  //         player.destroy();
  //       }
  //     });

  //     return () => {
  //       player.destroy();
  //     };
  //   }, [
  //     currentExercise,
  //     counter,
  //     playerList.highlight,
  //     playerList.main,
  //     playerList.complementary,
  //   ]);

  //   const handleSelectVideo = (answerId: string) => {
  //     if (answersSelected.includes(answerId)) {
  //       setAnswersSelected((answers) => [
  //         ...answers.filter((answer) => answer !== answerId),
  //       ]);
  //     } else {
  //       setAnswersSelected((answers) => [...answers, answerId]);
  //     }
  //   };

  //   const checkIfAnswerIsSelected = (answerId: string) =>
  //     answersSelected.includes(answerId);

  //   const checkIfAnswerIsCorrect = (answerId: string) => {
  //     const answerToCheck = currentExerciseBeneficiary?.answersBeneficiary?.find(
  //       (answer) => answer.answerId === answerId,
  //     );

  //     if (answerToCheck && answerToCheck.isCorrectAnswer !== undefined) {
  //       return answerToCheck.isCorrectAnswer;
  //     }
  //     return null;
  //   };

  //   const onSubmit = () => {
  //     const answersBeneficiaryToSubmit =
  //       currentExerciseBeneficiary?.answersBeneficiary?.map((answer) => ({
  //         ...answer,
  //         selected: answersSelected.includes(answer.answerId as string),
  //       }));

  //     const exerciseBeneficiaryToSubmit = {
  //       ...currentExerciseBeneficiary,
  //       answersBeneficiary: answersBeneficiaryToSubmit,
  //     };

  //     dispatch(updateExerciseBeneficiary(exerciseBeneficiaryToSubmit));

  //     setCounter(0);
  //     setCurrentPlayer(undefined);
  //     setCurrentPlayerId(playerList.main);
  //     setCurrentLetter("");
  //     setDisplayGrayLayer(true);
  //     setHasReadAllVideos(false);
  //     setTimer(false);
  //     setCurrentExerciseNumber((a) => a + 1);
  //     setAnswersSelected([]);
  //     setIsAnswersChecked(false);
  //   };

  //   const renderExamTimer = ({ formatted }) => (
  //     <span>
  //       {formatted.minutes}:{formatted.seconds}
  //     </span>
  //   );

  //   const renderBreadcrumb = () => (
  //     <div className="breadcrumb-container">
  //       <div>
  //         <div className="breadcrumb">
  //           {`${t("question")}: `}
  //           <span className="breadcrumb-value">{`${currentExerciseNumber + 1}/${exercisesCount}`}</span>
  //         </div>
  //         <div className="progress">
  //           <div
  //             className="progress-bar"
  //             style={{
  //               width: `${(((currentExerciseNumber + 1) / exercisesCount) * 100).toFixed(2)}%`,
  //               backgroundColor: "red",
  //             }}
  //           />
  //         </div>
  //       </div>
  //       {hasReadAllVideos && timer && (
  //         <div className="exam-countdown">
  //           <Countdown
  //             date={Number(timer) + TIMER_DURATION}
  //             renderer={renderExamTimer}
  //             onComplete={onSubmit}
  //           />
  //         </div>
  //       )}
  //     </div>
  //   );

  //   const renderGrayLayer = () =>
  //     displayGrayLayer && <div className="gray-layer" />;

  //   const renderExamLeft = () => (
  //     <div className="exam-left col-lg-3 p-3">
  //       <div
  //         className={`player-container ${currentPlayerId === playerList.main ? "player-focus" : ""}`}
  //       >
  //         <ExercisePlayer
  //           key={currentExercise?.id}
  //           src={currentExercise?.question?.video?.thumbnail}
  //           playerId={playerList.main}
  //           letter={currentLetter}
  //           isPlaying={currentPlayerId === playerList.main}
  //           isPaused={
  //             currentPlayerId === playerList.main ? isCurrentPlayerPaused : true
  //           }
  //           showPlayButton={Boolean(
  //             currentExercise?.question?.video?.videoId &&
  //               hasReadAllVideos &&
  //               !isAnswersChecked,
  //           )}
  //           handleToggleVideo={() =>
  //             handleToggleVideo(
  //               playerList.main,
  //               currentExercise?.question?.video?.videoId,
  //               "",
  //             )
  //           }
  //           isCorrect={
  //             isAnswersChecked ? currentExerciseBeneficiary?.isCorrect : undefined
  //           }
  //           isLoading={false}
  //         />
  //       </div>
  //     </div>
  //   );

  //   const renderAnswers = () => (
  //     <div className="answers-container row d-flex justify-content-start p-0">
  //       {currentExercise?.question?.answers?.map(
  //         (answer, index) =>
  //           answer?.video?.thumbnail && (
  //             <div
  //               key={answer.video.id}
  //               className={`answer-container col-lg-2 ${
  //                 displayGrayLayer && currentVideoId === answer.video.videoId
  //                   ? "answer-hidden"
  //                   : ""
  //               }`}
  //             >
  //               <AnswerPicker
  //                 src={answer?.video?.thumbnail}
  //                 letter={letterList[index]}
  //                 showPlayButton={hasReadAllVideos && !isAnswersChecked}
  //                 handleToggleVideo={() =>
  //                   handleToggleVideo(
  //                     playerList.main,
  //                     answer.video?.videoId,
  //                     letterList[index],
  //                   )
  //                 }
  //                 selected={checkIfAnswerIsSelected(answer.id)}
  //                 handleSelectVideo={() => handleSelectVideo(answer.id)}
  //                 isAnswersChecked={isAnswersChecked}
  //                 isCorrectAnswer={checkIfAnswerIsCorrect(answer.id)}
  //                 isLoading={false}
  //               />
  //             </div>
  //           ),
  //       )}
  //     </div>
  //   );

  //   const renderExamRight = () => (
  //     <div className="exam-right col-lg-9 p-3">
  //       <div
  //         className={`complementary-container ${
  //           currentPlayerId === playerList.complementary ||
  //           currentPlayerId === playerList.highlight
  //             ? "player-focus"
  //             : ""
  //         }`}
  //       >
  //         <ExercisePlayer
  //           key={currentExercise?.id}
  //           src={
  //             currentExercise?.question?.complementaryVideo?.thumbnail ||
  //             currentExercise?.question?.picto
  //           }
  //           playerId={playerList.complementary}
  //           letter=""
  //           isPlaying={currentPlayerId === playerList.complementary}
  //           isPaused={
  //             currentPlayerId === playerList.complementary
  //               ? isCurrentPlayerPaused
  //               : true
  //           }
  //           showPlayButton={Boolean(
  //             currentExercise?.question?.complementaryVideo?.videoId &&
  //               hasReadAllVideos &&
  //               !isAnswersChecked,
  //           )}
  //           handleToggleVideo={() =>
  //             handleToggleVideo(
  //               playerList.complementary,
  //               currentExercise?.question?.complementaryVideo?.videoId,
  //               "",
  //             )
  //           }
  //         />
  //       </div>
  //       {renderAnswers()}
  //     </div>
  //   );

  //   const renderExamContent = () =>
  //     currentExercise && (
  //       <div className="exam-container row">
  //         {renderExamLeft()}
  //         {renderExamRight()}
  //       </div>
  //     );

  //   const renderSubmit = () => (
  //     <div className="submit-container">
  //       <button
  //         className={`valid-icon-button ${answersSelected.length === 0 ? "btn-disabled" : ""}`}
  //         type="submit"
  //         disabled={answersSelected.length === 0}
  //         aria-label={t("send")}
  //       >
  //         <div className="valid-icon-container">
  //           <Valid className="valid-icon" />
  //         </div>
  //       </button>
  //     </div>
  //   );

  //   return (
  //     <>
  //       <form className="exam-form" onSubmit={handleSubmit(onSubmit)}>
  //         <h1 className="title-hidden">{t("exam")}</h1>
  //         <section className="exam">
  //           {currentExercise && !isExamFinished && (
  //             <>
  //               {renderGrayLayer()}
  //               {renderBreadcrumb()}
  //               {renderExamContent()}
  //               {renderSubmit()}
  //             </>
  //           )}
  //           {!loading && isExamFinished && <Score id={session.id} isExam />}
  //         </section>
  //       </form>
  //     </>
  //   );
  return <></>;
};

export default Exam;
