import { useTranslation } from "react-i18next";
import { FC, SyntheticEvent } from "react";
import "./AdminCardPermission.scss";
import Switch from "react-switch";

type AdminCardPermissionProps = {
  handleSwitchChange: (
    checked: boolean,
    event: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string,
  ) => void;
  editMode: boolean;
  accessChatbot: boolean;
  modifySupport: boolean;
  createAdmin: boolean;
  pedagogicSupervision: boolean;
  ariaId: number;
};
const AdminCardPermission: FC<AdminCardPermissionProps> = ({
  ariaId = 0,
  accessChatbot = false,
  modifySupport = false,
  createAdmin = false,
  pedagogicSupervision = false,
  handleSwitchChange,
  editMode,
}: AdminCardPermissionProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className="permissions-list"
      role="group"
      aria-labelledby={`permissions-${ariaId}`}
    >
      <span id={`permissions-${ariaId}`} className="permissions-title">
        {t("Autorisations")}
      </span>

      <div className="permissions-item">
        <label htmlFor={`createAdmin-${ariaId}`}>
          <span>{t("Create admin account")}</span>
          <div className="switch-element">
            <Switch
              checked={createAdmin}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id={`createAdmin-${ariaId}`}
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={editMode !== true}
            />
          </div>
        </label>
      </div>

      <div className="permissions-item">
        <label htmlFor={`modifySupport-${ariaId}`}>
          <span>{t("Modify pedagogique support")}</span>
          <div className="switch-element">
            <Switch
              checked={modifySupport}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              id={`modifySupport-${ariaId}`}
              disabled={editMode !== true}
            />
          </div>
        </label>
      </div>
      <div className="permissions-item">
        <label htmlFor={`pedagogicSupervision-${ariaId}`}>
          <span>{t("Access pedagogic supervision")}</span>
          <div className="switch-element">
            <Switch
              checked={pedagogicSupervision}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id={`pedagogicSupervision-${ariaId}`}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#017c8f"
              disabled={editMode !== true}
            />
          </div>
        </label>
      </div>
      <div className="permissions-item">
        <label htmlFor={`accessChatbot-${ariaId}`}>
          <span>{t("Access chatbot")}</span>
          <div className="switch-element">
            <Switch
              checked={accessChatbot}
              onChange={(checked, event, id) => {
                if (handleSwitchChange) handleSwitchChange(checked, event, id);
              }}
              id={`accessChatbot-${ariaId}`}
              onColor="#017c8f"
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={editMode !== true}
            />
          </div>
        </label>
      </div>
    </div>
  );
};

export default AdminCardPermission;
