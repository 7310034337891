export const SAVE_MESSAGE = "SAVE_MESSAGE";
export const SAVE_MESSAGE_SUCCESS = "SAVE_MESSAGE_SUCCESS";
export const SAVE_MESSAGE_FAILURE = "SAVE_MESSAGE_FAILURE";
export const GET_ALL_MESSAGES = "GET_ALL_MESSAGES";
export const GET_ALL_MESSAGES_SUCCESS = "GET_ALL_MESSAGES_SUCCESS";
export const GET_ALL_MESSAGES_FAILURE = "GET_ALL_MESSAGES_FAILURE";

export type Message = {
  id?: string;
  sender: number;
  text: string;
  createdAt?: Date;
};
export type ContactState = {
  messages: Message[];
  loading: boolean;
  errors?: string;
};
