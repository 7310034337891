import { FC, ReactNode } from "react";
import "./TabPanel.scss";

export type TabPanelProps = {
  children: ReactNode;
  value: number;
  index: number;
};

const TabPanel: FC<TabPanelProps> = ({
  children,
  value,
  index,
}: TabPanelProps) => {
  if (value !== index) return null;
  return <div className="tab-panel">{children}</div>;
};

export default TabPanel;
