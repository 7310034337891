import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";

function MessageApi(executor: ApiExecutorType): {
  getAllMessages: () => Promise<{
    body: unknown;
    status: number;
  }>;
  saveMessage: ({ sender, text }: { sender: number; text: string }) => unknown;
} {
  function saveMessage({ sender, text }) {
    return executor.post("/contact/message", { sender, text });
  }
  function getAllMessages() {
    return executor.get("/contact/message");
  }
  return { getAllMessages, saveMessage };
}

export default MessageApi;
