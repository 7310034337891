import { ComponentProps, FC, ReactNode } from "react";
import classNames from "classnames";

interface TitleProps extends ComponentProps<"h1"> {
  children: ReactNode;
}

const Title: FC<TitleProps> = ({ children, className, ...props }) => (
  <h1 className={classNames("title", className)} {...props}>
    {children}
  </h1>
);

export default Title;
