import DeleteThemeConfirmationDialog from "../../components/DeleteThemeConfirmationDialog/DeleteThemeConfirmationDialog";
import {
  selectLoading,
  selectThemes,
} from "../../domain/e-learning/e-learning.selectors";
import RadioGroupField from "../../components/RadioGroupField/RadioGroupField";
import { getAdminThemes } from "../../domain/e-learning/e-learning.actions";
import { ITheme } from "../../domain/e-learning/e-learning.types";
import useQueryParamsState from "../../utils/useQueryParamsState";
import IconButton from "../../components/IconButton/IconButton";
import LeftSidebar from "../../components/Page/LeftSidebar";
import { ChangeEvent, FC, useEffect, useMemo } from "react";
import { IoAddCircle, IoCreate } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import Topbar from "../../components/Page/Topbar";
import Paper from "../../components/Paper/Paper";
import Theme from "../../components/Theme/Theme";
import Title from "../../components/Page/Title";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import "./ELearning.scss";

type ThemeOption = { label: ITheme["title"]; value: ITheme["id"] };

const ELearning: FC = () => {
  const { t } = useTranslation();
  const themes = useSelector(selectThemes);
  const loading = useSelector(selectLoading);
  const themeOptions: ThemeOption[] = useMemo(
    () => themes.map((theme) => ({ label: theme.title, value: theme.id })),
    [themes],
  );
  const [theme, setTheme] = useQueryParamsState("theme", "");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminThemes());
  }, [dispatch]);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTheme = event.target.value || "";
    setTheme(newTheme);
  };
  if (loading) return null;
  return (
    <Page>
      <Topbar>
        <Title>Formations</Title>
        <Button
          to="/e-learning/add"
          startIcon={<IoAddCircle />}
          variant="outlined"
        >
          {t("Ajouter une thématique")}
        </Button>
      </Topbar>
      <LeftSidebar>
        <Paper className="e-learning-theme-selector" fullWidth>
          <RadioGroupField
            label="Thématiques"
            name="theme"
            options={themeOptions}
            value={theme}
            onChange={onChange}
            renderOption={(option: ThemeOption) => (
              <div className="e-learning-theme-selector-option-actions">
                <IconButton to={`/e-learning/${option.value}/edit`}>
                  <IoCreate />
                </IconButton>
                <DeleteThemeConfirmationDialog id={option.value} />
              </div>
            )}
          />
        </Paper>
      </LeftSidebar>
      <Content>
        <Theme id={theme} />
      </Content>
    </Page>
  );
};

export default ELearning;
