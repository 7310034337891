import { FC } from "react";

import "./Bounces.scss";

const Bounces: FC = (): JSX.Element => (
  <div className="bounces">
    <div className="bounce" />
    <div className="bounce" />
  </div>
);

export default Bounces;
