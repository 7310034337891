import { CreateThemeActionPayload } from "../../domain/e-learning/e-learning.types";
import { createTheme } from "../../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../../components/StepBar/StepNav";
import ThemeForm from "../../components/ThemeForm/ThemeForm";
import LeftSidebar from "../../components/Page/LeftSidebar";
import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import Topbar from "../../components/Page/Topbar";
import useNavigate from "../../utils/useNavigate";
import { useTranslation } from "react-i18next";
import Page from "../../components/Page/Page";
import mapWithId from "../../utils/mapWithId";
import { useDispatch } from "react-redux";
import { FC } from "react";
import "./AddTheme.scss";

const steps: Step[] = mapWithId([
  {
    label: "e_learning_add",
    to: "/e-learning/add",
  },
  {
    label: "e_learning_add_courses",
    disabled: true,
  },
  {
    label: "e_learning_add_exercises",
    disabled: true,
  },
]);

const AddTheme: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate("/e-learning");
  const onSubmit = (data: CreateThemeActionPayload) => {
    dispatch(createTheme(data));
    navigate();
  };
  return (
    <Page disabledGutters>
      <Topbar className="add-theme-topbar">
        <StepNav steps={steps} />
      </Topbar>
      <LeftSidebar className="add-theme-left-sidebar">
        <p className="m-typography-body1">{t("share_content")}</p>
        <input className="add-theme-member-input" disabled />
        <Button disabled>{t("add")}</Button>
      </LeftSidebar>
      <Content>
        <ThemeForm title="e_learning_add" onSubmit={onSubmit} />
      </Content>
    </Page>
  );
};

export default AddTheme;
