import { useTranslation } from "react-i18next";
import { FC, useState, useRef } from "react";

import "./course-item.scss";

interface ICourseItem {
  src: string;
  srt: string;
}

const CourseItem: FC<ICourseItem> = ({ src, srt = "" }: ICourseItem) => {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = () => videoRef?.current?.play();

  const pauseVideo = () => videoRef?.current?.pause();

  const switchVideo = () =>
    videoRef?.current?.paused ? playVideo() : pauseVideo();

  function handleToggleVideo() {
    setActive(!isActive);
    switchVideo();
  }

  return (
    <div className="course-item">
      <video ref={videoRef} muted crossOrigin="anonymous">
        <source src={src} type="video/mp4" />
        <track default lang="fr" kind="captions" src={srt} />
      </video>
      <button
        type="button"
        className={`control-button ${isActive ? "pause-button" : "play-button"}`}
        onClick={handleToggleVideo}
        aria-label={t("aria_label_play")}
      />
    </div>
  );
};

export default CourseItem;
