import Button from "../../components/Button/Button";
import Content from "../../components/Page/Content";
import Page from "../../components/Page/Page";
import { FC } from "react";
import "./NotFound.scss";

const NotFound: FC = () => {
  return (
    <Page>
      <Content>
        <div className="not-found">
          <p className="m-typography-body1">
            Désolé, cette page n&apos;existe pas
          </p>
          <Button to="/">Revenir à la page d&apos;accueil</Button>
        </div>
      </Content>
    </Page>
  );
};

export default NotFound;
