import { ComponentProps, FC, ReactNode } from "react";
import classNames from "classnames";

interface TopbarProps extends ComponentProps<"div"> {
  children: ReactNode;
}

const Topbar: FC<TopbarProps> = ({ children, className, ...props }) => (
  <div className={classNames("topbar", className)} {...props}>
    {children}
  </div>
);

export default Topbar;
