import { selectBeneficiaryCourses } from "../../../../domain/e-learning/e-learning.selectors";
import { getCourses } from "../../../../domain/e-learning/e-learning.actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, NavLink } from "react-router-dom";
import CourseItem from "./course-item/course-item";
import { useTranslation } from "react-i18next";
import { FC, useEffect } from "react";

import arrowBack from "../../../../assets/icons/arrow-back.svg";

import "./courses-player.scss";

const CoursesPlayer: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { themeId } = useParams<{ themeId: string }>();
  const courses = useSelector(selectBeneficiaryCourses);

  useEffect(() => {
    dispatch(getCourses(themeId as string));
  }, [dispatch, themeId]);

  return (
    <div className="courses">
      <h1 className="title-hidden">{t("h1_course_player")}</h1>
      <div className="courses-actions">
        <NavLink exact to="/courses" className="courses-link" role="button">
          <img src={arrowBack} alt="revenir en arrière" />
        </NavLink>
      </div>
      <div className="courses-list">
        {courses
          ?.sort((a, b) => a.order - b.order)
          .map((course) => (
            <CourseItem
              key={course.id}
              src={course.video?.mp4 || ""}
              srt={course.video?.vtt || ""}
            />
          ))}
      </div>
    </div>
  );
};

export default CoursesPlayer;
