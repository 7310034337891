import { createContext, createRef, ReactNode, RefObject } from "react";
import FocusTrap from "focus-trap-react";
import { createPortal } from "react-dom";

import "./modalComponent/modal-component.scss";

interface IModalProps {
  children: ReactNode;
  onModalClose: () => void;
  onConfirm: () => void;
  size: string;
}
interface IModalContext {
  onModalClose: () => void;
  onConfirm: () => void;
  size: string;
}
const modalContextDefaultValues = {
  onModalClose: () => null,
  onConfirm: () => null,
  size: "medium",
};

export const modalContext = createContext<IModalContext>(
  modalContextDefaultValues,
);
export default function Modal({
  children,
  onModalClose,
  size,
  onConfirm,
}: IModalProps): JSX.Element {
  const modalRef = createRef<HTMLDivElement>();

  const valueContext: IModalContext = {
    onModalClose,
    onConfirm,
    size,
  };
  return createPortal(
    <FocusTrap>
      <div
        className={`modal-container ${size}`}
        role="dialog"
        aria-modal="true"
      >
        <div
          className={`${"modal-content"} ${size}`}
          ref={modalRef as unknown as RefObject<HTMLDivElement>}
        >
          <modalContext.Provider value={valueContext}>
            {children}
          </modalContext.Provider>
        </div>
      </div>
    </FocusTrap>,
    document.body,
  );
}
