import {
  selectSession,
  selectThemes,
} from "../../../domain/e-learning/e-learning.selectors";
import { getBeneficiaryPracticeSession } from "../../../domain/e-learning/e-learning.actions";
import { selectCurrentUser } from "../../../domain/currentUser/currentUser.selectors";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import config from "../../../config";
import "./progress-circular.scss";

interface IProgress {
  id: string;
}

const apiUrl = config.SIGNMOVE_API_URL;

const ProgressCircular: FC<IProgress> = ({ id }: IProgress): JSX.Element => {
  const { t } = useTranslation();
  const [animatedPercentage, setAnimatedPercentage] = useState(0);
  const [completedCurrentPercentage, setCompletedCurrentPercentage] =
    useState(0);
  const fetchedData = useRef(false);
  const currentUser = useSelector(selectCurrentUser);
  const selectionSessions = useSelector(selectSession);
  const userId = currentUser?.id;
  const dispatch = useDispatch();
  const nameThemes = useSelector(selectThemes);
  const titles = nameThemes.find(
    (theme: { id: string; title: string }) => theme.id === id,
  )?.title;
  const themeIdMap = useRef({});
  const [themeCounters, setThemeCounters] = useState({});
  const exercises = selectionSessions.exercisesBeneficiary;
  const atLeastOneIsDoneTrue = exercises?.some(
    (exercise: { isDone?: boolean }) => exercise.isDone === true,
  );
  const isNewConditionMet = themeCounters[id] === 0 && atLeastOneIsDoneTrue;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/theme/available-theme-ids`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const fetchedThemeIds = await response.json();
        const uniqueThemeIds = new Set(fetchedThemeIds);
        const filteredThemeIds = [...uniqueThemeIds].filter((themeId) => {
          if (typeof themeId === "string" && !(themeId in themeIdMap.current)) {
            themeIdMap.current[themeId] = true;
            return true;
          }
          return false;
        });
        const promises = filteredThemeIds.map((themeId) =>
          dispatch(
            getBeneficiaryPracticeSession(userId as string, themeId as string),
          ),
        );
        await Promise.all(promises);
      } catch (error) {
        // eslint-disable-next-line
        console.error("Error fetching data: ", error);
      }
    };
    if (userId && !fetchedData.current) {
      fetchData();
      fetchedData.current = true;
    }
  }, [userId, dispatch, themeIdMap]);
  useEffect(() => {
    if (selectionSessions.themeId === id) {
      if (exercises) {
        const totalItems = exercises.length;
        const completedItems = exercises.filter(
          (exercise: { isDone?: boolean }) => exercise.isDone === true,
        ).length;
        const allIsDoneFalse = exercises.every(
          (exercise: { isDone?: boolean }) => exercise.isDone === false,
        );

        if (themeCounters[id] === 0 && atLeastOneIsDoneTrue) {
          setCompletedCurrentPercentage(
            totalItems > 0 ? (completedItems / totalItems) * 100 : 0,
          );
        } else if (themeCounters[id] > 0 && allIsDoneFalse) {
          setCompletedCurrentPercentage(100);
        } else {
          setCompletedCurrentPercentage(
            totalItems > 0 ? (completedItems / totalItems) * 100 : 0,
          );
        }
        setThemeCounters((prevCounters) => ({
          ...prevCounters,
          [id]: selectionSessions.counter,
        }));
      }
    }
  }, [id, selectionSessions, atLeastOneIsDoneTrue, exercises, themeCounters]);

  useEffect(() => {
    let animationInterval: ReturnType<typeof setInterval>;
    const animatePercentage = () => {
      setAnimatedPercentage((prevPercentage) => {
        const newPercentage = prevPercentage + 1;
        if (newPercentage <= completedCurrentPercentage) {
          return newPercentage;
        }
        return completedCurrentPercentage;
      });
    };

    if (isNewConditionMet || completedCurrentPercentage > animatedPercentage) {
      animationInterval = setInterval(animatePercentage, 10);
    }
    return () => {
      if (animationInterval) {
        clearInterval(animationInterval);
      }
    };
  }, [completedCurrentPercentage, animatedPercentage, isNewConditionMet]);

  const isTerminated = themeCounters[id] > 0;
  const getSafeProgressPercentage = () => {
    if (isNewConditionMet) {
      return parseFloat(String(completedCurrentPercentage)) || 0;
    }
    if (isTerminated) {
      return 100;
    }
    return parseFloat(String(completedCurrentPercentage)) || 0;
  };

  const safeProgressPercentage = getSafeProgressPercentage();
  const progressText =
    safeProgressPercentage === 100
      ? t("Terminé !")
      : `${safeProgressPercentage.toFixed(1)}%`;
  const radius = 180;
  const circumference = 2 * Math.PI * radius;
  const dashArray = `${circumference} ${circumference}`;
  const dashOffset = (1 - animatedPercentage / 100) * circumference;
  return (
    <div className="progress-container">
      <svg className="progress-circle" width="360" height="360">
        <defs>
          <mask id="mask">
            <rect x="0" y="0" width="360" height="360" fill="white" />
            <circle
              cx="240"
              cy="240"
              r={radius}
              strokeWidth="40"
              fill="none"
              strokeLinecap="round"
              stroke="white"
              strokeDasharray={dashArray}
              strokeDashoffset={dashOffset}
              transform="rotate(-90, 240, 240)"
            />
          </mask>
        </defs>
        <circle
          className="progress-circle-bg"
          cx="240"
          cy="240"
          r={radius}
          strokeWidth="20"
          fill="none"
          stroke="#E2F3F4"
        />
        <circle
          className="progress-circle-fg"
          cx="240"
          cy="240"
          r={radius}
          strokeWidth="20"
          fill="none"
          strokeLinecap="round"
          stroke={isTerminated ? "#00505B" : "#00505B"}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>
      <div className="progress-title">{titles}</div>
      <div className="progress-percentage">{progressText}</div>
    </div>
  );
};

export default ProgressCircular;
