import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { selectCurrentUser } from "../domain/currentUser/currentUser.selectors";
import { Permission, Role } from "../domain/currentUser/currentUser.types";
import { useKeycloak } from "@react-keycloak/web";
import isAuthorized from "../utils/isAuthorized";
import { useSelector } from "react-redux";
import { FC } from "react";

interface RestrictedRouteProps extends RouteProps {
  roles?: Role[];
  permissions?: Permission[];
  component: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

const RestrictedRoute: FC<RestrictedRouteProps> = ({
  roles,
  permissions,
  component: Component,
  ...rest
}) => {
  const { keycloak } = useKeycloak();
  const user = useSelector(selectCurrentUser);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!keycloak.authenticated) {
          return <Redirect to="/portal" />;
        }

        if (!user.kcId) {
          return <div>Loading...</div>;
        }

        if (user.status !== "done") {
          return <Redirect to="/register" />;
        }

        if (!isAuthorized(user, { roles, permissions })) {
          return <Redirect to="/401" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default RestrictedRoute;
