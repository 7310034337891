import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { IconType } from "react-icons";
import { FC } from "react";
import "./MenuItem.scss";

type MenuItemProps = {
  to?: string;
  alt?: string;
  IconComponent?: IconType;
  onClick?: () => void;
};

const MenuItem: FC<MenuItemProps> = ({
  to = "/",
  alt,
  IconComponent,
  onClick,
}) => {
  const { t } = useTranslation();
  if (!IconComponent) return null;
  if (onClick) {
    return (
      <button className="menu-item" type="button" onClick={onClick}>
        <IconComponent aria-label={alt && t(alt)} className="menu-item-icon" />
      </button>
    );
  }
  return (
    <NavLink
      to={to}
      exact
      className="menu-item"
      activeClassName="menu-item-active"
    >
      <IconComponent aria-label={alt && t(alt)} className="menu-item-icon" />
    </NavLink>
  );
};

export default MenuItem;
