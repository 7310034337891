import { FC, useEffect, useRef, useState } from "react";
import { IoTrashSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import "./AutoComplete.scss";

type Option = {
  label: string;
  value: string;
};

export interface AutoCompleteProps {
  options: Option[];
  name: string;
  id: string;
  className: string;
  handleChange: (id: string, values: string[]) => void;
  defaultValue: Option[];
}

const AutoComplete: FC<AutoCompleteProps> = ({
  name,
  options,
  id,
  className,
  handleChange,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedChoices, setSelectedChoices] = useState<Option[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      const value = defaultValue.map((item) => {
        return item.value;
      });

      setSelectedValues(value);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelectedChoices(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelectedChoices(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleChange(name, selectedValues);
  }, [selectedValues, name, handleChange]);

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  const handleInputClick = () => {
    setShowOptions(!showOptions);
  };

  const handleAddChoice = (selectedValue: string) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue,
    );

    if (selectedOption) {
      setSelectedChoices([...selectedChoices, selectedOption]);
      setSelectedValues([...selectedValues, selectedOption.value]);
    }

    setShowOptions(false);
  };

  const handleRemoveChoice = (choice: Option) => () => {
    setSelectedChoices(
      selectedChoices.filter((item) => item.value !== choice.value),
    );
    setSelectedValues(selectedValues.filter((item) => item !== choice.value));
  };

  return (
    <div className="autoComplete-wrapper">
      <div className="inputGroup">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onClick={handleInputClick}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={t("add_word")}
          className={className}
        />
        {showOptions && (
          <select
            multiple
            name={name}
            id={id}
            className="m-form-input select-input dropDownMenu"
            onChange={(e) => handleAddChoice(e.target.value)}
          >
            {options
              .filter(
                (option) =>
                  !selectedChoices.some(
                    (choice) => choice.value === option.value,
                  ) &&
                  option.label.toLowerCase().includes(inputValue.toLowerCase()),
              )
              .map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  className="select-input-option"
                >
                  {t(option.label)}
                </option>
              ))}
          </select>
        )}
      </div>

      <ul className="selected-choices">
        {selectedChoices.map((choice) => (
          <button
            key={choice.value}
            className="deleteButton"
            onClick={handleRemoveChoice(choice)}
          >
            <li className="choiceLabel">{choice.label}</li>
            <span className="icon">
              <IoTrashSharp />
            </span>
          </button>
        ))}
      </ul>
    </div>
  );
};

export default AutoComplete;
