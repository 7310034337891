import {
  GET_ADMINS,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAILURE,
  UPSERT_ADMIN,
  UPSERT_ADMIN_FAILURE,
  UPSERT_ADMIN_SUCCESS,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  ManageActionTypes,
  CreateAdminActionType,
  IAdmin,
} from "./manageUsers.types";

export const getAdmins = (): ManageActionTypes => ({
  type: GET_ADMINS,
});

export const getAdminsSuccess = (admins: IAdmin[]): ManageActionTypes => ({
  type: GET_ADMINS_SUCCESS,
  admins,
});

export const getAdminsFailure = (): ManageActionTypes => ({
  type: GET_ADMINS_FAILURE,
});

export const upsertAdmin = (admin: IAdmin): ManageActionTypes => ({
  type: UPSERT_ADMIN,
  admin,
});

export const upsertAdminSuccess = (admin: IAdmin): CreateAdminActionType => ({
  type: UPSERT_ADMIN_SUCCESS,
  admin,
});

export const upsertAdminFailure = (): ManageActionTypes => ({
  type: UPSERT_ADMIN_FAILURE,
});

export const deleteAdmin = (admin: IAdmin): ManageActionTypes => ({
  type: DELETE_ADMIN,
  admin,
});

export const deleteAdminSuccess = (admin: IAdmin): ManageActionTypes => ({
  type: DELETE_ADMIN_SUCCESS,
  admin,
});
export const deleteAdminFailure = (): ManageActionTypes => ({
  type: DELETE_ADMIN_FAILURE,
});
