import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAILURE,
  GET_ROLE_USER,
  GET_PERSONAL_INFORMATIONS,
  GET_PERSONAL_INFORMATIONS_SUCCESS,
  GET_PERSONAL_INFORMATIONS_FAILURE,
  UPDATE_INFOS,
  UPDATE_INFOS_SUCCESS,
  UPDATE_INFOS_FAILURE,
  ICurrentUser,
  CurrentUserState,
  CurrentUserActionTypes,
} from "./currentUser.types";

const INITIAL_STATE: CurrentUserState = {
  currentUser: {},
  loading: false,
};

const currentUser = (
  state = INITIAL_STATE,
  action: CurrentUserActionTypes,
): {
  currentUser: ICurrentUser | undefined;
  loading: boolean;
  errors?: string | undefined;
} => {
  switch (action.type) {
    case GET_CURRENT_USER:
    case GET_PERSONAL_INFORMATIONS:
    case UPDATE_INFOS:
      return {
        ...state,
        loading: true,
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: {
          ...state.currentUser,
          id: action.currentUser?.id,
          kcId: action.currentUser?.kcId,
          email: action.currentUser?.email,
          role: action.currentUser?.role,
          permissions: action.currentUser?.permissions,
          status: action.currentUser?.status,
        },
      };
    case GET_CURRENT_USER_FAILURE:
    case GET_PERSONAL_INFORMATIONS_FAILURE:
    case UPDATE_INFOS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.message,
      };
    case GET_ROLE_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          role: action.role,
        },
      };
    case GET_PERSONAL_INFORMATIONS_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          personalInfos: action.personalInfos,
        },
        loading: false,
      };
    case UPDATE_INFOS_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          personalInfos: action.personalInfos,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default currentUser;
