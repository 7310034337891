import { FC, ReactNode } from "react";
import "./Menu.scss";

type MenuProps = { children?: ReactNode };

const Menu: FC<MenuProps> = ({ children }) => {
  if (!children) return null;
  return (
    <nav className="menu">
      <ul className="menu-items">{children}</ul>
    </nav>
  );
};

export default Menu;
