import { ComponentProps, FC, Fragment, ReactNode } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import "./StepNav.scss";

export type Step = {
  id: string;
  label: string;
  to?: string;
  icon?: ReactNode;
  disabled?: boolean;
};

export interface StepNavProps extends ComponentProps<"button"> {
  steps: Step[];
}

const StepNav: FC<StepNavProps> = ({ steps = [] }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const activeStep = steps.findIndex((step) =>
    matchPath(location.pathname, {
      path: step.to,
      exact: true,
    }),
  );

  return (
    <nav className="step-nav">
      <ol className="step-nav-steps">
        {steps.map(({ id, to, label, icon, disabled }, index) => {
          const done = index < activeStep;
          const todo = index > activeStep;
          const hasSeparator = index !== steps.length - 1;

          return (
            <Fragment key={id}>
              <li>
                <Button
                  to={to}
                  color={done ? "secondary" : "primary"}
                  variant={todo ? "outlined" : "contained"}
                  disabled={disabled || todo}
                  startIcon={icon}
                >
                  {t(label)}
                </Button>
              </li>
              {hasSeparator && <li className="step-nav-separator" />}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default StepNav;
