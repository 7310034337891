import {
  GET_ALL_MESSAGES,
  GET_ALL_MESSAGES_FAILURE,
  GET_ALL_MESSAGES_SUCCESS,
  Message,
  SAVE_MESSAGE,
  SAVE_MESSAGE_FAILURE,
  SAVE_MESSAGE_SUCCESS,
} from "./message.types";

export const saveMessage = (
  message: Message,
): {
  type: string;
  message: Message;
} => ({
  type: SAVE_MESSAGE,
  message,
});
export const saveMessageSuccess = (
  message: Message,
): {
  type: string;
  message: Message;
} => ({
  type: SAVE_MESSAGE_SUCCESS,
  message,
});
export const saveMessageFailure = (
  error: string,
): {
  type: string;
  error: string;
} => ({
  type: SAVE_MESSAGE_FAILURE,
  error,
});

export const getAllMessages = (): {
  type: string;
} => ({
  type: GET_ALL_MESSAGES,
});
export const getAllMessagesSuccess = (
  messages: Message[],
): {
  type: string;
  messages: Message[];
} => ({
  type: GET_ALL_MESSAGES_SUCCESS,
  messages,
});
export const getAllMessagesFailure = (
  error: string,
): {
  type: string;
  error: string;
} => ({
  type: GET_ALL_MESSAGES_FAILURE,
  error,
});
