import Button, { ButtonProps } from "../Button/Button";
import useDialogContext from "./useDialogContext";
import { forwardRef } from "react";

const DialogClose = forwardRef<HTMLButtonElement, ButtonProps>(
  function DialogClose(props, ref) {
    const { setOpen } = useDialogContext();
    const onClose = () => setOpen(false);
    return <Button {...props} ref={ref} onClick={onClose} />;
  },
);

export default DialogClose;
