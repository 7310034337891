import {
  getCurrentUser,
  getPersonalInformations,
} from "../../domain/currentUser/currentUser.actions";
import { updateAxiosInstanceWithTokenHeader } from "../../utils/fetchApi";
import NotAuthorized from "../../pages/NotAuthorized/NotAuthorized";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFound from "../../pages/NotFound/NotFound";
import Register from "../../pages/Register/Register";
import { useKeycloak } from "@react-keycloak/web";
import RestrictedRoute from "../RestrictedRoute";
import Portal from "../../pages/Portal/Portal";
import { init } from "../../redux/root-action";
import { useDispatch } from "react-redux";
import Layout from "../Layout/Layout";
import { FC, useEffect } from "react";
import routes from "./routes";
import "../../i18n";

const AppRouter: FC = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  useEffect(() => {
    if (keycloak.subject) {
      updateAxiosInstanceWithTokenHeader(keycloak.token);
      dispatch(getCurrentUser(keycloak.subject));
      dispatch(getPersonalInformations(keycloak.subject));
    }
    dispatch(init());
  }, [dispatch, keycloak.subject, keycloak.token]);

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          {routes.map(({ id, path, component, roles, permissions, exact }) => (
            <RestrictedRoute
              key={id}
              path={path}
              exact={exact}
              component={component}
              roles={roles}
              permissions={permissions}
            />
          ))}
          <Route path="/register" component={Register} />
          <Route path="/portal" component={Portal} />
          <Route path="/401" component={NotAuthorized} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default AppRouter;
