import { ComponentProps, FC, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";
import "./RadioInput.scss";

export interface RadioInputProps extends ComponentProps<"input"> {
  label?: string;
  register?: Ref<HTMLInputElement>;
  children?: ReactNode;
}

const RadioInput: FC<RadioInputProps> = ({
  children,
  label,
  register,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className="radio-input-wrapper">
      <input type="radio" className="radio-input" ref={register} {...props} />
      {label && (
        <label htmlFor={props.id} className="m-form-label">
          {t(label)}
        </label>
      )}
      {children}
    </div>
  );
};

export default RadioInput;
