import { ComponentProps, FC, ReactNode, Ref } from "react";
import RadioInput from "../RadioInput/RadioInput";
import classNames from "classnames";
import "./RadioGroupField.scss";

type Option = {
  label?: string;
  value: string;
};

export interface RadioGroupFieldProps extends ComponentProps<"input"> {
  label?: string;
  options: Option[];
  register?: Ref<HTMLInputElement>;
  renderOption: (option: never) => ReactNode;
}

const RadioGroupField: FC<RadioGroupFieldProps> = ({
  options,
  label,
  renderOption,
  className,
  ...props
}) => {
  return (
    <fieldset
      className={classNames(
        "radio-group-field m-form-field m-form-field-full-width",
        className,
      )}
    >
      {label && <label className="m-form-label">{label}</label>}
      {options.map((option) => (
        <RadioInput
          key={option.value}
          {...props}
          id={option.value}
          type="radio"
          value={option.value}
          checked={props.register ? undefined : props.value === option.value}
          label={option.label}
        >
          {renderOption && renderOption(option as never)}
        </RadioInput>
      ))}
    </fieldset>
  );
};

export default RadioGroupField;
