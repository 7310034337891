import { selectPersonalInfos } from "../../domain/currentUser/currentUser.selectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import palette from "./palette";

const unit = "rem";
const fontSizeFactors = {
  sm: 1,
  md: 1.1875,
  lg: 1.3125,
  xl: 1.5,
};
const lineSpacingFactor = 1.5;
const wordSpacingFactor = 0.16;

const useThemeProvider = (): void => {
  const personalInfos = useSelector(selectPersonalInfos);

  useEffect(() => {
    if (personalInfos?.fontFamily) {
      document.documentElement.style.setProperty(
        "--font-family",
        personalInfos.fontFamily,
      );
    }
    if (personalInfos?.fontSize) {
      const fontSize = fontSizeFactors[personalInfos.fontSize];
      document.documentElement.style.setProperty(
        "--font-size",
        `${fontSize}${unit}`,
      );
    }
    if (personalInfos?.lineSpacing) {
      const lineSpacing =
        fontSizeFactors[personalInfos.lineSpacing] * lineSpacingFactor;
      document.documentElement.style.setProperty(
        "--line-height",
        `${lineSpacing}`,
      );
    }
    if (personalInfos?.wordSpacing) {
      const wordSpacing =
        fontSizeFactors[personalInfos.wordSpacing] * wordSpacingFactor;
      document.documentElement.style.setProperty(
        "--word-spacing",
        `${wordSpacing}${unit}`,
      );
    }
    if (personalInfos?.palette) {
      const colors = palette[personalInfos?.palette];
      document.documentElement.style.setProperty(
        "--bg-color-primary-main",
        `var(--color-${colors.backgroundColorPrimaryMain})`,
      );
      document.documentElement.style.setProperty(
        "--bg-color-primary-light",
        `var(--color-${colors.backgroundColorPrimaryLight})`,
      );
      document.documentElement.style.setProperty(
        "--fg-color-primary",
        `var(--color-${colors.foregroundColorPrimary})`,
      );
      document.documentElement.style.setProperty(
        "--text-color-primary",
        `var(--color-${colors.textColorPrimary})`,
      );
      document.documentElement.style.setProperty(
        "--bg-color-secondary-main",
        `var(--color-${colors.backgroundColorSecondaryMain})`,
      );
      document.documentElement.style.setProperty(
        "--fg-color-secondary",
        `var(--color-${colors.foregroundColorSecondary})`,
      );
    }
  }, [
    personalInfos?.fontFamily,
    personalInfos?.fontSize,
    personalInfos?.lineSpacing,
    personalInfos?.wordSpacing,
    personalInfos?.palette,
  ]);
};

export default useThemeProvider;
