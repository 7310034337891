import {
  getAllMessagesFailure,
  getAllMessagesSuccess,
  saveMessageFailure,
  saveMessageSuccess,
} from "../../domain/contact/message.actions";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import {
  GET_ALL_MESSAGES,
  SAVE_MESSAGE,
} from "../../domain/contact/message.types";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import MessageApi from "./Message.api";

export default function ContactSaga(apiExecutor: ApiExecutorType): {
  contactSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const messageApi = MessageApi(apiExecutor);

  function* saveMessage(action) {
    try {
      const messageSend = {
        sender: action.message.sender,
        text: action.message.text,
      };
      const snackbar = {
        status: true,
        type: "success",
        message: "Message_successfully_send",
      };
      yield call(messageApi.saveMessage, messageSend);
      yield put(saveMessageSuccess(messageSend));
      yield put(showSnackBar(snackbar));
    } catch (e) {
      const snackbar = {
        status: true,
        type: "error",
        message: "Message_failure_send",
      };
      yield put(saveMessageFailure("Message_failure_send"));
      yield put(showSnackBar(snackbar));
    }
  }

  function* getAllMessages() {
    try {
      const { body: messages } = yield call(messageApi.getAllMessages);
      yield put(getAllMessagesSuccess(messages));
    } catch (error) {
      yield put(getAllMessagesFailure("Unable_to_get_messages"));
      const snackbar = {
        status: true,
        type: "error",
        message: "Unable_to_get_messages",
      };
      yield put(showSnackBar(snackbar));
    }
  }

  function* contactSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(SAVE_MESSAGE, saveMessage);
    yield takeLatest(GET_ALL_MESSAGES, getAllMessages);
  }
  return { contactSaga };
}
