import {
  ComponentPropsWithRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
} from "react";
import classNames from "classnames";
import "./Paper.scss";

export interface PaperProps extends ComponentPropsWithRef<"div"> {
  children?: ReactNode;
  fullHeight?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
}

const Paper: ForwardRefRenderFunction<HTMLDivElement, PaperProps> = (
  { children, fullHeight, fullWidth, secondary = false, className, ...props },
  ref,
) => (
  <div
    className={classNames("paper", className, {
      "paper-full-height": fullHeight,
      "paper-full-width": fullWidth,
      "paper-secondary": secondary,
    })}
    ref={ref}
    {...props}
  >
    {children}
  </div>
);

export default forwardRef(Paper);
