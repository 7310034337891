import { IAdmin } from "./manageUsers.types";

export const upsertAdminStateReducer = (
  adminState: IAdmin[],
  upsertedAdmin: IAdmin | undefined,
): IAdmin[] => {
  if (!upsertedAdmin) return adminState;
  if (!adminState.length) return [upsertedAdmin];
  const upsertedAdminIndex = adminState.findIndex(
    (admin) => admin.id === upsertedAdmin?.id,
  );
  if (upsertedAdminIndex <= -1) {
    return [...adminState, upsertedAdmin];
  }
  return adminState.map((admin) =>
    admin.id === upsertedAdmin?.id ? upsertedAdmin : admin,
  );
};
