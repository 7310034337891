import {
  INIT_PAYMENT,
  INIT_PAYMENT_FAILURE,
  INIT_PAYMENT_SUCCESS,
  SAVE_PAYMENT_SUCCESS,
} from "./payment.types";

const INITIAL_STATE = {
  paymentData: {},
  error: "",
  loading: false,
};

const paymentReducer = (
  state = INITIAL_STATE,
  action: { type: string; payment: unknown; error: string },
): {
  loading: boolean;
  error: string;
  paymentData: unknown;
} => {
  switch (action.type) {
    case INIT_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case INIT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentData: action.payment,
      };
    case INIT_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: "Une erreur est survenue",
      };
    case SAVE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentData: action.payment,
      };
    default:
      return state;
  }
};

export default paymentReducer;
