import {
  initPaymentFailure,
  initPaymentSuccess,
  savePaymentFailure,
  savePaymentSuccess,
} from "../../domain/payment/payment.actions";
import { showSnackBar } from "../../domain/interactionsWithUsers/interactionsWithUsers.actions";
import { INIT_PAYMENT, SAVE_PAYMENT } from "../../domain/payment/payment.types";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import PaymentApi from "./Payment.api";

export default function PaymentSaga(apiExecutor: ApiExecutorType): {
  paymentSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const paymentApi = PaymentApi(apiExecutor);

  function* initPayment(action) {
    try {
      const { body: paymentData } = yield call(
        paymentApi.initPayment,
        action.kcId,
      );
      yield put(initPaymentSuccess(paymentData));
    } catch (e) {
      yield put(initPaymentFailure("Une erreur est survenue"));
    }
  }

  function* savePayment(action) {
    try {
      const { payment } = action;

      const snackbar = {
        status: true,
        type: "success",
        message: "Payment_sucessfully_send",
      };
      const paiementData = yield call(paymentApi.savePayment, payment);
      yield put(savePaymentSuccess(paiementData));
      yield put(showSnackBar(snackbar));
    } catch (e) {
      const snackbar = {
        status: true,
        type: "error",
        message: "Payment_failure_save",
      };

      yield put(savePaymentFailure("Payment_failure_save"));
      yield put(showSnackBar(snackbar));
    }
  }

  function* paymentSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(INIT_PAYMENT, initPayment);
    yield takeLatest(SAVE_PAYMENT, savePayment);
  }

  return { paymentSaga };
}
