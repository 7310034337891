import { IUpdateInfos } from "../../domain/currentUser/currentUser.types";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";
import { IPersonalInformations } from "../../domain/auth/auth.types";
import requestUrlConstructor from "../utils/requestUrlConstructor";

function CurrentUserApi(executor: ApiExecutorType): {
  getCurrentUser: (kcId: string) => Promise<{
    status: number;
  }>;
  getPersonalInformations: (kcId: string) => Promise<{
    status: number;
  }>;
  updateInfos: (data: IUpdateInfos | undefined) => Promise<{
    body: IPersonalInformations;
    status: number;
  }>;
} {
  function getCurrentUser(kcId: string) {
    const param = {
      kcId,
    };
    const url = "/user/me";
    const urlCreated = requestUrlConstructor(url, param);
    return executor.get(urlCreated);
  }

  function getPersonalInformations(kcId: string): Promise<{
    body: IPersonalInformations;
    status: number;
  }> {
    return executor.get(`/user/me/${kcId}`);
  }

  function updateInfos(data: IUpdateInfos | undefined): Promise<{
    body: IPersonalInformations;
    status: number;
  }> {
    return executor.post("/user/me", data);
  }

  return { getCurrentUser, getPersonalInformations, updateInfos };
}

export default CurrentUserApi;
