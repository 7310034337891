import {
  CREATE_BENEFICIARY,
  GET_USER,
  IBeneficiary,
  AuthActionTypes,
} from "../../domain/auth/auth.types";
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  takeLatest,
} from "redux-saga/effects";
import AuthApi from "./auth.api";

import {
  createBeneficiarySuccess,
  createBeneficiaryFailure,
  getUserSuccess,
  getUserFailure,
} from "../../domain/auth/auth.actions";
import { ApiExecutorType } from "../../utils/fetchApi/apiExecutor.type";

export default function AuthSaga(apiExecutor: ApiExecutorType): {
  authSaga: () => Generator<ForkEffect<never>, void, unknown>;
} {
  const authApi = AuthApi(apiExecutor);
  function* createBeneficiary(action: AuthActionTypes): Generator<
    | CallEffect<{
        body: IBeneficiary;
        status: number;
      }>
    | PutEffect<AuthActionTypes>,
    void,
    {
      body: IBeneficiary;
    }
  > {
    try {
      const { body: beneficiary } = yield call(
        authApi.createBeneficiary,
        action.beneficiary,
      );
      yield put(createBeneficiarySuccess(beneficiary));
    } catch (e) {
      yield put(createBeneficiaryFailure("Une erreur est survenue"));
    }
  }

  function* getUser(action: AuthActionTypes) {
    try {
      const { body: beneficiary } = yield call(
        authApi.getUser,
        action?.kcId as string,
      );
      yield put(getUserSuccess(beneficiary));
    } catch (e) {
      yield put(getUserFailure("Une erreur est survenue"));
    }
  }

  function* authSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(CREATE_BENEFICIARY, createBeneficiary);
    yield takeLatest(GET_USER, getUser);
  }

  return { authSaga };
}
