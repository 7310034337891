export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAILURE = "GET_ADMINS_FAILURE";
export const GET_ADMINS = "GET_ADMINS";

export const UPSERT_ADMIN_SUCCESS = "UPSERT_ADMIN_SUCCESS";
export const UPSERT_ADMIN_FAILURE = "UPSERT_ADMIN_FAILURE";
export const UPSERT_ADMIN = "UPSERT_ADMIN";

export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILURE = "DELETE_ADMIN_FAILURE";
export const DELETE_ADMIN = "DELETE_ADMIN";

export interface ManageActionTypes {
  type: string;
  admin?: IAdmin;
  admins?: IAdmin[];
}

export interface ManageUsersState {
  admins: IAdmin[];
  error: boolean;
  loading: boolean;
}

export interface IAdmin {
  id?: number;
  email?: string;
  isAdministrator: boolean;
  isAdminPrincipal: boolean;
  permissions?: {
    accessChatbot?: boolean;
    modifySupport?: boolean;
    createAdmin?: boolean;
    pedagogicSupervision?: boolean;
  };
}

export interface CreateAdminActionType {
  type: string;
  admin: IAdmin;
}
